import { AnyAction } from 'redux';
import { togglerType, GET_TOGGLER_SUCCESS } from '../types/mdms';

export const initState: togglerType = {
  dateFeatureToggles: {
    poaValidationDate: '',
    nomineeValidationDate: '',
    dematFieldValidation: '',
    bankAccountProofValidation: '',
    dematDocumentValidation: '',
    twoBankAccountMandatoryValidation: '',
    poaDocValidation: '',
    authorisedSignatoryDocValidationDate: '',
  },
};
function togglerReducer(state = initState, action: AnyAction): togglerType {
  switch (action.type) {
    case GET_TOGGLER_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }

    default:
      return state;
  }
}

export default togglerReducer;
