import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { FundManager, RmsType, Trustee } from '../../redux-store/types/api-types';
import {
  getFundManagersById,
  getIMAccess,
  getRMById,
  gettrusteeAccess,
  getTrustesById,
  IMAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const IMFields = ({
  fundManagerDetails,
  oldData,
}: {
  fundManagerDetails: Trustee;
  oldData?: null | Trustee;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Name (Individual)'}
        description={
          fundManagerDetails.userId ? fundManagerDetails.user?.firstName : fundManagerDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                fundManagerDetails.userId
                  ? fundManagerDetails.user?.firstName
                  : fundManagerDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            fundManagerDetails.userId
              ? fundManagerDetails.user?.firstName
              : fundManagerDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name (Individual)',
            fundManagerDetails.userId
              ? fundManagerDetails.user?.firstName
              : fundManagerDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Leegality Name'}
        description={fundManagerDetails.leegalityName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.leegalityName, fundManagerDetails.leegalityName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.leegalityName, fundManagerDetails.leegalityName) &&
          handleRoutePopupOpen(
            'Leegality Name',
            fundManagerDetails.leegalityName,
            oldData.leegalityName
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={
          fundManagerDetails.userId ? fundManagerDetails.user?.email : fundManagerDetails?.email
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                fundManagerDetails.userId
                  ? fundManagerDetails.user?.email
                  : fundManagerDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            fundManagerDetails.userId ? fundManagerDetails.user?.email : fundManagerDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            fundManagerDetails.userId ? fundManagerDetails.user?.email : fundManagerDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={
          fundManagerDetails.userId ? fundManagerDetails.user?.phone : fundManagerDetails?.phone
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                fundManagerDetails.userId
                  ? fundManagerDetails.user?.phone
                  : fundManagerDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            fundManagerDetails.userId ? fundManagerDetails.user?.phone : fundManagerDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            fundManagerDetails.userId ? fundManagerDetails.user?.phone : fundManagerDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pincode'}
        description={fundManagerDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, fundManagerDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, fundManagerDetails.pincode) &&
          handleRoutePopupOpen('Pincode', fundManagerDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={fundManagerDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, fundManagerDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, fundManagerDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', fundManagerDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={fundManagerDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, fundManagerDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, fundManagerDetails.streetName) &&
          handleRoutePopupOpen('Street Name', fundManagerDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={fundManagerDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, fundManagerDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, fundManagerDetails.state) &&
          handleRoutePopupOpen('State', fundManagerDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={fundManagerDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, fundManagerDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, fundManagerDetails.city) &&
          handleRoutePopupOpen('City', fundManagerDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={fundManagerDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, fundManagerDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, fundManagerDetails.country) &&
          handleRoutePopupOpen('Country', fundManagerDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          fundManagerDetails.userId
            ? fundManagerDetails.user?.companyName
            : fundManagerDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                fundManagerDetails.userId
                  ? fundManagerDetails.user?.companyName
                  : fundManagerDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            fundManagerDetails.userId
              ? fundManagerDetails.user?.companyName
              : fundManagerDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            fundManagerDetails.userId
              ? fundManagerDetails.user?.companyName
              : fundManagerDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />

      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function IMDetails({
  location,
}: {
  location: Location<{ fundManagerId: number }>;
}): JSX.Element {
  const { fundManagerId: id } = location.state || { fundManagerId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [fundManagerDetails, setFundManagerDetails] = useState<FundManager>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        if (id) {
          const getFundManagerDetails = (await dispatch(
            getFundManagersById(id)
          )) as unknown as FundManager;
          setFundManagerDetails(getFundManagerDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(fundManagerAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('invest-managers')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('invest-managers')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Investment Manager
            </Typography>
          </Box>
          {accessForEditAdd && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push('edit-Invest-manager', { fundManagerId: fundManagerDetails?.id })
                }
              />
            </IconButton>
          )}
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          fundManagerDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <IMFields fundManagerDetails={fundManagerDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {fundManagerDetails.history && fundManagerDetails.history.length > 0 && (
                <LogsUI data={fundManagerDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
