import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { UseRadioGroup } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { ApplicationProps, JointholderRelationMaster } from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { getFunds } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AdvisoryServices,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkIsAccreditedInvestor,
  checkValidationBasedOnDate,
  currencyConversion,
  saveForLater,
  setUpFeeCalculations,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import { useFeatureToggles } from '../../utils/toggler';
import { ConfirmationDialog } from '../commonComponents';
import MFCheckbox from '../../lib/formik/Checkbox';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  groupInvestment: null,
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  referralName: '',
  referralPercentage: 0,
  investorAdvisory: null,
  saveType: 'save and proceed',
};

export const ReferralFeeDetails = ({ values }: { values: Values }): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <SubHeading>Referral Fee Details</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`referralName`}
          label="Referral Name *"
          placeholder="Enter Referral Name"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`referralPercentage`}
          label="Referral fee is % of management fee charged  *"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          placeholder={`Enter Referral fee is % of management fee charged`}
          trimOnBlur={false}
          disabled={isFieldDisabled || !values.planId}
          // disabled
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <UseRadioGroup
          name="investorAdvisory"
          formLabel="Please select one option from the below"
          items={Object.keys(AdvisoryServices).map((item) => ({
            label: AdvisoryServices[item],
            value: item,
          }))}
          value={values.investorAdvisory || ''}
          disabled={isFieldDisabled}
        />
      </Grid>
    </>
  );
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const [openDialog, setDialog] = useState<{
    investmentData: Values;
    checkApplication: boolean;
  } | null>(null);

  const dateFeatureToggles = useFeatureToggles();

  const { nomineeValidationDate } = dateFeatureToggles;

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    minInvestmentAmt = 0,
  } = selectedPlan || {};

  useEffect(() => {
    const {
      plan,
      referralName = '',
      referralPercentage = 0,
      investorAdvisory = null,
    } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      minInvestmentAmt = 0,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            minInvestmentAmt: Number(minInvestmentAmt),
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || null,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      referralName,
      referralPercentage: application?.referralPercentage || 0,
      investorAdvisory,
      groupInvestment: application?.groupInvestment || false,
    });
  }, [application]);

  function getUpdatedApplication(values: Values) {
    const { saveType, ...rest } = values;
    const updatedApplication = {
      ...application,
      ...rest,
      contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
      setupFeePercentage: Number(rest.setupFeePercentage),
    };
    return updatedApplication;
  }

  const submitInvestmentData = async (values: Values, checkApplication: boolean) => {
    const {
      id,
      applicant1ReferenceId = '',
      applicationType,
      applicationNumber,
      hasPOA,
      status,
    } = application || {};
    const { saveType } = values;
    const updatedApplication = getUpdatedApplication(values);
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      setLoading(true);
      await dispatch(
        updateApplication({
          body: {
            ...updatedApplication,
            status:
              !hasPOA &&
              AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
              status !== 'draft' &&
              applyRoleBasedStatus(role) &&
              applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                ? 'sent_to_amc_approver'
                : status,
            currentStep:
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                ? 4
                : !checkValidationBasedOnDate(application?.createdAt || '', nomineeValidationDate)
                ? 9
                : 8,
            // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            //   ? !!currentStep && currentStep > 3
            //     ? currentStep
            //     : Number(currentStep) + 1
            //   : !!currentStep && currentStep > 7
            //   ? currentStep
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } else if (checkApplication) {
      if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : // : history.push('investor-questionnaire', { id, applicant1ReferenceId })
          history.push(saveForLater(role, id, applicant1ReferenceId));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { currentStep, applicationType } = application || {};
      const { saveType, ...rest } = values;
      const updatedApplication = getUpdatedApplication(values);
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...updatedApplication,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep &&
                currentStep >
                  (!checkValidationBasedOnDate(application?.createdAt || '', nomineeValidationDate)
                    ? 8
                    : 7)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      if (values?.groupInvestment && !checkApplication) {
        setDialog({ investmentData: values, checkApplication });
      } else {
        submitInvestmentData(values, checkApplication);
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(getFunds({ isActive: true }))) as unknown as FundProps[];
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId && !application.scheme.isActive
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1 && jointHoldersData
            ? (updatedCommitmentAmount(
                Number(minCommitmentAmount),
                application.applicants,
                jointHoldersData
              ) as number)
            : minCommitmentAmount;
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              values?.groupInvestment
                ? Number(minInvestmentAmt)
                : checkIsAccreditedInvestor(application?.applicants || [])
                ? 2500000
                : minCommitmentAmountBasedOnJointHolders,
              maxCommitmentAmount,
              minContributionPercentage,
              fundsList || []
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <MFCheckbox
              name={`groupInvestment`}
              label="Group Investment"
              sx={{ mt: 1 }}
              disabled={isFieldDisabled}
              onChange={({ target: { checked } }) => {
                setValues({
                  ...values,
                  groupInvestment: checked,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  referralPercentage: 0,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
            />
          </Grid>
          {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class (Plan) *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: 0,
                  contributionChequeAmount: 0,
                  setupFeePercentage: selectedPlan?.setupFee,
                  managementFeesRate: selectedPlan?.managementFee,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  referralPercentage: selectedPlan?.referralFee || 0,
                });
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'commitmentAmount'}
              label="Commitment Amount *"
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(value) || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  commitmentAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || Number(value),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'managementFeesRate'}
              label="Management Fees Rate"
              placeholder="Enter Management Fee Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={'setupFeePercentage'}
              label="Setup Fee Percentage *"
              type="number"
              placeholder="Enter Setup Fee Percentage"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  Number(value)
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  setupFeePercentage: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeAmount'}
              label="Setup Fee Amount"
              type="number"
              placeholder="Enter Setup Fee Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeAmount)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeGST'}
              label="GST (GST On Setup Fee Amount)"
              type="number"
              placeholder="Enter GST"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.setupFeeGST != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeGST)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalSetupFee'}
              label="Total Setup Fee"
              type="number"
              placeholder="Enter Total Setup Fee"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalSetupFee != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalSetupFee)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'setupFeeTDS'}
              label="TDS"
              type="number"
              placeholder="Enter TDS"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                setValues({
                  ...values,
                  setupFeeTDS: value ? Number(value) : null,
                  netSetupFeeLessTDS:
                    values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.setupFeeTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.setupFeeTDS)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'netSetupFeeLessTDS'}
              label="Setup Fee Payable"
              type="number"
              placeholder="Enter Setup Fee Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.netSetupFeeLessTDS != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.netSetupFeeLessTDS)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable)}
              </Typography>
            )}
          </Grid>
          <ReferralFeeDetails values={values} />
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
          {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={`Are you sure you want to proceed with Group Investment Option ?`}
            open={openDialog?.investmentData.groupInvestment as boolean}
            setOpen={() => setDialog(null)}
            onSave={async () => {
              setDialog(null);
              try {
                setLoading(true);
                await submitInvestmentData(
                  openDialog?.investmentData as Values,
                  openDialog?.checkApplication as boolean
                );
              } catch (e) {
                setLoading(false);
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setDialog(null)}
            displayInfo={
              Number(openDialog?.investmentData.commitmentAmount) > Number(maxCommitmentAmount)
                ? 'Commitment amount does not match with provided class of units'
                : ''
            }
          />
        </Grid>
      )}
    </Formik>
  );
}
