import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS, VERIFY_LOGIN_OTP_SUCCESS } from '../types/auth';
import { CREATE_APPLICATION_SUCCESS } from '../types/application';

export type ParamsProps = {
  page: number;
  search: string;
  applicationType: string;
  status: string;
  modeOfHolding: string;
  distributorId: string;
  applicationSentBack: boolean | null;
  schemeId: string;
};

export const initialState: ParamsProps = {
  page: 1,
  applicationType: '',
  status: '',
  search: '',
  modeOfHolding: '',
  distributorId: '',
  applicationSentBack: null,
  schemeId: '',
};

function paramsReducer(state = initialState, action: AnyAction): Partial<ParamsProps> {
  switch (action.type) {
    case VERIFY_LOGIN_OTP_SUCCESS: {
      return {
        ...state,
        status:
          action.body.role === 'amcapprover'
            ? 'sent_to_amc_approver'
            : action.body.role === 'poaapprover'
            ? 'sent_to_poa_approver'
            : '',
      };
    }
    case 'GET_PARAMS_DATA':
      return {
        ...state,
        page: action.paramsObj.page,
        applicationType: action.paramsObj.applicationType,
        status: action.paramsObj.status,
        modeOfHolding: action.paramsObj.modeOfHolding,
        distributorId: action.paramsObj.distributorId,
        search: action.paramsObj.search,
        applicationSentBack: action.paramsObj.applicationSentBack,
        schemeId: action.paramsObj.schemeId,
      };
    case CREATE_APPLICATION_SUCCESS:
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export default paramsReducer;
