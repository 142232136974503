import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { addPlan, fundAccess, getPlanDetail, updatePlan } from '../../redux-store/actions/funds';
import {
  accessType,
  addFundPlanType,
  addPlanType,
  ClassPlanProps,
} from '../../redux-store/types/funds';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import {
  getFundManagersById,
  getFundManagersList,
  getTrustesById,
  getTrustessList,
} from '../../redux-store/actions/userManagement';
import { FundManager, Trustee } from '../../redux-store/types/api-types';
import { addPlanValidation } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  currencyConversion,
} from '../../utils/utilityFunctions';
import { initialComment } from '../../utils/constant';
import { NavigationThrowPopup } from '../UsersFundsVerification/userFundscommonComponents';
import { noUpdationMsg } from '../userManagement/inviteRm';
import { showError } from '../../redux-store/actions/auth';

const initialValues: addPlanType = {
  // id: '',
  schemeId: 0,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: '',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  faceValue: 0,
  fundAddress1: '',
  fundManagerId: null,
  trusteeId: null,
  fundAddress2: '',
  fundAddress3: '',
  inActive: true,
  isActive: true,
  custodian: '',
  setupFee: 0,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minInvestmentAmt: null,
  minContributionPercentage: 0,
  managementFee: 0,
  referralFee: 0,
  tcLink: '',
  comment: '',
};

export default function AddPlan({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { fundId, PlanId } = location.state || {};
  const [trustee, setTrustee] = useState<any[]>([]);
  const [planInitial, setPlanInitial] = useState({ ...initialValues, checkId: PlanId });

  const [planEditDetails, setPlanEditDetails] = useState({ ...planInitial, checkId: fundId });

  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(
          getTrustessList({ isActive: true })
        )) as unknown as Trustee[];
        const fundManagerList = (await dispatch(
          getFundManagersList({ isActive: true })
        )) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        !PlanId && setFundManager(fundManagerList);
        !PlanId && setTrustee(trusteeList);
        if (PlanId) {
          const GetFundDetail = (await dispatch(
            getPlanDetail(PlanId)
          )) as unknown as ClassPlanProps;
          const {
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minInvestmentAmt,
            minContributionPercentage,
            managementFee,
            fundManagerId,
            trusteeId,
            tcLink,
            comment,
            referralFee,
          } = GetFundDetail || {};
          const planObj = {
            ...planInitial,
            planCode: planCode || planInitial.planCode,
            schemeId,
            planDescription: planDescription || planInitial.planDescription,
            schemeNature: schemeNature || planInitial.schemeNature,
            registrationNumber: registrationNumber || planInitial.registrationNumber,
            schemeCategory: schemeCategory || planInitial.schemeCategory,
            country: country || planInitial.country,
            currency: currency || planInitial.currency,
            schemeStartDate: schemeStartDate || planInitial.schemeStartDate,
            lockInPeriod: lockInPeriod || planInitial.lockInPeriod,
            lockInExtension: lockInExtension || planInitial.lockInExtension,
            inActive,
            faceValue: faceValue || planInitial.faceValue,
            fundAddress1: fundAddress1 || planInitial.fundAddress1,
            fundAddress2: fundAddress2 || planInitial.fundAddress2,
            fundAddress3: fundAddress3 || planInitial.fundAddress3,
            isActive,
            custodian: custodian || planInitial.custodian,
            setupFee: setupFee || planInitial.setupFee,
            minCommitmentAmount: minCommitmentAmount || planInitial.minCommitmentAmount,
            maxCommitmentAmount: maxCommitmentAmount || planInitial.maxCommitmentAmount,
            minContributionPercentage:
              minContributionPercentage || planInitial.minContributionPercentage,
            managementFee: managementFee || planInitial.managementFee,
            referralFee: referralFee || planInitial.referralFee,
            fundManagerId,
            trusteeId,
            tcLink: tcLink || planInitial.tcLink,
            minInvestmentAmt,
          };
          setPlanInitial({
            ...planObj,
            comment: comment || '',
          });
          setPlanEditDetails(planObj);
          if (trusteeId) {
            const checkTrusteeId = trusteeList.find(
              (_list) => Number(_list.id) === Number(trusteeId)
            );
            if (checkTrusteeId) {
              setTrustee(trusteeList);
            } else {
              const getDetails = (await dispatch(getTrustesById(trusteeId))) as unknown as Trustee;
              setTrustee([...trusteeList, getDetails]);
            }
          }
          if (fundManagerId) {
            const checkIMId = fundManagerList.find(
              (_list) => Number(_list.id) === Number(fundManagerId)
            );
            if (checkIMId) {
              setFundManager(fundManagerList);
            } else {
              const getDetails = (await dispatch(
                getFundManagersById(fundManagerId)
              )) as unknown as FundManager;
              setFundManager([...fundManagerList, getDetails]);
            }
          }
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addPlanType) => {
    try {
      const {
        planCode,
        schemeId,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minInvestmentAmt,
        minContributionPercentage,
        managementFee,
        fundManagerId,
        trusteeId,
        tcLink,
        comment,
        referralFee,
      } = values;

      if (!PlanId) {
        const res = (await dispatch(
          addPlan({
            schemeId: fundId,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            referralFee: referralFee ? referralFee : 0,
            tcLink,
            comment: initialComment,
            trustee: trustee?.find((_trustee) => Number(_trustee.id) === Number(trusteeId)),
            fundManager: fundManager?.find((_Im) => Number(_Im.id) === Number(fundManagerId)),
            minInvestmentAmt,
          })
        )) as any;
        if (res.message) {
          setRouteDialogOpen({
            message: res.message,
            open: true,
          });
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { checkId, comment: comparedComment, ...restComparedData } = planEditDetails;
        const planUpdatedObj = {
          schemeId,
          planCode,
          planDescription,
          schemeNature,
          registrationNumber,
          schemeCategory,
          country,
          currency,
          schemeStartDate,
          lockInPeriod,
          lockInExtension,
          fundManagerId,
          trusteeId,
          inActive,
          faceValue: faceValue ? Number(faceValue) : faceValue === 0 ? faceValue : null,
          fundAddress1,
          fundAddress2,
          fundAddress3,
          isActive,
          custodian,
          setupFee: setupFee ? setupFee : 0,
          minCommitmentAmount,
          maxCommitmentAmount,
          minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
          managementFee: managementFee ? managementFee : 0,
          referralFee: referralFee ? referralFee : 0,
          tcLink,
          minInvestmentAmt,
        };
        const checkPlanData = applicationComparison(
          {
            ...restComparedData,
            trusteeId: Number(restComparedData.trusteeId),
            fundManagerId: Number(restComparedData.fundManagerId),
            schemeStartDate: new Date(restComparedData.schemeStartDate),
            setupFee: Number(restComparedData.setupFee),
            faceValue: restComparedData.faceValue
              ? Number(restComparedData.faceValue)
              : restComparedData.faceValue === 0
              ? restComparedData.faceValue
              : null,
            minCommitmentAmount: Number(restComparedData.minCommitmentAmount),
            maxCommitmentAmount: Number(restComparedData.maxCommitmentAmount),
            minContributionPercentage: Number(restComparedData.minContributionPercentage),
            managementFee: Number(restComparedData.managementFee),
          },
          {
            ...planUpdatedObj,
            trusteeId: Number(trusteeId),
            fundManagerId: Number(fundManagerId),
            schemeStartDate: new Date(schemeStartDate),
            setupFee: Number(setupFee),
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            managementFee: Number(managementFee),
          }
        );
        if (checkPlanData) {
          throw noUpdationMsg;
        }
        const result = (await dispatch(
          updatePlan(Number(PlanId), {
            ...planUpdatedObj,
            comment,
            trustee: trustee?.find((_trustee) => Number(_trustee.id) === Number(trusteeId)),
            fundManager: fundManager?.find((_Im) => Number(_Im.id) === Number(fundManagerId)),
          })
        )) as any;
        if (result.message) {
          setRouteDialogOpen({
            message: result.message,
            open: true,
          });
        }
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={planInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addPlanValidation}>
        {({ handleSubmit, values, errors }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {PlanId ? 'Edit Plan' : 'Add Plan'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {'Plan Details'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planCode"
                      label="Plan Code *"
                      placeholder="Enter Plan Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planDescription"
                      label="Plan Description *"
                      placeholder="Enter Plan Description"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeNature"
                      label="Scheme Nature *"
                      placeholder="Enter Scheme Nature"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCategory"
                      label="Scheme Category *"
                      placeholder="Enter Scheme Category"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="registrationNumber"
                      label="Registration Number *"
                      placeholder="Enter Registration Number"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="fundManagerId"
                      label="Investment Manager Name *"
                      items={fundManager.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="trusteeId"
                      label="Trustee Name *"
                      items={trustee.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="currency" label="Currency" placeholder="Enter Currency" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      label={'Scheme Start Date'}
                      inputLabelStyles={{
                        transform: 'unset',

                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      placeholder={'Enter Scheme Start Date'}
                      // maxDate={minDateForContributor()}
                      name={`schemeStartDate`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInPeriod"
                      label="Lock In Period(In Year)"
                      placeholder="Enter Lock In Period(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInExtension"
                      label="Lock In Extension(In Year)"
                      disabled={!access}
                      placeholder="Enter Lock In Extension(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="faceValue"
                      label="Face value"
                      placeholder="Enter Face value"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress1"
                      label="Fund Address 1"
                      placeholder="Enter Fund Address 1"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress2"
                      label="Fund Address 2"
                      placeholder="Enter Fund Address 2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress3"
                      label="Fund Address 3"
                      placeholder="Enter Fund Address 3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="custodian" label="Custodian" placeholder="Enter Custodian" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="setupFee"
                      label="Setup Fee"
                      placeholder="Enter Setup Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minCommitmentAmount"
                      label="Minimum Commitment Amount *"
                      placeholder="Enter Minimum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.minCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.minCommitmentAmount)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="maxCommitmentAmount"
                      label="Maximum Commitment Amount *"
                      placeholder="Enter Maximum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.maxCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.maxCommitmentAmount)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minInvestmentAmt"
                      label="Group Investment Commitment Amount *"
                      placeholder="Enter Group Investment Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.minInvestmentAmt != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.minInvestmentAmt)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minContributionPercentage"
                      label="Minimum Contribution Percentage"
                      placeholder="Enter Minimum Contribution Percentage"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="managementFee"
                      label="Management Fee"
                      placeholder="Enter Management Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="tcLink"
                      label={`PPM Link *`}
                      placeholder={`Enter PPM Link`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="referralFee"
                      label="Referral fee is % of management fee charged *"
                      placeholder="Enter Referral fee is % of management fee charged"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  {values.checkId && (
                    <Grid item xs={12}>
                      <Grid item xs={12} sm={6} md={8}>
                        <MFTextField
                          name="comment"
                          label={'Comment(s) *'}
                          multiline
                          rows={4}
                          placeholder="Type your answer here..."
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
            <NavigationThrowPopup
              handleClose={handleRoutePopupClose}
              dialogOpen={routeDialogOpen}
              onSave={() => {
                // handleErrorPopupClose();
                history.push(PlanId ? 'plan-details' : 'view-plans', {
                  fundId: fundId,
                  PlanId: PlanId,
                });
              }}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
