import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Button,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  AuthorisedSignatories,
  DocumentType,
  FileType,
  RmsType,
  SubDistributorListType,
} from '../../redux-store/types/api-types';
import {
  getAuthorisedSignatoryById,
  getDistributorRMbyId,
  getRMById,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison, getUpdatedDocuments } from '../../utils/utilityFunctions';
import { SubHeading } from '../investors/components';
import { getFieldTitle } from '../ApplicationDetails/DocumentDetails';
import { CommonDocumentPreviewSection } from '../commonComponents';
import { documentDetails } from '../investors/documentDetails';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const AuthorisedSignatoryFields = ({
  authorisedSignatoryDetails,
  oldData,
}: {
  authorisedSignatoryDetails: AuthorisedSignatories;
  oldData?: null | AuthorisedSignatories;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined | FileType;
    oldValue: string | null | number | undefined | FileType;
    isDoc?: boolean;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
    isDoc: false,
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '', isDoc: false });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined | FileType,
    oldValue: string | null | number | undefined | FileType,
    isDoc?: boolean
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
      isDoc: isDoc,
    });
  };

  // const updatedDocuments = authorisedSignatoryDetails?.documents?.reduce(
  const updatedDocuments = getUpdatedDocuments(
    authorisedSignatoryDetails?.signatoryDocuments || []
  );

  return (
    <>
      <KeyValue
        title={'Name'}
        description={
          authorisedSignatoryDetails.userId
            ? authorisedSignatoryDetails.user?.firstName
            : authorisedSignatoryDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                authorisedSignatoryDetails.userId
                  ? authorisedSignatoryDetails.user?.firstName
                  : authorisedSignatoryDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.firstName
              : authorisedSignatoryDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name',
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.firstName
              : authorisedSignatoryDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={
          authorisedSignatoryDetails.userId
            ? authorisedSignatoryDetails.user?.email
            : authorisedSignatoryDetails?.email
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                authorisedSignatoryDetails.userId
                  ? authorisedSignatoryDetails.user?.email
                  : authorisedSignatoryDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.email
              : authorisedSignatoryDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.email
              : authorisedSignatoryDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={
          authorisedSignatoryDetails.userId
            ? authorisedSignatoryDetails.user?.phone
            : authorisedSignatoryDetails?.phone
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                authorisedSignatoryDetails.userId
                  ? authorisedSignatoryDetails.user?.phone
                  : authorisedSignatoryDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.phone
              : authorisedSignatoryDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.phone
              : authorisedSignatoryDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pan'}
        description={authorisedSignatoryDetails.panNumber}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.panNumber, authorisedSignatoryDetails.panNumber)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, authorisedSignatoryDetails.panNumber) &&
          handleRoutePopupOpen('Pan', authorisedSignatoryDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={'Pincode'}
        description={authorisedSignatoryDetails.pincode}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.pincode, authorisedSignatoryDetails.pincode)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, authorisedSignatoryDetails.pincode) &&
          handleRoutePopupOpen('Pincode', authorisedSignatoryDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={authorisedSignatoryDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, authorisedSignatoryDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, authorisedSignatoryDetails.buildingNo) &&
          handleRoutePopupOpen(
            'Building Number',
            authorisedSignatoryDetails.buildingNo,
            oldData.buildingNo
          )
        }
      />
      <KeyValue
        title={'Street Name'}
        description={authorisedSignatoryDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, authorisedSignatoryDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, authorisedSignatoryDetails.streetName) &&
          handleRoutePopupOpen(
            'Street Name',
            authorisedSignatoryDetails.streetName,
            oldData.streetName
          )
        }
      />
      <KeyValue
        title={'State'}
        description={authorisedSignatoryDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, authorisedSignatoryDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, authorisedSignatoryDetails.state) &&
          handleRoutePopupOpen('State', authorisedSignatoryDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={authorisedSignatoryDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, authorisedSignatoryDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, authorisedSignatoryDetails.city) &&
          handleRoutePopupOpen('City', authorisedSignatoryDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={authorisedSignatoryDetails.country}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.country, authorisedSignatoryDetails.country)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, authorisedSignatoryDetails.country) &&
          handleRoutePopupOpen('Country', authorisedSignatoryDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          authorisedSignatoryDetails.userId
            ? authorisedSignatoryDetails.user?.companyName
            : authorisedSignatoryDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                authorisedSignatoryDetails.userId
                  ? authorisedSignatoryDetails.user?.companyName
                  : authorisedSignatoryDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.companyName
              : authorisedSignatoryDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            authorisedSignatoryDetails.userId
              ? authorisedSignatoryDetails.user?.companyName
              : authorisedSignatoryDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      {authorisedSignatoryDetails?.signatoryDocuments?.length ? (
        <>
          <Grid item xs={12}>
            <SubHeading>Document Details</SubHeading>
            <CommonDocumentPreviewSection
              updatedDocuments={updatedDocuments}
              authorisedSignatoryDetails={authorisedSignatoryDetails}
              oldData={oldData}
              handleRoutePopupOpen={handleRoutePopupOpen}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function AuthorisedSignatoryDetails({
  location,
}: {
  location: Location<{ authorisedSignatoryId: number }>;
}): JSX.Element {
  const { authorisedSignatoryId: id } = location.state || { authorisedSignatoryId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [authorisedSignatoryDetails, setAuthorisedSignatoryDetails] =
    useState<AuthorisedSignatories>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getAuthorisedSignatoryDetails = (await dispatch(
            getAuthorisedSignatoryById(id)
          )) as unknown as AuthorisedSignatories;
          setAuthorisedSignatoryDetails(getAuthorisedSignatoryDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('authorised-signatory', {
                  distributorId: authorisedSignatoryDetails?.distributorId,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('authorised-signatory', {
                    distributorId: authorisedSignatoryDetails?.distributorId,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Authorised Signatories
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-authorised-signatory', {
                  authorisedSignatoryId: authorisedSignatoryDetails?.id,
                })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          authorisedSignatoryDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <AuthorisedSignatoryFields
                        authorisedSignatoryDetails={authorisedSignatoryDetails}
                      />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {authorisedSignatoryDetails.history &&
                authorisedSignatoryDetails.history.length > 0 && (
                  <LogsUI data={authorisedSignatoryDetails.history} />
                )}
            </>
          )
        )}
      </Box>
    </>
  );
}
