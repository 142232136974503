import { Box, Grid, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { UseRadioGroup } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  addressTypesMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  IdTypeMasters,
  ReasonMaster,
  USER_ROLES,
  yesAndNoMasters,
  yesAndNoTrueAndFalseMasters,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkValidationBasedOnDate,
  checkifFatcaYes,
  checkifFatcaYesAndTypeOthers,
  getApplicantName,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { FATCAValidationSchema } from '../../utils/schema';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MFCheckbox from '../../lib/formik/Checkbox';
import { useFeatureToggles } from '../../utils/toggler';

export type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: string[];
};
const FatcaCrsDocumentCheckEnable = (defaultValue: boolean) => {
  return {
    typeOfAddressProvidedAtKRA: '',
    citizenOfAnyCountryOtherThanIndia: defaultValue ? 'no' : '',
    countryOfBirthOtherThanIndia: defaultValue ? 'no' : '',
    poaMandateHolderOutsideIndia: defaultValue ? 'no' : '',
    addressTelephoneOutsideIndia: defaultValue ? 'no' : '',
    sourceOfWealth: '',
    taxResidentOfAnyCountryOtherThanIndia: false,
    placeOfBirth: '',
    countryOfBirth: defaultValue ? 'INDIA' : '',
    countryOfNationality: defaultValue ? 'INDIA' : '',
    taxCountryName: '',
    taxID: '',
    idType: '',
    nameOfEntity: '',
    dateOfIncorporation: null,
    cityOfIncorporation: '',
    countryOfIncorporation: '',
    entityExcemptionCode: '',
    idTypeOthers: '',
    othersTinNotAvailableReason: '',
    fatcaCrsDocumentCheck: !defaultValue,
  };
};

const initialValues: Values = {
  applicants: [FatcaCrsDocumentCheckEnable(true)],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
  hasPOA,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  hasPOA: boolean | undefined;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabledForPOALogin = [USER_ROLES.POAAPPROVER].includes(role);
  const isFieldDisabledForInvestorLogin = [USER_ROLES.INVESTOR].includes(role);
  const isFieldDisabled = isFieldDisabledForPOALogin || isFieldDisabledForInvestorLogin;

  return (
    <>
      <SubHeading>FATCA Details of {applicantName} Applicant</SubHeading>
      {hasPOA && (
        <Grid item xs={12}>
          <MFCheckbox
            name={`applicants.${index}.fatcaCrsDocumentCheck`}
            label={'Wish to upload FATCA-CRS Declaration'}
            sx={{ my: 2 }}
            disabled={isFieldDisabled}
          />
        </Grid>
      )}
      {!values.fatcaCrsDocumentCheck && (
        <>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
              label="Type of Address Provided At KRA *"
              items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
              disabled={isFieldDisabledForPOALogin}
            />
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <UseRadioGroup
                formLabel="Citizen of any country other than India *"
                name={`applicants.${index}.citizenOfAnyCountryOtherThanIndia`}
                items={Object.keys(yesAndNoMasters).map((item) => ({
                  label: yesAndNoMasters[item],
                  value: item,
                }))}
                value={values.citizenOfAnyCountryOtherThanIndia?.toString()}
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <UseRadioGroup
                formLabel="Country of birth is any country other than India *"
                name={`applicants.${index}.countryOfBirthOtherThanIndia`}
                items={Object.keys(yesAndNoMasters).map((item) => ({
                  label: yesAndNoMasters[item],
                  value: item,
                }))}
                value={values.countryOfBirthOtherThanIndia?.toString()}
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <UseRadioGroup
                formLabel="Tax Resident of any country other than India *"
                name={`applicants.${index}.taxResidentOfAnyCountryOtherThanIndia`}
                items={Object.keys(yesAndNoTrueAndFalseMasters).map((item) => ({
                  label: yesAndNoTrueAndFalseMasters[item],
                  value: item,
                }))}
                value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <UseRadioGroup
                formLabel="POA or a mandate holder who has an address outside India *"
                name={`applicants.${index}.poaMandateHolderOutsideIndia`}
                items={Object.keys(yesAndNoMasters).map((item) => ({
                  label: yesAndNoMasters[item],
                  value: item,
                }))}
                value={values.poaMandateHolderOutsideIndia?.toString()}
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <UseRadioGroup
                formLabel="Address or telephone number outside India *"
                name={`applicants.${index}.addressTelephoneOutsideIndia`}
                items={Object.keys(yesAndNoMasters).map((item) => ({
                  label: yesAndNoMasters[item],
                  value: item,
                }))}
                value={values.addressTelephoneOutsideIndia?.toString()}
                disabled={isFieldDisabled}
              />
            </Grid>
          </Grid>
          {/* {applicant?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && ( */}
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.placeOfBirth`}
                label="Place of Birth *"
                placeholder="Enter Place of Birth"
                disabled={isFieldDisabledForPOALogin}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MFTextField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            placeholder="Enter Country of Birth"
            disabled={isFieldDisabled}
          /> */}
              <SearchableSelect
                name={`applicants.${index}.countryOfBirth`}
                label="Country of Birth *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MFTextField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            placeholder="Enter Country of Nationality"
            disabled={isFieldDisabled}
          /> */}
              <SearchableSelect
                name={`applicants.${index}.countryOfNationality`}
                label="Country of Nationality *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            {checkifFatcaYes(
              values?.taxResidentOfAnyCountryOtherThanIndia,
              values?.citizenOfAnyCountryOtherThanIndia,
              values?.countryOfBirthOtherThanIndia,
              values?.poaMandateHolderOutsideIndia,
              values?.addressTelephoneOutsideIndia
            ) && (
              <>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.sourceOfWealth`}
                    label="Source of wealth"
                    placeholder="Enter Source of wealth"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxCountryName`}
                    label="Tax country Name *"
                    placeholder="Enter Tax country Name"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.idType`}
                    label="ID Type *"
                    items={IdTypeMasters.map((_idType) => ({
                      key: _idType,
                      value: _idType,
                    }))}
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxID`}
                    label="Tax ID (TIN)/Equivalent Number *"
                    placeholder="Enter (TIN)/Equivalent Number"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                {values.idType === 'OTHERS' && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`applicants.${index}.idTypeOthers`}
                        label="Please Specify ID Type *"
                        placeholder="Enter ID Type"
                        disabled={isFieldDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`applicants.${index}.othersTinNotAvailableReason`}
                        label="Specify Reason (If TIN is not available) *"
                        placeholder="Enter Reason"
                        disabled={isFieldDisabled}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.nameOfEntity`}
              label="Name of Entity"
              placeholder="Enter Name of Entity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date of Incorporation"
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={minDateForContributor()}
              name={`applicants.${index}.dateOfIncorporation`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={applicant.dateOfBirth || minDateForContributor().toString()}
              onChange={(newValue) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant, ind) => {
                    if (index === ind) {
                      return {
                        ...applicant,
                        dateOfIncorporation: newValue?.toString(),
                      };
                    }
                    return applicant;
                  }),
                });
              }}
              textFieldStyles={{
                'label + &': {
                  mt: 4,
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #DDEAF3',
                },
                '& .MuiOutlinedInput-root': {
                  position: 'relative',
                  backgroundColor: 'common.white',
                  border: '1px solid #DDEAF3',
                  fontSize: 16,
                  '&:hover': {
                    borderColor: 'primary.main',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '.MuiInputBase-input': {
                    p: '10px 12px',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.cityOfIncorporation`}
              label="City of Incorporation"
              placeholder="Enter City of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.countryOfIncorporation`}
              label="Country of Incorporation"
              placeholder="Enter Country of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.entityExcemptionCode`}
              label="Entity Exemption Code"
              placeholder="Enter Entity Exemption Code"
            />
          </Grid> */}
          </>
        </>
      )}
    </>
  );
};

export default function Fatca(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);

  const dateFeatureToggles = useFeatureToggles();

  const { nomineeValidationDate } = dateFeatureToggles;

  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setFatcaDetails({
          ...fatcaDetails,
          applicants: applicants?.map((applicant) => ({
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxResidentOfAnyCountryOtherThanIndia:
              applicant.taxResidentOfAnyCountryOtherThanIndia || false,
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth: applicant.countryOfBirth
              ? applicant.countryOfBirth.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfNationality: applicant.countryOfNationality
              ? applicant.countryOfNationality.toUpperCase()
              : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || '',
            idType: applicant.idType || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
            citizenOfAnyCountryOtherThanIndia: applicant.citizenOfAnyCountryOtherThanIndia || 'no',
            countryOfBirthOtherThanIndia: applicant.countryOfBirthOtherThanIndia || 'no',
            poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || 'no',
            addressTelephoneOutsideIndia: applicant.addressTelephoneOutsideIndia || 'no',
            sourceOfWealth: applicant.sourceOfWealth || '',
            idTypeOthers: applicant.idTypeOthers || '',
            othersTinNotAvailableReason: applicant.othersTinNotAvailableReason || '',
            fatcaCrsDocumentCheck:
              applicant.fatcaCrsDocumentCheck === null && application?.hasPOA
                ? true
                : applicant.fatcaCrsDocumentCheck,
          })),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        const {
          taxResidentOfAnyCountryOtherThanIndia = false,
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];
        if (!applicants[index].fatcaCrsDocumentCheck) {
          return {
            ...applicant,
            taxResidentOfAnyCountryOtherThanIndia:
              taxResidentOfAnyCountryOtherThanIndia.toString() === 'false' ? false : true,
            // dateOfBirth: dateOfBirth ? dateOfBirth : null,
            dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
            ...rest,
            placeOfBirth: removeSingleQuote(rest.placeOfBirth),
            taxCountryName: checkifFatcaYes(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia
            )
              ? removeSingleQuote(rest.taxCountryName)
              : '',
            taxID: checkifFatcaYes(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia
            )
              ? removeSingleQuote(rest.taxID)
              : '',
            idType: checkifFatcaYes(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia
            )
              ? removeSingleQuote(rest.idType)
              : '',
            sourceOfWealth: checkifFatcaYes(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia
            )
              ? rest.sourceOfWealth
              : '',
            idTypeOthers: checkifFatcaYesAndTypeOthers(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia,
              rest.idType
            )
              ? removeSingleQuote(rest.idTypeOthers)
              : '',
            othersTinNotAvailableReason: checkifFatcaYesAndTypeOthers(
              taxResidentOfAnyCountryOtherThanIndia,
              rest.citizenOfAnyCountryOtherThanIndia,
              rest.countryOfBirthOtherThanIndia,
              rest.poaMandateHolderOutsideIndia,
              rest.addressTelephoneOutsideIndia,
              rest.idType
            )
              ? removeSingleQuote(rest.othersTinNotAvailableReason)
              : '',
          };
        } else {
          return {
            ...applicant,
            ...FatcaCrsDocumentCheckEnable(false),
          };
        }
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedPayload,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 5,
              //!!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? checkValidationBasedOnDate(application?.createdAt || '', nomineeValidationDate)
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? checkValidationBasedOnDate(application?.createdAt || '', nomineeValidationDate)
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={'FATCA Information is mandatory to disclose any Foreign Citizenship'}
          />
          {values.applicants.map((applicant, index) => (
            <FatcaDetails
              applicantName={getApplicantName(index + 1)}
              index={index}
              key={index}
              values={applicant}
              nationalitiesMdmsResponse={mdmsCountriesList}
              hasPOA={application?.hasPOA}
            />
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
