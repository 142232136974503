import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import {
  grossAnnualMasters,
  occupationDetailsMasters,
  yesAndNoMasters,
} from '../../utils/constant';

export default function KycDetails({
  application = {},
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  const { applicants = [], dpName = '', dpId = '', dpClientId = '' } = application;
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Occupation Details'}
                        description={
                          occupationDetailsMasters[applicant.occupationDetails || ''] ===
                          occupationDetailsMasters.others
                            ? applicant.otherOccupation
                            : occupationDetailsMasters[applicant.occupationDetails || '']
                        }
                      />
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={grossAnnualMasters[applicant.grossAnnualIncome || '']}
                      />
                      {/* <KeyValue
                        title={'Net-Worth (Mandatory For Non-Individuals)'}
                        description={applicant.netWorth}
                      />
                      <KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />
                      {/* <KeyValue title={'KIN Number'} description={applicant.kinNo} /> */}
                      <KeyValue
                        title={'Are you an Accredited Investor'}
                        description={yesAndNoMasters[applicant.isAccreditedInvestor || '']}
                      />
                      {yesAndNoMasters[applicant.isAccreditedInvestor || ''] ===
                        yesAndNoMasters.yes && (
                        <>
                          <KeyValue
                            title={'Issuing Authority'}
                            description={applicant.issuingAuthority}
                          />
                          <KeyValue
                            title={'Certificate No'}
                            description={applicant.certificateNo}
                          />
                          <KeyValue
                            title={'Accredited Investor From'}
                            description={formatShortDate(applicant.accreditedInvestorFrom)}
                          />
                          <KeyValue
                            title={'Accredited Investor To'}
                            description={formatShortDate(applicant.accreditedInvestorTo)}
                          />
                        </>
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
            <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>Demat Account details</SubHeading>
            <Grid
              item
              container
              pb={5}
              rowSpacing={6}
              columnSpacing={15}
              pl={{ xs: 0, sm: '10%' }}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <KeyValue title={'DP Name'} description={dpName} />
              <KeyValue title={'DP Id'} description={dpId} />
              <KeyValue title={'Client Id'} description={dpClientId} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
