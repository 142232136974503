/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  CardMedia,
  Badge,
  Pagination,
  Card,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  LinearProgress,
} from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminMakerCheckerFilterType } from '../../redux-store/types/api-types';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { RootStateType } from '../../redux-store/reducers';
import { FilterDropdown, initialValuesFilter } from './filters';
import { useDebounce } from '../../utils/useDebounce';
import {
  AdminMakerCheckerProps,
  GetAdminMakerCheckerResponseBody,
  downloadOldNewDocumentsInMiscellaneous,
  getAdminCheckerApprove,
  getAdminCheckerReject,
  getAdminMakerCheckerRequests,
} from '../../redux-store/actions/userFundsVerification';
import { UserFundsMaster } from '../../utils/constant';
import { RejectApproveButtons } from '../ApplicationDetails';
import {
  ApproveComponent,
  ConfirmationDialogWithCommentsBox,
  RejectComponent,
} from './userFundscommonComponents';
import { LoadingButton } from '@mui/lab';
import { reportDownload } from '../Reports/report-utils';
import { LoadingDialog } from '../commonComponents';
import { getRequiredForEsignType } from '../../redux-store/actions/userManagement';
import { KeyValue } from '../ApplicationDetails/contributor';
import { formatShortDateAndTime } from '../../utils/date';

export const checkSelfRequests = (loggedinAdminId: number, createdBy: number): boolean => {
  return Number(loggedinAdminId) !== Number(createdBy);
};

export default function MiscList(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [miscData, setMiscData] = useState<AdminMakerCheckerProps[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [authorizedSignatorysRequestsData, setAuthorizedSignatorysRequestsData] = useState<
    AdminMakerCheckerProps[]
  >([]);
  const [documentsSetupRequestsData, setDocumentsSetupRequestsData] = useState<
    AdminMakerCheckerProps[]
  >([]);
  const { id: loggedinAdminId = '' } = useSelector((store: RootStateType) => store.auth);

  const handleRejectPopupOpen = () => setOpenRejectPopup(true);
  const handleApprovePopupDialogOpen = () => setOpenApprovePopup(true);

  const handleRejectPopupClose = () => {
    setId(''), setOpenRejectPopup(false), setApproveLoading(false);
  };
  const handleApprovePopupClose = () => {
    setId(''), setOpenApprovePopup(false), setRejectLoading(false);
  };
  const {
    page,
    search: storeSearch,
    endpoint: storeEndpoint,
    status: storeStatus,
    createdBy: storeCreatedBy,
  } = useSelector((store: RootStateType) => store.paramsObjForAdminMakerChecker);
  const [currentPage, setCurrentPage] = useState(page);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminMakerCheckerFilterType>(
    initialValuesFilter(storeEndpoint, storeStatus, storeCreatedBy)
  );
  const { status, createdBy } = filters;
  const [search, setSearch] = useState(storeSearch);
  const debounceSearchString = useDebounce(search, 500);
  const [loadingPopup, setloadingPopup] = useState(false);

  const handleLoadingPopupClose = () => {
    setloadingPopup(false);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleFiltersAnchorEl = async () => {
    setFiltersAnchorEl(true);
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['status', 'createdBy'].filter(
      (filterKey) => (filters[filterKey] as string[]).length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setCurrentPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            displayUserRoles={false}
          />
        )}
      </>
    );
  };

  const miscellaneousAPI = async () => {
    const response = (await dispatch(
      getAdminMakerCheckerRequests({
        page: currentPage,
        search,
        endpoint: 'update-mdmsdocument,set-authorisedsignatorythreshold',
        createdBy: createdBy.toString(),
        status: status.toString(),
      })
    )) as unknown as GetAdminMakerCheckerResponseBody;
    const { activityRequests, ...rest } = response || {};
    const activeJsonData = activityRequests.map((_data) => {
      return {
        ..._data,
        currentData: _data.currentData
          ? JSON.parse(_data.currentData as string)
          : _data.currentData,
        payload: _data.payloadId || _data.payload ? JSON.parse(_data.payload as string) : null,
      };
    });

    return { activeJsonData, ...rest };
  };

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setMiscData([]);
    (async function () {
      try {
        const apiResponse = await miscellaneousAPI();
        const { activeJsonData, pages } = apiResponse || {};
        if (!componentIsAlive) {
          return;
        }

        setMiscData(activeJsonData);
        setAuthorizedSignatorysRequestsData(
          activeJsonData.filter((data) => {
            return data?.endpoint.split('-')[1] === 'authorisedsignatorythreshold';
          })
        );
        setDocumentsSetupRequestsData(
          activeJsonData.filter((data) => {
            return data?.endpoint.split('-')[1] === 'mdmsdocument';
          })
        );
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: { xs: 1, sm: 4 } }}>
        <Grid
          container
          sx={{
            p: '20px 30px',
            pb: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            bgcolor: 'common.white',
            mb: 1.5,
          }}>
          {filterOptions()}
        </Grid>

        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={12}>
            {isLoading ? (
              <LinearProgress sx={{ ml: { xs: 1, sm: 5 }, mr: { xs: 1, sm: 5 } }} />
            ) : (
              <>
                {authorizedSignatorysRequestsData &&
                  authorizedSignatorysRequestsData.length > 0 &&
                  authorizedSignatorysRequestsData.map((authorizedSignatorysData) => (
                    <Card
                      raised={true}
                      sx={{
                        height:
                          UserFundsMaster[authorizedSignatorysData?.status || ''] ===
                          UserFundsMaster.pending
                            ? checkSelfRequests(
                                loggedinAdminId,
                                Number(authorizedSignatorysData?.createdBy)
                              )
                              ? 300
                              : 230
                            : 300,
                        p: { xs: 0.5, sm: 2 },
                        width: '100%',
                        mb: 5,
                        overflow: 'auto',
                        boxSizing: 'border-box',
                      }}
                      key={authorizedSignatorysData.id}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pb: 1 }}>
                        <Typography
                          sx={{
                            color: 'primary.main',
                            fontSize: '18px',
                            letterSpacing: '0.4px',
                            fontWeight: 600,
                          }}>
                          Authorised signatories are changed
                        </Typography>
                        <Typography
                          className={UserFundsMaster[authorizedSignatorysData?.status || '']}
                          sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: 'text.secondary',
                            '&.Approved .MuiTypography-root': {
                              fontWeight: 600,
                              color: 'rgba(35, 133, 63, 0.7)',
                            },
                            '&.Rejected .MuiTypography-root': {
                              color: '#BD2932',
                              fontWeight: 500,
                            },
                          }}>
                          Status:{' '}
                          <Typography
                            component="span"
                            sx={{
                              color: '#B78813',
                              fontSize: '16px',
                              letterSpacing: '0.4px',
                              ml: 0.5,
                            }}>
                            {UserFundsMaster[authorizedSignatorysData?.status || '']}
                          </Typography>
                        </Typography>
                      </Box>
                      <Grid
                        container
                        display={'flex'}
                        sx={{
                          flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          overflowWrap: 'anywhere',
                        }}>
                        <Grid item xs={12} sm={12} lg={8} px={2}>
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                              }}>
                              Maker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {authorizedSignatorysData.makerComment || 'N/A'}
                              </Typography>
                            </Typography>
                            <Box
                              display={'flex'}
                              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                              {authorizedSignatorysData.makerAdminName && (
                                <KeyValue
                                  title={'Requested By'}
                                  description={authorizedSignatorysData.makerAdminName}
                                />
                              )}
                              {authorizedSignatorysData.createdAt && (
                                <KeyValue
                                  title={'Requested At'}
                                  description={formatShortDateAndTime(
                                    authorizedSignatorysData.createdAt
                                  )}
                                />
                              )}
                            </Box>
                          </Grid>
                          {authorizedSignatorysData?.checkerComment && (
                            <Grid item xs={12} sx={{ mt: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: 17,
                                  fontWeight: 500,
                                  color: 'text.secondary',
                                }}>
                                Checker Comments:{' '}
                                <Typography
                                  component="span"
                                  sx={{
                                    color: 'text.secondary',
                                    fontSize: '16px',
                                    letterSpacing: '0.4px',
                                    fontWeight: 700,
                                    ml: { sm: 0.5 },
                                  }}>
                                  {authorizedSignatorysData.checkerComment || 'N/A'}
                                </Typography>
                              </Typography>
                              <Box
                                display={'flex'}
                                sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                {authorizedSignatorysData.checkerAdminName && (
                                  <KeyValue
                                    title={
                                      UserFundsMaster[authorizedSignatorysData.status || ''] ===
                                      UserFundsMaster.rejected
                                        ? 'Rejected By'
                                        : 'Approved By'
                                    }
                                    description={authorizedSignatorysData.checkerAdminName}
                                  />
                                )}
                                {(authorizedSignatorysData.approvedAt ||
                                  authorizedSignatorysData.rejectedAt) && (
                                  <KeyValue
                                    title={
                                      UserFundsMaster[authorizedSignatorysData.status || ''] ===
                                      UserFundsMaster.rejected
                                        ? 'Rejected At'
                                        : 'Approved At'
                                    }
                                    description={formatShortDateAndTime(
                                      authorizedSignatorysData.approvedAt ||
                                        authorizedSignatorysData.rejectedAt
                                    )}
                                  />
                                )}
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          lg={4}
                          px={{ xs: 0, sm: 2 }}
                          mt={{ xs: 2, sm: 0 }}>
                          <Grid
                            xs={12}
                            sx={{
                              '.MuiTypography-root': {
                                textAlign: 'center',
                              },
                            }}>
                            <TableContainer sx={{ width: '100%' }}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ width: '20px !important' }}>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: 500,
                                        }}>
                                        {'New Value'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '20px !important' }}>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: 500,
                                        }}>
                                        {'Old Value'}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow
                                    sx={{
                                      '&:last-child td': { border: 0 },
                                      height: '20px !important',
                                    }}>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        height: '60px !important',
                                        overflowWrap: 'anywhere',
                                        textAlign: 'center',
                                      }}>
                                      <Typography
                                        align="left"
                                        sx={{
                                          bgcolor: 'rgb(203,245,221,0.5)',
                                          py: 0.5,
                                          px: 1.2,
                                          borderRadius: '5px',
                                          fontSize: '15px',
                                        }}>
                                        {(
                                          authorizedSignatorysData.payload as getRequiredForEsignType
                                        )?.authorisedSignatoryThreshold || 'N/A'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        height: '60px !important',
                                        overflowWrap: 'anywhere',
                                      }}>
                                      <Typography
                                        align="left"
                                        sx={{
                                          bgcolor: 'rgb(251,217,211,0.5)',
                                          py: 0.5,
                                          px: 1.2,
                                          borderRadius: '5px',
                                          fontSize: '15px',
                                        }}>
                                        {(
                                          authorizedSignatorysData.currentData as getRequiredForEsignType
                                        )?.authorisedSignatoryThreshold || 'N/A'}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                      {UserFundsMaster[authorizedSignatorysData?.status || ''] ===
                        UserFundsMaster.pending &&
                        checkSelfRequests(
                          loggedinAdminId,
                          Number(authorizedSignatorysData?.createdBy)
                        ) && (
                          <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                            <RejectApproveButtons
                              handleOpen={() => {
                                setId(authorizedSignatorysData.id as string),
                                  handleRejectPopupOpen();
                              }}
                              handleDialogOpen={() => {
                                setId(authorizedSignatorysData.id as string),
                                  handleApprovePopupDialogOpen();
                              }}
                              approve={approveLoading}
                              reject={rejectLoading}
                              displaySendBack={false}
                            />
                          </Box>
                        )}
                    </Card>
                  ))}

                {documentsSetupRequestsData &&
                  documentsSetupRequestsData.length > 0 &&
                  documentsSetupRequestsData.map((documentsSetupData) => {
                    return (
                      <Card
                        raised={true}
                        sx={{
                          height:
                            UserFundsMaster[documentsSetupData?.status || ''] ===
                            UserFundsMaster.pending
                              ? checkSelfRequests(
                                  loggedinAdminId,
                                  Number(documentsSetupData?.createdBy)
                                )
                                ? 300
                                : 230
                              : 300,
                          p: { xs: 0.5, sm: 2 },
                          width: '100%',
                          mb: 5,
                          overflow: 'auto',
                          boxSizing: 'border-box',
                        }}
                        key={documentsSetupData.id}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, pb: 1 }}>
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontSize: '18px',
                              letterSpacing: '0.4px',
                              fontWeight: 600,
                            }}>
                            Document Changes
                          </Typography>
                          <Typography
                            className={UserFundsMaster[documentsSetupData?.status || '']}
                            sx={{
                              fontSize: 18,
                              fontWeight: 600,
                              color: 'text.secondary',
                              '&.Approved .MuiTypography-root': {
                                fontWeight: 600,
                                color: 'rgba(35, 133, 63, 0.7)',
                              },
                              '&.Rejected .MuiTypography-root': {
                                color: '#BD2932',
                                fontWeight: 500,
                              },
                            }}>
                            Status:{' '}
                            <Typography
                              component="span"
                              sx={{
                                color: '#B78813',
                                fontSize: '16px',
                                letterSpacing: '0.4px',
                                ml: 0.5,
                              }}>
                              {UserFundsMaster[documentsSetupData?.status || '']}
                            </Typography>
                          </Typography>
                        </Box>
                        <Grid
                          container
                          display={'flex'}
                          sx={{
                            flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            overflowWrap: 'anywhere',
                          }}>
                          <Grid item xs={12} sm={12} lg={8} px={2}>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: 17,
                                  fontWeight: 500,
                                  color: 'text.secondary',
                                }}>
                                Maker Comments:{' '}
                                <Typography
                                  component="span"
                                  sx={{
                                    color: 'text.secondary',
                                    fontSize: '16px',
                                    letterSpacing: '0.4px',
                                    fontWeight: 700,
                                    ml: { sm: 0.5 },
                                  }}>
                                  {documentsSetupData.makerComment || 'N/A'}
                                </Typography>
                              </Typography>
                              <Box
                                display={'flex'}
                                sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                {documentsSetupData.makerAdminName && (
                                  <KeyValue
                                    title={'Requested By'}
                                    description={documentsSetupData.makerAdminName}
                                  />
                                )}
                                {documentsSetupData.createdAt && (
                                  <KeyValue
                                    title={'Requested At'}
                                    description={formatShortDateAndTime(
                                      documentsSetupData.createdAt
                                    )}
                                  />
                                )}
                              </Box>
                            </Grid>
                            {documentsSetupData?.checkerComment && (
                              <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography
                                  sx={{
                                    fontSize: 17,
                                    fontWeight: 500,
                                    color: 'text.secondary',
                                  }}>
                                  Checker Comments:{' '}
                                  <Typography
                                    component="span"
                                    sx={{
                                      color: 'text.secondary',
                                      fontSize: '16px',
                                      letterSpacing: '0.4px',
                                      fontWeight: 700,
                                      ml: { sm: 0.5 },
                                    }}>
                                    {documentsSetupData.checkerComment || 'N/A'}
                                  </Typography>
                                </Typography>
                                <Box
                                  display={'flex'}
                                  sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                  {documentsSetupData.checkerAdminName && (
                                    <KeyValue
                                      title={
                                        UserFundsMaster[documentsSetupData.status || ''] ===
                                        UserFundsMaster.rejected
                                          ? 'Rejected By'
                                          : 'Approved By'
                                      }
                                      description={documentsSetupData.checkerAdminName}
                                    />
                                  )}
                                  {(documentsSetupData.approvedAt ||
                                    documentsSetupData.rejectedAt) && (
                                    <KeyValue
                                      title={
                                        UserFundsMaster[documentsSetupData.status || ''] ===
                                        UserFundsMaster.rejected
                                          ? 'Rejected At'
                                          : 'Approved At'
                                      }
                                      description={formatShortDateAndTime(
                                        documentsSetupData.approvedAt ||
                                          documentsSetupData.rejectedAt
                                      )}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            lg={4}
                            px={{ xs: 0, sm: 2 }}
                            mt={{ xs: 2, sm: 0 }}>
                            <Grid xs={12}>
                              <TableContainer sx={{ width: '100%' }}>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ width: '20px !important' }}>
                                        <Typography
                                          sx={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                          }}>
                                          {'New Document'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{ width: '20px !important' }}>
                                        <Typography
                                          sx={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                          }}>
                                          {'Old Document'}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        '&:last-child td': { border: 0 },
                                        height: '20px !important',
                                      }}>
                                      <TableCell
                                        align="right"
                                        sx={{
                                          height: '60px !important',
                                          overflowWrap: 'anywhere',
                                        }}>
                                        <Typography
                                          align="center"
                                          sx={{
                                            bgcolor: 'rgb(203,245,221,0.5)',
                                            py: 0.5,
                                            px: 1.2,
                                            borderRadius: '5px',
                                            fontSize: '15px',
                                          }}>
                                          <IconButton
                                            sx={{
                                              ':hover': {
                                                bgcolor: '#F4FCFC',
                                              },
                                              cursor: 'pointer',
                                              pointerEvents: 'all',
                                            }}
                                            onClick={async () => {
                                              try {
                                                setloadingPopup(true);
                                                const res = (await dispatch(
                                                  downloadOldNewDocumentsInMiscellaneous(
                                                    documentsSetupData.id as string,
                                                    { documentType: 'new' }
                                                  )
                                                )) as unknown as any;
                                                reportDownload(
                                                  res as unknown as Blob,
                                                  `required_document_master_new.json`
                                                );
                                              } catch (e) {
                                                console.error((e as Error).message);
                                              } finally {
                                                handleLoadingPopupClose();
                                              }
                                            }}>
                                            <CardMedia
                                              component="img"
                                              src={'/images/download-icon.png'}
                                              alt="Download"
                                              sx={{ width: '25px' }}
                                            />
                                          </IconButton>
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        sx={{
                                          height: '60px !important',
                                          overflowWrap: 'anywhere',
                                        }}>
                                        <Typography
                                          align="center"
                                          sx={{
                                            bgcolor: 'rgb(251,217,211,0.5)',
                                            py: 0.5,
                                            px: 1.2,
                                            borderRadius: '5px',
                                            fontSize: '15px',
                                          }}>
                                          <IconButton
                                            sx={{
                                              ':hover': {
                                                bgcolor: '#F4FCFC',
                                              },
                                              cursor: 'pointer',
                                              pointerEvents: 'all',
                                            }}
                                            onClick={async () => {
                                              try {
                                                setloadingPopup(true);
                                                const res = (await dispatch(
                                                  downloadOldNewDocumentsInMiscellaneous(
                                                    documentsSetupData.id as string,
                                                    { documentType: 'old' }
                                                  )
                                                )) as unknown as any;
                                                reportDownload(
                                                  res as unknown as Blob,
                                                  `required_document_master_old.json`
                                                );
                                              } catch (e) {
                                                console.error((e as Error).message);
                                              } finally {
                                                handleLoadingPopupClose();
                                              }
                                            }}>
                                            <CardMedia
                                              component="img"
                                              src={'/images/download-icon.png'}
                                              alt="Download"
                                              sx={{ width: '25px' }}
                                            />
                                          </IconButton>
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                        {UserFundsMaster[documentsSetupData?.status || ''] ===
                          UserFundsMaster.pending &&
                          checkSelfRequests(
                            loggedinAdminId,
                            Number(documentsSetupData?.createdBy)
                          ) && (
                            <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                              <RejectApproveButtons
                                handleOpen={() => {
                                  setId(documentsSetupData.id as string), handleRejectPopupOpen();
                                }}
                                handleDialogOpen={() => {
                                  setId(documentsSetupData.id as string),
                                    handleApprovePopupDialogOpen();
                                }}
                                approve={approveLoading}
                                reject={rejectLoading}
                                displaySendBack={false}
                              />
                            </Box>
                          )}
                      </Card>
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid>
        {!!miscData.length && (
          <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
            <Pagination
              count={noOfPages}
              page={currentPage}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        )}
      </Box>

      <ConfirmationDialogWithCommentsBox
        component={<RejectComponent />}
        open={openRejectPopup}
        setOpen={handleRejectPopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleRejectPopupClose();
            // setRejectLoading(true);
            setloadingPopup(true);
            await dispatch(getAdminCheckerReject(id, { comment: values.comment }));
            const apiResponse = await miscellaneousAPI();
            const { activeJsonData, pages } = apiResponse || {};
            setMiscData(activeJsonData);
            setAuthorizedSignatorysRequestsData(
              activeJsonData.filter((data) => {
                return data?.endpoint.split('-')[1] === 'authorisedsignatorythreshold';
              })
            );
            setDocumentsSetupRequestsData(
              activeJsonData.filter((data) => {
                return data?.endpoint.split('-')[1] === 'mdmsdocument';
              })
            );
            setNoPages(pages);
          } catch (e) {
            setRejectLoading(false);
            console.error((e as Error).message);
          } finally {
            handleLoadingPopupClose();
          }
        }}
        onCancel={handleRejectPopupClose}
      />
      <ConfirmationDialogWithCommentsBox
        component={<ApproveComponent />}
        open={openApprovePopup}
        setOpen={handleApprovePopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleApprovePopupClose();
            // setApproveLoading(true);
            setloadingPopup(true);
            await dispatch(getAdminCheckerApprove(id, { comment: values.comment }));
            const apiResponse = await miscellaneousAPI();
            const { activeJsonData, pages } = apiResponse || {};
            setMiscData(activeJsonData);
            setAuthorizedSignatorysRequestsData(
              activeJsonData.filter((data) => {
                return data?.endpoint.split('-')[1] === 'authorisedsignatorythreshold';
              })
            );
            setDocumentsSetupRequestsData(
              activeJsonData.filter((data) => {
                return data?.endpoint.split('-')[1] === 'mdmsdocument';
              })
            );
            setNoPages(pages);
          } catch (e) {
            setApproveLoading(false);
            console.error((e as Error).message);
          } finally {
            handleLoadingPopupClose();
          }
        }}
        onCancel={handleApprovePopupClose}
      />
      <LoadingDialog loadingPopup={loadingPopup} onLoadingPopupClose={handleLoadingPopupClose} />
    </>
  );
}
