/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  RadioGroup,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Radio,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { AdminMakerCheckerFilterType } from '../../redux-store/types/api-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type FiltersDropDownProps = {
  filters: AdminMakerCheckerFilterType;
  anchorEl: boolean;
  onFiltersChange: (value: AdminMakerCheckerFilterType) => void;
  handleClose: () => void;
  displayUserRoles?: boolean;
};

const AccordionFilter = ({
  filterName = '',
  children,
  expanded,
  handleChange,
}: {
  filterName: string;
  children: JSX.Element;
  expanded: boolean;
  handleChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
    filterName: string
  ) => void;
}): JSX.Element => {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          boxShadow: 'none',
        },
      }}
      expanded={expanded}
      onChange={(event, expanded) => handleChange(event, expanded, filterName)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <FormLabel component="legend">{filterName}</FormLabel>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const Inival = () => {
  return {
    endpoint: [],
    status: [],
    createdBy: '',
  };
};

export const filterAdminMakerCheckerUserRoles = [
  { key: 'rm', value: 'RM' },
  { key: 'distributor', value: 'Distributor' },
  { key: 'subdistributor', value: "Distributor's RM" },
  { key: 'authorisedsignatory', value: 'Authorised Signatory' },
  { key: 'poaapprover', value: 'POA Approver' },
  { key: 'trustee', value: 'Trustee' },
  { key: 'fundmanager', value: 'Investment Managers' },
  { key: 'amcapprover', value: 'AIF Approver' },
  { key: 'amcadmin', value: 'AMC Admin' },
];

export const initialValuesFilter = (
  storeEndpoint: string,
  storeStatus: string,
  storeCreatedBy: string
): AdminMakerCheckerFilterType => {
  return {
    endpoint: storeEndpoint?.toString()?.length > 0 ? storeEndpoint.split(',') : [],
    status: storeStatus.split(',') || [],
    createdBy: storeCreatedBy || '',
  };
};

export const FilterDropdown = ({
  filters,
  anchorEl,
  onFiltersChange,
  handleClose,
  displayUserRoles = true,
}: FiltersDropDownProps): JSX.Element => {
  const {
    endpoint: storeEndpoint,
    status: storeStatus,
    createdBy: storeCreatedBy,
  } = useSelector((store: RootStateType) => store.paramsObjForAdminMakerChecker);
  const [filterExpanded, setExpanded] = useState<boolean | string>(false);
  const [modalState, setModalState] = useState(
    initialValuesFilter(storeEndpoint, storeStatus, storeCreatedBy)
  );

  useEffect(() => {
    setModalState(filters);
  }, [filters, anchorEl]);

  const _handleModalState = () => {
    handleClose();
    setModalState(initialValuesFilter(storeEndpoint, storeStatus, storeCreatedBy));
  };

  const _handleApplyFilters = () => {
    onFiltersChange(modalState);
    _handleModalState();
  };

  const filterAdminMakerCheckerstatusTypes = [
    { key: 'pending', value: 'Pending' },
    { key: 'rejected', value: 'Rejected' },
    { key: 'approved', value: 'Approved' },
  ];

  const filterAdminMakerCheckerCreatedBy = [
    { key: 'self', value: 'Self' },
    { key: 'other', value: 'Other(s)' },
  ];

  const _handleClearFilters = () => {
    onFiltersChange(Inival());
    handleClose();
  };

  const _handleSelection = (item: string | number, key: string) => {
    if (['status', 'endpoint'].includes(key)) {
      const index = (modalState[key] as string[]).indexOf(item as string);
      if (index === -1) {
        setModalState({
          ...modalState,
          [key]:
            key === 'endpoint'
              ? ([...((modalState[key] as string[]) || []), item] as string[]).filter(
                  (_endpoint) => _endpoint !== 'user'
                )
              : ([...((modalState[key] as string[]) || []), item] as string[]),
        });
      } else {
        setModalState({
          ...modalState,
          [key]:
            key === 'endpoint'
              ? (modalState[key] as string[])
                  .filter((status: any) => status != (item as string))
                  .filter((_endpoint) => _endpoint !== 'user')
              : (modalState[key] as string[]).filter((status: any) => status != (item as string)),
        });
      }
    } else {
      setModalState({
        ...modalState,
        [key]: item,
      });
    }
  };

  const handleAccordionExpanded = (
    event: React.SyntheticEvent<Element, Event>,
    isExpanded: boolean,
    panel: string
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Drawer anchor={'right'} open={anchorEl} onClose={_handleModalState}>
        <FormControl
          component="fieldset"
          sx={{
            width: '100%',
            position: 'relative',
            p: { xs: 1, sm: 2 },
            pb: 0,
            '.MuiFormLabel-root ': { color: 'primary.main', fontWeight: 600, mb: 0.5 },
            '.MuiFormControlLabel-root': {
              ml: '-2px',
              mb: 0.75,
              width: '100%',
              alignItems: 'flex-start',
              '.MuiTypography-root': {
                color: 'text.primary',
                fontWeight: 500,
                fontSize: 14,
                overflow: { xs: 'unset', md: 'hidden' },
                whiteSpace: { xs: 'unset', md: 'nowrap' },
                textOverflow: { xs: 'unset', md: 'ellipsis' },
                wordBreak: { xs: 'break-word', md: 'unset' },
              },
              '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': { color: 'text.primary' },
              '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
            },
            '.MuiAccordionDetails-root': {
              paddingTop: 0,
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              marginBottom: '10px',
            },
          }}>
          <Box>
            <IconButton sx={{ float: 'right', p: 0 }} onClick={_handleModalState}>
              <CancelOutlinedIcon sx={{ color: '#EA5167' }} fontSize="large" />
            </IconButton>
          </Box>
          <Box>
            {displayUserRoles && (
              <AccordionFilter
                filterName={'User Role'}
                expanded={filterExpanded === 'User Role'}
                handleChange={(e, expanded, filterName) =>
                  handleAccordionExpanded(e, expanded, filterName)
                }>
                <RadioGroup aria-label="endpoint" defaultValue="" name="endpoint">
                  {filterAdminMakerCheckerUserRoles.map((type: { key: string; value: string }) => (
                    <FormControlLabel
                      key={type.key}
                      value={type.key}
                      onChange={() => _handleSelection(type.key, 'endpoint')}
                      control={
                        <Checkbox size="small" checked={modalState.endpoint.includes(type.key)} />
                      }
                      label={type.value}
                      title={type.value}
                    />
                  ))}
                </RadioGroup>
              </AccordionFilter>
            )}

            <AccordionFilter
              filterName={'Status'}
              expanded={filterExpanded === 'Status'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="status" defaultValue="" name="status">
                {filterAdminMakerCheckerstatusTypes.map((type: { key: string; value: string }) => (
                  <FormControlLabel
                    key={type.key}
                    value={type.key}
                    onChange={() => _handleSelection(type.key, 'status')}
                    control={
                      <Checkbox size="small" checked={modalState.status.includes(type.key)} />
                    }
                    label={type.value}
                    title={type.value}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>

            <AccordionFilter
              filterName={'Created By'}
              expanded={filterExpanded === 'Created By'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="createdBy" defaultValue="" name="createdBy">
                {filterAdminMakerCheckerCreatedBy.map((type: { key: string; value: string }) => (
                  <FormControlLabel
                    key={type.key}
                    value={type.key}
                    onChange={() => _handleSelection(type.key, 'createdBy')}
                    control={<Radio size="small" checked={type.key === modalState.createdBy} />}
                    label={type.value}
                    title={type.value}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
              mt: 2,
              '& .MuiButton-root': {
                fontSize: 16,
                mx: 1,
                px: 3.5,
                py: 0.5,
              },
            }}>
            <Button
              type="submit"
              sx={{
                color: 'common.white',
                '&, :hover': { bgcolor: 'primary.main' },
              }}
              onClick={_handleApplyFilters}>
              Apply Filters
            </Button>
            <Button
              type="reset"
              sx={{
                color: 'text.primary',
                border: '1px solid #1F4173',
                '&, :hover': { bgcolor: 'common.white' },
              }}
              onClick={_handleClearFilters}>
              Clear Filters
            </Button>
          </Box>
        </FormControl>
      </Drawer>
    </Box>
  );
};
