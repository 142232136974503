import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/auth';

export type ParamsPropsForAdminMakerChecker = {
  page: number;
  search: string;
  endpoint: string;
  status: string;
  createdBy: string;
};

export const initialStateForAdminMakerChecker: ParamsPropsForAdminMakerChecker = {
  page: 1,
  status: 'pending',
  search: '',
  endpoint: '',
  createdBy: 'other',
};

function paramsReducerForAdminMakerChecker(
  state = initialStateForAdminMakerChecker,
  action: AnyAction
): Partial<ParamsPropsForAdminMakerChecker> {
  switch (action.type) {
    case 'GET_PARAMS_FOR_ADMIN_MAKER_CHECKER_DATA':
      return {
        ...state,
        page: action.paramsObjForAdminMakerChecker.page,
        status: action.paramsObjForAdminMakerChecker.status,
        search: action.paramsObjForAdminMakerChecker.search,
        endpoint: action.paramsObjForAdminMakerChecker.endpoint,
        createdBy: action.paramsObjForAdminMakerChecker.createdBy,
      };
    case LOGOUT_SUCCESS: {
      return { ...initialStateForAdminMakerChecker };
    }
    default:
      return state;
  }
}

export default paramsReducerForAdminMakerChecker;
