import { FormHelperText, Grid, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CommonLayout } from '../commonComponents';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { reportDownload } from '../Reports/report-utils';
import { LoadingButton } from '@mui/lab';
import {
  mdmsRequiredDocumentsMasterDownload,
  mdmsRequiredDocumentsMasterUpdate,
} from '../../redux-store/actions/documentsetup';
import { FilePond } from 'react-filepond';
import { logout } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { MFTextField } from '../../lib/formik';
import { Formik } from 'formik';
import {
  LogsUI,
  NavigationThrowPopup,
  commentValidation,
} from '../UsersFundsVerification/userFundscommonComponents';
import {
  AdminMakerCheckerProps,
  GetAdminMakerCheckerResponseBody,
  getHistoryForMisAdminMakerCheckerRequests,
} from '../../redux-store/actions/userFundsVerification';

export default function DocumentsMasterJsonUpdate(): JSX.Element {
  const [isDownloading, setIsDownloading] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const initialValueForComment: { comment: string } = { comment: '' };

  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const dispatch = useDispatch();
  const [uploadedFileDetails, setUploadedFileDetails] = useState<{
    fileId: string | number;
    fileName: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });

  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyData, setHistoryData] = useState<AdminMakerCheckerProps[]>([]);

  const historyAPICall = async () => {
    const response = (await dispatch(
      getHistoryForMisAdminMakerCheckerRequests({
        endpoint: 'update-mdmsdocument',
      })
    )) as unknown as GetAdminMakerCheckerResponseBody;
    const { activityRequests, ...rest } = response || {};
    const activeJsonData = activityRequests.map((_data) => {
      return {
        ..._data,
        currentData: _data.currentData
          ? JSON.parse(_data.currentData as string)
          : _data.currentData,
        payload: _data.payloadId || _data.payload ? JSON.parse(_data.payload as string) : null,
      };
    });
    return { activeJsonData, ...rest };
  };

  const handleRoutePopupClose = async () => {
    setRouteDialogOpen({ message: '', open: false });
    try {
      setHistoryLoading(true);
      const apiResponse = await historyAPICall();
      const { activeJsonData } = apiResponse || {};
      setHistoryData(activeJsonData);
      setHistoryLoading(false);
    } catch (e) {
      setHistoryLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let componentIsAlive = true;
    setHistoryLoading(true);
    (async function () {
      try {
        const apiResponse = await historyAPICall();
        const { activeJsonData } = apiResponse || {};
        if (!componentIsAlive) {
          return;
        }
        setHistoryData(activeJsonData);
        setHistoryLoading(false);
      } catch (e) {
        setHistoryLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  const handleFileUpload = async (values: { comment: string }, resetForm: () => void) => {
    try {
      setIsLoading(true);
      if (uploadedFileDetails) {
        const res = (await dispatch(
          mdmsRequiredDocumentsMasterUpdate(uploadedFileDetails?.fileName, {
            documentId: uploadedFileDetails?.fileId,
            comment: values.comment,
          })
        )) as unknown as any;
        if (res.message) {
          setRouteDialogOpen({
            message: res.message,
            open: true,
          });
        }
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setUploadedFileDetails(null);
      setFiles([]);
      setIsLoading(false);
      resetForm();
    }
  };

  return (
    <CommonLayout>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
          }}>
          Update Document(S) List
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '25%' } },
            px: { xs: 0, sm: '30px' },
            py: '5%',
            bgcolor: 'common.white',
            mt: 1.5,
          }}>
          {/* <Grid sx={{ width: '100%', mb: 4 }}>
            <Notes displayContent={'Please upload the template for user creation'} />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              loading={isDownloading}
              variant="outlined"
              fullWidth
              sx={{
                letterSpacing: 1,
                px: isDownloading ? 0 : 3,
                py: 1,
                mt: 1,
                mr: 4,
                color: 'primary.main',
              }}
              onClick={async () => {
                try {
                  setIsDownloading(true);
                  const res = (await dispatch(
                    mdmsRequiredDocumentsMasterDownload()
                  )) as unknown as any;
                  reportDownload(res as unknown as Blob, `required_document_master.json`); //when ever changing file name from required_document_master.json to any other name in mdms then update the same here
                } catch (e) {
                  setIsDownloading(false);
                  console.error((e as Error).message);
                } finally {
                  setIsDownloading(false);
                }
              }}>
              Click here to Download
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={12} mt={4}>
            <Formik
              onSubmit={(values, { resetForm }) => handleFileUpload(values, resetForm)}
              initialValues={initialValueForComment}
              validationSchema={commentValidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      '.filepond--credits': { display: 'none' },
                      '.filepond--label-action': {
                        textDecoration: 'none',
                        fontSize: '14px',
                      },
                      '.filepond--drop-label': {
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                        pointerEvents: 'all',
                      },
                      '.filepond--file': {
                        color: '#000',
                        flexWrap: 'wrap',
                        fontWeight: 500,
                      },
                      pt: 2,
                    }}>
                    <FilePond
                      files={files}
                      server={{
                        process: {
                          url: '/api/files/files',
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${authToken}`,
                          },
                          onload: (response): string => {
                            const result = JSON.parse(response);
                            result?.name &&
                              result?.id &&
                              setUploadedFileDetails({ fileId: result.id, fileName: result?.name });
                            return response;
                          },
                        },
                      }}
                      onprocessfile={(error, file) => {
                        if (error) {
                          if (error.code === 401) {
                            dispatch(logout());
                          }
                        }
                      }}
                      onprocessfilerevert={() => {
                        setUploadedFileDetails(null);
                      }}
                      onupdatefiles={(fileItems) => {
                        setFiles(fileItems);
                      }}
                      name="file"
                      allowMultiple={false}
                      maxFiles={1}
                      acceptedFileTypes={['application/json']}
                      labelIdle={`<span class="filepond--label-action">Upload Latest List of Documents <div>Supports: json</div></span>`}
                      labelFileTypeNotAllowed={'Invalid file type'}
                      imagePreviewHeight={100}
                    />
                    <FormHelperText sx={{ fontSize: 14, color: '#8692A6', mt: 1 }}>
                      File name and format should be same as downloaded file
                    </FormHelperText>
                  </Box>
                  <Box>
                    <Typography sx={{ color: 'primary.main', mt: 4 }}> Comment(s) *</Typography>
                    <MFTextField
                      name="comment"
                      multiline
                      rows={4}
                      placeholder="Type your answer here..."
                    />
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <LoadingButton
                      loadingPosition="start"
                      type="submit"
                      loading={isLoading}
                      variant="contained"
                      sx={{
                        minWidth: isLoading ? 180 : '',
                        letterSpacing: 1,
                        px: isLoading ? 0 : 3,
                        py: 1,
                        mt: 2,
                        mr: 4,
                      }}
                      // disabled={!uploadedFileDetails}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                  <NavigationThrowPopup
                    handleClose={handleRoutePopupClose}
                    dialogOpen={routeDialogOpen}
                    onSave={() => {
                      handleRoutePopupClose();
                    }}
                  />
                </Box>
              )}
            </Formik>
          </Grid>

          {historyData && historyData.length > 0 && (
            <LogsUI data={historyData} loading={historyLoading} />
          )}
        </Grid>
      </Box>
    </CommonLayout>
  );
}
