/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  CardMedia,
  Badge,
  Pagination,
} from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from '../DataTable';
import { Link as RouterLink } from 'react-router-dom';
import { AdminMakerCheckerFilterType } from '../../redux-store/types/api-types';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { RootStateType } from '../../redux-store/reducers';
import { FilterDropdown, initialValuesFilter } from './filters';
import { useDebounce } from '../../utils/useDebounce';
import {
  AdminMakerCheckerProps,
  GetAdminMakerCheckerResponseBody,
  getAdminMakerCheckerRequests,
} from '../../redux-store/actions/userFundsVerification';
import { UserFundsMaster } from '../../utils/constant';
import { checkSelfRequests } from './misc';

const headers = [
  {
    header: 'Plan Description',
    valueGetter: (row: AdminMakerCheckerProps) =>
      row?.payloadId || row?.payload ? (row.payload as any)?.planDescription || 'N/A' : 'N/A',
  },
  {
    header: 'Plan Code',
    valueGetter: (row: AdminMakerCheckerProps) =>
      row?.payloadId || row?.payload ? (row.payload as any)?.planCode || 'N/A' : 'N/A',
  },
  {
    header: 'Request For',
    valueGetter: (row: AdminMakerCheckerProps) =>
      row.endpoint ? row.endpoint.split('-')[0] : 'N/A',
  },
  {
    header: 'Requested By',
    valueGetter: (row: AdminMakerCheckerProps) => row.makerAdminName || 'N/A',
  },
  {
    header: 'Status',
    renderCell: (row: AdminMakerCheckerProps) => {
      const { createdBy } = useSelector(
        (store: RootStateType) => store.paramsObjForAdminMakerChecker
      );
      const { id: loggedinAdminId = '' } = useSelector((store: RootStateType) => store.auth);
      const isSelfRoute = !checkSelfRequests(loggedinAdminId, Number(row?.createdBy));
      const adminMakerCheckerStatus = UserFundsMaster[row.status || ''];
      return (
        <Typography
          className={adminMakerCheckerStatus}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
            color: '#B78813 !important',
            '&.Approved': {
              fontWeight: 600,
              color: 'rgba(35, 133, 63, 0.7) !important',
            },
            '&.Rejected': {
              color: '#BD2932 !important',
              fontWeight: 500,
            },
          }}
          component={RouterLink}
          to={isSelfRoute ? `self-request-plan-details/${row.id}` : `plan-details/${row.id}`}>
          {adminMakerCheckerStatus}
        </Typography>
      );
    },
  },
];

export default function PlansList(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [planData, setPlanData] = useState<AdminMakerCheckerProps[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const {
    page,
    search: storeSearch,
    endpoint: storeEndpoint,
    status: storeStatus,
    createdBy: storeCreatedBy,
  } = useSelector((store: RootStateType) => store.paramsObjForAdminMakerChecker);
  const [currentPage, setCurrentPage] = useState(page);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminMakerCheckerFilterType>(
    initialValuesFilter(storeEndpoint, storeStatus, storeCreatedBy)
  );
  const { status, createdBy } = filters;
  const [search, setSearch] = useState(storeSearch);
  const debounceSearchString = useDebounce(search, 500);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleFiltersAnchorEl = async () => {
    setFiltersAnchorEl(true);
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['status', 'createdBy'].filter(
      (filterKey) => (filters[filterKey] as string[]).length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setCurrentPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            displayUserRoles={false}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setPlanData([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAdminMakerCheckerRequests({
            page: currentPage,
            search,
            endpoint: 'plan',
            createdBy: createdBy.toString(),
            status: status.toString(),
          })
        )) as unknown as GetAdminMakerCheckerResponseBody;
        const { activityRequests, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setPlanData(
          activityRequests.map((_data) => {
            return {
              ..._data,
              currentData: _data.currentData
                ? JSON.parse(_data.currentData as string)
                : _data.currentData,
              payload:
                _data.payloadId || _data.payload ? JSON.parse(_data.payload as string) : null,
            };
          })
        );
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Grid
          container
          sx={{
            p: '20px 30px',
            pb: 0,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            bgcolor: 'common.white',
            mb: 1.5,
          }}>
          <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
            <Grid item>
              <FormControl variant="standard" sx={{ display: 'block' }}>
                <InputLabel
                  htmlFor="input-with-icon-adornment"
                  sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                  Search Plan
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  value={search}
                  onChange={({ target: { value } }) => {
                    setCurrentPage(1);
                    setSearch(value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchSharpIcon sx={{ color: 'primary.main' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
            <Grid item>{filterOptions()}</Grid>
          </Grid>
        </Grid>
        <Box>
          <DataTable
            tableData={planData || []}
            tableHeader={headers}
            isLoading={isLoading}
            renderAdditionalRow={true}
            tableBodyCustomStyles={{
              '.MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
            tableHeaderCustomStyles={{
              '&:last-child th': { border: 0 },
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
              },
            }}
          />
        </Box>

        {!!planData.length && (
          <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
            <Pagination
              count={noOfPages}
              page={currentPage}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        )}
      </Box>
    </>
  );
}
