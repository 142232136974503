import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  AuthorisedSignatories,
  poaApprover,
  RmsType,
  SubDistributorListType,
} from '../../redux-store/types/api-types';
import {
  getAuthorisedSignatoryById,
  getDistributorRMbyId,
  getPoaApproverById,
  getRMById,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const PoaApproverFields = ({
  poaApproverDetails,
  oldData,
}: {
  poaApproverDetails: AuthorisedSignatories;
  oldData?: null | AuthorisedSignatories;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Name'}
        description={
          poaApproverDetails.userId ? poaApproverDetails.user?.firstName : poaApproverDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                poaApproverDetails.userId
                  ? poaApproverDetails.user?.firstName
                  : poaApproverDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            poaApproverDetails.userId
              ? poaApproverDetails.user?.firstName
              : poaApproverDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name',
            poaApproverDetails.userId
              ? poaApproverDetails.user?.firstName
              : poaApproverDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={
          poaApproverDetails.userId ? poaApproverDetails.user?.email : poaApproverDetails?.email
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                poaApproverDetails.userId
                  ? poaApproverDetails.user?.email
                  : poaApproverDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            poaApproverDetails.userId ? poaApproverDetails.user?.email : poaApproverDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            poaApproverDetails.userId ? poaApproverDetails.user?.email : poaApproverDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={
          poaApproverDetails.userId ? poaApproverDetails.user?.phone : poaApproverDetails?.phone
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                poaApproverDetails.userId
                  ? poaApproverDetails.user?.phone
                  : poaApproverDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            poaApproverDetails.userId ? poaApproverDetails.user?.phone : poaApproverDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            poaApproverDetails.userId ? poaApproverDetails.user?.phone : poaApproverDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pan'}
        description={poaApproverDetails.panNumber}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.panNumber, poaApproverDetails.panNumber) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, poaApproverDetails.panNumber) &&
          handleRoutePopupOpen('Pan', poaApproverDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={'Pincode'}
        description={poaApproverDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, poaApproverDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, poaApproverDetails.pincode) &&
          handleRoutePopupOpen('Pincode', poaApproverDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={poaApproverDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, poaApproverDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, poaApproverDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', poaApproverDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={poaApproverDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, poaApproverDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, poaApproverDetails.streetName) &&
          handleRoutePopupOpen('Street Name', poaApproverDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={poaApproverDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, poaApproverDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, poaApproverDetails.state) &&
          handleRoutePopupOpen('State', poaApproverDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={poaApproverDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, poaApproverDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, poaApproverDetails.city) &&
          handleRoutePopupOpen('City', poaApproverDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={poaApproverDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, poaApproverDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, poaApproverDetails.country) &&
          handleRoutePopupOpen('Country', poaApproverDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          poaApproverDetails.userId
            ? poaApproverDetails.user?.companyName
            : poaApproverDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                poaApproverDetails.userId
                  ? poaApproverDetails.user?.companyName
                  : poaApproverDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            poaApproverDetails.userId
              ? poaApproverDetails.user?.companyName
              : poaApproverDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            poaApproverDetails.userId
              ? poaApproverDetails.user?.companyName
              : poaApproverDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function PoaApproverDetails({
  location,
}: {
  location: Location<{ poaApproverId: number }>;
}): JSX.Element {
  const { poaApproverId: id } = location.state || { poaApproverId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [poaApproverDetails, setPoaApproverDetailsDetails] = useState<poaApprover>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getPoaApproverDetails = (await dispatch(
            getPoaApproverById(id)
          )) as unknown as poaApprover;
          setPoaApproverDetailsDetails(getPoaApproverDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('poa-approver', {
                  distributorId: poaApproverDetails?.distributorId,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('poa-approver', {
                    distributorId: poaApproverDetails?.distributorId,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              POA Approver
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-poaApprover', {
                  poaApproverId: poaApproverDetails?.id,
                })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          poaApproverDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <PoaApproverFields poaApproverDetails={poaApproverDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {poaApproverDetails.history && poaApproverDetails.history.length > 0 && (
                <LogsUI data={poaApproverDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
