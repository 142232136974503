import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { useHistory } from 'react-router';

import {
  IndividualMdmsinvestorProfile,
  investorQuestionDetailType,
  investorQuestionMdsm,
  mdmsInvestorProfileQuestion,
  nonIndividualMdmsFatca,
  nonIndividualMdmsQuestionsFatca,
  nonIndividualQuestionsFatca,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import {
  applicationComparison,
  checkInvestorProfilebasedOnScheme,
  getApplicantName,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { getInvestorProfileData, updateApplication } from '../../redux-store/actions/application';
import { APPLICATION_TYPE, USER_ROLES } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { setVisibility } from '../NonIndividualInvestor/fatca';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  investorProfileDeclaration,
  investorProfileDeclarationNonIndividual,
  investorVerificationDeclaration,
  investorVerificationDeclarationNonIndividual,
} from '../../utils/declaration';

interface Values {
  applicants: {
    investorprofile: nonIndividualMdmsQuestionsFatca;
    investorProfileDeclaration: boolean;
    investorVerificationDeclaration: boolean;
  }[];
  saveType: string;
}

export const setVisibilityFalse = (
  inputQId: string,
  q: nonIndividualQuestionsFatca,
  formValues: nonIndividualMdmsQuestionsFatca
): void => {
  if (q.id === inputQId) {
    q.isVisible = false;
    if (q.answer) {
      updateVisibility(q.answer, q, formValues);
    }
  }
};

function InvestorQuestion({
  data,
  values,
  setValues,
  applicantIndex,
}: {
  data: IndividualMdmsinvestorProfile[];
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  applicantIndex: number;
}): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { setFieldValue } = useFormikContext();
  const { applicationType } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabledForPOALogin = [USER_ROLES.POAAPPROVER].includes(role);

  const _handleSelection = async (ind: number, selectedOptionObj: any, checkboxVal: string[]) => {
    const index = checkboxVal?.indexOf(selectedOptionObj.label);
    const checkboxValSelect =
      index === -1
        ? [...checkboxVal, selectedOptionObj.label]
        : checkboxVal?.filter((checkVal) => checkVal != selectedOptionObj.label);

    setValues({
      ...values,
      applicants: values.applicants.map((__applicant, indx) => {
        if (indx === applicantIndex) {
          return {
            ...__applicant,
            investorprofile: __applicant.investorprofile.map((_profile, index) => {
              if (ind === index) {
                return {
                  ..._profile,
                  checkboxVal: checkboxValSelect,
                  answer: checkboxValSelect?.toString().replace(/,/g, '*'),
                };
              }
              return _profile;
            }),
          };
        }
        return __applicant;
      }),
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {data.map((question, formValuesIndex) => {
        return (
          <>
            {question.isVisible && (
              <>
                {question.section_header && (
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: 'primary.main', ml: 4, mt: 2 }}>
                    {`${question.section_header}`}{' '}
                  </Typography>
                )}
                {question.question_type === 'single_choice_radio' && (
                  <Grid item xs={12} sm={12} sx={{ pt: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        pb: 3,
                        pt: 1,
                        fontFamily: 'Work Sans,sans-serif',
                      }}>
                      <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
                        {question.question_order}
                      </Typography>
                      <Typography
                        sx={{
                          pl: 1,
                          fontWeight: 400,
                          fontSize: 16,
                        }}>
                        {question.isMandatory === 'true'
                          ? question.question_text + ' *'
                          : question.question_text}
                      </Typography>
                    </Box>
                    <Grid xs={12}>
                      <UseRadioGroupTransparent
                        name={`applicants.${applicantIndex}.investorprofile.${formValuesIndex}.answer`}
                        items={
                          question.options?.map((option) => ({
                            label: option.label,
                            value: option.label,
                          })) || []
                        }
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            `applicants.${applicantIndex}.investorprofile.${formValuesIndex}.answer`,
                            value
                          );
                          updateVisibility(
                            value,
                            question,
                            data as IndividualMdmsinvestorProfile[]
                          );
                        }}
                        disabled={isFieldDisabledForPOALogin}
                      />
                    </Grid>
                  </Grid>
                )}
                {question.question_type === 'checkbox' && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: 16,
                        }}>{`${question.question_order} ${question.question_text} ${
                        question.isMandatory === 'true' ? '*' : ''
                      }`}</Typography>

                      {question.options?.map((option, ind) => {
                        return (
                          <>
                            <FormControlLabel
                              key={question.id}
                              value={option.label}
                              sx={{
                                width: '100%',
                                position: 'relative',
                                pb: 0,
                                ml: 0.2,
                                '.MuiFormLabel-root ': {
                                  color: 'primary.main',
                                  fontWeight: 600,
                                  mb: 0.5,
                                },
                                '.MuiFormControlLabel-root': {
                                  width: '100%',
                                  alignItems: 'flex-start',
                                  '.MuiTypography-root': {
                                    color: 'text.primary',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    overflow: { xs: 'unset', md: 'hidden' },
                                    whiteSpace: { xs: 'unset', md: 'nowrap' },
                                    textOverflow: { xs: 'unset', md: 'ellipsis' },
                                    wordBreak: { xs: 'break-word', md: 'unset' },
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': {
                                    color: 'text.primary',
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
                                },
                                '.MuiAccordionDetails-root': {
                                  paddingTop: 0,
                                },
                                '.MuiAccordionSummary-content.Mui-expanded': {
                                  marginBottom: '10px',
                                },
                              }}
                              onChange={() => {
                                _handleSelection(
                                  formValuesIndex,
                                  option,
                                  question.checkboxVal || []
                                );
                              }}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={question.checkboxVal
                                    ?.map((checkVal) => checkVal.toString())
                                    .includes(option.label)}
                                  disabled={isFieldDisabledForPOALogin}
                                />
                              }
                              label={option.label}
                              title={option.label}
                              disabled={isFieldDisabledForPOALogin}
                            />
                          </>
                        );
                      })}
                    </Grid>
                  </>
                )}
                {question.question_type === 'textbox' && (
                  <Grid item xs={12} sm={12}>
                    <Typography
                      sx={{
                        transform: 'unset',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                        mt: 2,
                      }}>
                      {question.question_text &&
                        (question.isMandatory === 'true'
                          ? question.question_order + question.question_text + ' *'
                          : question.question_order + ` ${question.question_text}`)}
                    </Typography>
                    <MFTextField
                      name={`applicants.${applicantIndex}.investorprofile.${formValuesIndex}.answer`}
                      placeholder={'Please enter text here'}
                      sx={{
                        '&.MuiInputBase-root': {
                          position: 'relative',
                          border: 0,
                          borderBottom: '1px solid #ccc',
                          fontSize: 16,
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: 14,
                          color: '#131836',
                          pt: 0,
                        },
                      }}
                      disabled={isFieldDisabledForPOALogin}
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        );
      })}
      <Grid item xs={12}>
        <MFCheckbox
          name={`applicants.${applicantIndex}.investorProfileDeclaration`}
          label={
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? investorProfileDeclarationNonIndividual
              : investorProfileDeclaration
          }
          sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}
          disabled={isFieldDisabledForPOALogin}
        />
      </Grid>
      <Grid item xs={12}>
        <MFCheckbox
          name={`applicants.${applicantIndex}.investorVerificationDeclaration`}
          label={
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? investorVerificationDeclarationNonIndividual
              : investorVerificationDeclaration
          }
          sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}
          disabled={isFieldDisabledForPOALogin}
        />
      </Grid>
    </Box>
  );
}

export function updateVisibility(
  answer: string,
  q: Partial<nonIndividualMdmsFatca>,
  formValues: investorQuestionMdsm
): void {
  if (
    q.question_type === 'single_choice_radio' ||
    q.question_type === 'checkbox' ||
    q.question_type === 'textbox'
  ) {
    const filteredOption = q.options?.filter((option) => option.label === answer);
    const showQs =
      filteredOption && filteredOption?.length > 0 ? filteredOption[0].showQs || [] : [];
    const hideQs =
      filteredOption && filteredOption?.length > 0 ? filteredOption[0].hideQs || [] : [];
    showQs?.forEach((qns) => {
      formValues.forEach((ques) => {
        setVisibility(qns, ques, formValues);
      });
    });
    hideQs?.forEach((qns) => {
      formValues.forEach((ques) => {
        setVisibilityFalse(qns, ques, formValues);
      });
    });
  }
}

export const AssignAnsAndVisibilityForInitialInvestorQuestion = (
  investorQuestion: investorQuestionMdsm,
  existingInvestorQuestion: investorQuestionDetailType
): any => {
  const assignAns = investorQuestion.map((_question) => {
    if (existingInvestorQuestion[_question.backend_key as keyof investorQuestionDetailType]) {
      if (_question.question_type === 'checkbox') {
        const str = existingInvestorQuestion[
          _question.backend_key as keyof investorQuestionDetailType
        ] as string;
        const arr = str.split('*');
        const checkboxAns = arr.join(',').split(',');
        return {
          ..._question,
          checkboxVal: checkboxAns,
          answer: existingInvestorQuestion[
            _question.backend_key as keyof investorQuestionDetailType
          ] as string,
        };
      }
      return {
        ..._question,
        answer: existingInvestorQuestion[
          _question.backend_key as keyof investorQuestionDetailType
        ] as string,
      };
    }
    return _question;
  });

  const finalInitialFatcaQns = assignAns.map((_q) => {
    if (
      (_q.question_type === 'single_choice_radio' ||
        _q.question_type === 'checkbox' ||
        _q.question_type === 'textbox') &&
      _q.answer &&
      _q.answer.length > 0
    ) {
      updateVisibility(_q.answer, _q, assignAns);
    }

    return _q;
  });
  return finalInitialFatcaQns;
};

export default function investorQuestionnaires(): JSX.Element {
  const [questionsInvestor, setQuestionInvestor] = useState<IndividualMdmsinvestorProfile[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const { scheme, applicationType } = application || {};
        const response = (await dispatch(
          getInvestorProfileData()
        )) as unknown as mdmsInvestorProfileQuestion;
        applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? setQuestionInvestor(response.investor_question_non_individual)
          : setQuestionInvestor(response.investor_question_individual);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const { applicants = [] } = application || {};

  const initialValues: Values = useMemo(() => {
    return {
      applicants: applicants.map((applicant) => {
        const { investorprofile: existingInvestorprofile = null } = applicant || {};
        return {
          investorprofile: existingInvestorprofile
            ? AssignAnsAndVisibilityForInitialInvestorQuestion(
                questionsInvestor,
                existingInvestorprofile
              )
            : questionsInvestor,
          investorProfileDeclaration: applicant.investorProfileDeclaration || false,
          investorVerificationDeclaration: applicant.investorProfileDeclaration || false,
        };
      }),
      saveType: 'save and proceed',
    };
  }, [application, questionsInvestor]);

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
      } = application || {};

      applicants.map((_profile) => {
        _profile.investorprofile.map((question) => {
          if (
            question.isVisible &&
            !question.answer &&
            question.isMandatory === 'true' &&
            checkInvestorProfilebasedOnScheme(application?.scheme?.schemeCode || '')
          ) {
            throw 'Please fill all the required(*) fields';
          }
        });
        if (
          (!_profile.investorProfileDeclaration || !_profile.investorVerificationDeclaration) &&
          checkInvestorProfilebasedOnScheme(application?.scheme?.schemeCode || '')
        ) {
          throw 'Please fill all the Declarations';
        }
      });

      const updatedApplicants = exisitingApplicants.map((applicant, index) => {
        const { investorprofile = null } = applicants[index] || {};
        const { ...rest } = applicant.investorprofile || {};
        const investorQuestionObj = investorprofile?.reduce((prev, curr) => {
          return (
            curr.backend_key && {
              ...prev,
              [curr.backend_key as string]: '',
            }
          );
        }, {} as any) as investorQuestionDetailType;

        const finalProfileQuestion = investorprofile?.reduce((prev, curr) => {
          if (curr.answer && curr.isVisible) {
            return { ...prev, [curr.backend_key as string]: curr.answer };
          }

          return prev;
        }, {}) as investorQuestionDetailType;

        return {
          ...applicant,
          investorprofile: checkInvestorProfilebasedOnScheme(application?.scheme?.schemeCode || '')
            ? {
                ...rest,
                ...investorQuestionObj,
                ...finalProfileQuestion,
                id: applicant?.investorprofile?.id || null,
                applicantId: applicant?.investorprofile?.applicantId || null,
                detailOfInvestmentExperience: finalProfileQuestion.detailOfInvestmentExperience
                  ? removeSingleQuote(finalProfileQuestion.detailOfInvestmentExperience)
                  : investorQuestionObj.detailOfInvestmentExperience || null,
                describeDetailOfWork: finalProfileQuestion.describeDetailOfWork
                  ? removeSingleQuote(finalProfileQuestion.describeDetailOfWork)
                  : investorQuestionObj.describeDetailOfWork || null,
                yearOfTradingExperience: finalProfileQuestion.yearOfTradingExperience
                  ? removeSingleQuote(finalProfileQuestion.yearOfTradingExperience)
                  : investorQuestionObj.yearOfTradingExperience || null,
                detailOfTradingExperience: finalProfileQuestion.detailOfTradingExperience
                  ? removeSingleQuote(finalProfileQuestion.detailOfTradingExperience)
                  : investorQuestionObj.detailOfTradingExperience || null,
                otherTradingDetails: finalProfileQuestion.otherTradingDetails
                  ? removeSingleQuote(finalProfileQuestion.otherTradingDetails)
                  : investorQuestionObj.otherTradingDetails || null,
                detailOfSerialEntrepreneur: finalProfileQuestion.detailOfSerialEntrepreneur
                  ? removeSingleQuote(finalProfileQuestion.detailOfSerialEntrepreneur)
                  : investorQuestionObj.detailOfSerialEntrepreneur || null,
                detailOfPreferredIndustry: finalProfileQuestion.detailOfPreferredIndustry
                  ? removeSingleQuote(finalProfileQuestion.detailOfPreferredIndustry)
                  : investorQuestionObj.detailOfPreferredIndustry || null,
              }
            : {
                ...rest,
                ...investorQuestionObj,
              },
          investorProfileDeclaration: checkInvestorProfilebasedOnScheme(
            application?.scheme?.schemeCode || ''
          )
            ? applicants[index].investorProfileDeclaration
            : false,
          investorVerificationDeclaration: checkInvestorProfilebasedOnScheme(
            application?.scheme?.schemeCode || ''
          )
            ? applicants[index].investorVerificationDeclaration
            : false,
        };
      });
      const checkApplication = applicationComparison(
        {
          ...application,
        },
        {
          ...application,
          applicants: updatedApplicants,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 6
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 9
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 7 : 10,
            },
            applicationId: id,
            ...(saveType !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('document-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('document-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={investorQuestionnairesSheme}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((applicant, idx) => (
            <>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                Investor Questionnaire of {getApplicantName(idx + 1, true)} Applicant
              </SubHeading>

              <Notes displayContent={'Investor Questionnaire is only asked for Angel Fund'} />
              {checkInvestorProfilebasedOnScheme(application?.scheme?.schemeCode || '') && (
                <InvestorQuestion
                  data={applicant.investorprofile as IndividualMdmsinvestorProfile[]}
                  values={values}
                  setValues={setValues}
                  applicantIndex={idx}
                />
              )}
            </>
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
