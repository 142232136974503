import {
  Box,
  Typography,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  Declaration,
  Document,
  esignApplicationResponse,
  getAuthorizedSignatoriesDetailsByRefrenceType,
  Groups,
  GroupSignatories,
  individuals_Poa_nonIndividuals_Documents,
  mdmsInvestorProfileQuestion,
  poaAuthorisedSignatoriesType,
  RiskProfileMaster,
  uboTypes,
} from '../../redux-store/types/api-types';
import {
  getApplicationDetailsWithRefId,
  sendEsignAuthorised,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ESIGN_STATUS } from '../../utils/constant';
import {
  provideConsentApplication,
  getDocuments,
  getInvestorProfileData,
  getUboTypes,
  isFormValidForSubmission,
  nonIndividualFormValidForSubmission,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { nationaliyType } from '../../redux-store/types/mdms';
import { Formik } from 'formik';
import { Notes } from '../investors/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { NonIndividualApplicationLayout } from '../NonIndividualApplicationDetails';
import { Values } from '../Investments/investor-application';
import MFCheckbox from '../../lib/formik/Checkbox';
import { getNationalityList, riskProfileMasterData } from '../../redux-store/actions';
import {
  checkIfApplicationIsNonIndividual,
  checkIfApplicationIsNonIndividualPOA,
} from '../../utils/utilityFunctions';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails';
import {
  kycDeclaration,
  kycDocumentsDeclaration,
  ppmAcknowledge,
} from '../Investments/Declarations';
import { useFeatureToggles } from '../../utils/toggler';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

const initialValues: Values = {
  ppmAcknowledge: true,
  kycDeclaration: true,
  kycDocumentsDeclaration: true,
};

export const getGroupAuthorizedSignatories = (
  authorizedSignatories: Groups[]
): GroupSignatories[] => {
  return authorizedSignatories
    ?.map((_group) => _group.groupsignatories)
    ?.flat()
    .filter((item) => item.canEsign) as GroupSignatories[];
};

export const getPoaAuthorizedSignatories = (
  authorizedSignatories: poaAuthorisedSignatoriesType[]
): poaAuthorisedSignatoriesType[] => {
  return authorizedSignatories
    ?.flat()
    .filter((item) => item.canEsign) as poaAuthorisedSignatoriesType[];
};

const getAuthorisedSignatories = (application: ApplicationProps): Declaration[] => {
  if (application?.hasPOA) {
    return getPoaAuthorizedSignatories(
      application?.distributor?.authorisedsignatories as poaAuthorisedSignatoriesType[]
    )?.map((signatory) => signatory?.declaration) as Declaration[];
  } else {
    return getGroupAuthorizedSignatories(application?.groups as Groups[])?.map(
      (signatory) => signatory?.declaration
    ) as Declaration[];
  }
};

const getDeclarationAuthorizedSignatory = (application: ApplicationProps): Declaration => {
  const declarations = getAuthorisedSignatories(application);
  return {
    kycDeclaration: declarations.some((item) => item.kycDeclaration),
    kycDocumentsDeclaration: declarations.some((item) => item.kycDocumentsDeclaration),
    ppmAcknowledge: declarations.some((item) => item.ppmAcknowledge),
  };
};

export default function AuthorizedSignatoriesInvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [application, setApplication] = useState<ApplicationProps>();
  const [loading, setLoading] = useState(false);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authorizedSignatory, setAuthorizedSignatory] = useState(initialValues);
  const [consentInfo, setConsentInfo] = useState('');
  const [signatoryDeclaration, setSignatoryDeclaration] = useState<Declaration>();
  const [nationalitiesMdmsMasters, setNationalitiesMdmsMasters] = useState<nationaliyType>(
    {} as nationaliyType
  );
  const [documentsResponse, setDocumentsResponse] = useState<Document>({} as Document);
  const [getInvestorType, setGetInvestorType] = useState<string>('');
  const [questionsInvestor, setQuestionsInvestor] = useState<mdmsInvestorProfileQuestion>(
    {} as mdmsInvestorProfileQuestion
  );
  const [riskProfile, setRiskProfile] = useState<RiskProfileMaster>({} as RiskProfileMaster);
  const [uboTypesMdms, setUboTypesMdms] = useState<uboTypes>({} as uboTypes);

  const dateFeatureToggles = useFeatureToggles();

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setNationalitiesMdmsMasters(nationalitiesMdmsMasters);
        const documentsResponse = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentsResponse(documentsResponse);
        const questionsInvestor = (await dispatch(
          getInvestorProfileData()
        )) as unknown as mdmsInvestorProfileQuestion;
        setQuestionsInvestor(questionsInvestor);
        const risk = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
        setRiskProfile(risk);
        let uboTypesMdms: uboTypes = {} as uboTypes;
        if (checkIfApplicationIsNonIndividual(_application as ApplicationProps))
          uboTypesMdms = (await dispatch(getUboTypes())) as unknown as uboTypes;
        setUboTypesMdms(uboTypesMdms);
        const getInvestorType = _application?.applicants[0]?.investorType?.toString();
        setGetInvestorType(getInvestorType || '');
        const signatoryDeclarations = getDeclarationAuthorizedSignatory(_application);
        setSignatoryDeclaration(signatoryDeclarations);
        if (
          signatoryDeclarations?.ppmAcknowledge &&
          signatoryDeclarations?.kycDeclaration &&
          signatoryDeclarations?.kycDocumentsDeclaration
        ) {
          if (!_application?.signDetails?.url) {
            if (checkIfApplicationIsNonIndividual(_application as ApplicationProps)) {
              const nonIndividualDoc =
                documentsResponse[
                  `${
                    checkIfApplicationIsNonIndividualPOA(_application as ApplicationProps)
                      ? `${getInvestorType}_poa`
                      : getInvestorType
                  }`
                ];
              await nonIndividualFormValidForSubmission(
                _application as ApplicationProps,
                nonIndividualDoc as individuals_Poa_nonIndividuals_Documents[],
                questionsInvestor?.investor_question_non_individual,
                nationalitiesMdmsMasters?.countries,
                uboTypesMdms,
                risk,
                dateFeatureToggles,
                true,
                auth.role
              );
            } else {
              await isFormValidForSubmission(
                _application as ApplicationProps,
                risk,
                questionsInvestor.investor_question_individual,
                dateFeatureToggles,
                true,
                true,
                nationalitiesMdmsMasters,
                documentsResponse,
                auth.role
              );
            }
          }
          throw '';
        }
      } catch (e) {
        typeof e === 'string' && setConsentInfo(e);
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/authorized-signatories-investment-details/${referenceId}/fund-details`);
    }
  }, []);

  const handleEsign = async (values: Values) => {
    try {
      setIsSubmitting(true);
      setConsentInfo('');
      const { id, signDetails } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }
      if (!values.kycDeclaration || !values.kycDocumentsDeclaration || !values.ppmAcknowledge) {
        throw `Please Provide Declaration`;
      }
      let signatory = getDeclarationAuthorizedSignatory(application as ApplicationProps);
      setSignatoryDeclaration(signatory);
      if (
        !signatory?.kycDeclaration &&
        !signatory?.ppmAcknowledge &&
        !signatory?.kycDocumentsDeclaration
      ) {
        if (ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status) {
          if (checkIfApplicationIsNonIndividual(application as ApplicationProps)) {
            const nonIndividualDoc =
              documentsResponse[
                `${
                  checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)
                    ? `${getInvestorType}_poa`
                    : getInvestorType
                }`
              ];
            await nonIndividualFormValidForSubmission(
              application as ApplicationProps,
              nonIndividualDoc as individuals_Poa_nonIndividuals_Documents[],
              questionsInvestor.investor_question_non_individual,
              nationalitiesMdmsMasters.countries,
              uboTypesMdms,
              riskProfile,
              dateFeatureToggles,
              true,
              auth.role
            );
          } else {
            await isFormValidForSubmission(
              application as ApplicationProps,
              riskProfile,
              questionsInvestor.investor_question_individual,
              dateFeatureToggles,
              true,
              true,
              nationalitiesMdmsMasters,
              documentsResponse,
              auth.role
            );
          }
        }
      }
      if (id) {
        const applicationResponse = (!signatory?.kycDeclaration &&
        !signatory?.ppmAcknowledge &&
        !signatory?.kycDocumentsDeclaration
          ? await dispatch(
              provideConsentApplication({
                body: {
                  ppmAcknowledge: values.ppmAcknowledge,
                  kycDeclaration: values.kycDeclaration,
                  kycDocumentsDeclaration: values.kycDocumentsDeclaration,
                },
                referenceId: referenceId,
              })
            )
          : application) as unknown as ApplicationProps;
        setApplication(applicationResponse);
        signatory = getDeclarationAuthorizedSignatory(applicationResponse);
        setSignatoryDeclaration(signatory);
        if (id) {
          setIsSubmitting(true);
          if (
            getAuthorisedSignatories(applicationResponse as ApplicationProps)?.some(
              (declaration) =>
                declaration?.ppmAcknowledge &&
                declaration?.kycDeclaration &&
                declaration?.kycDocumentsDeclaration
            )
          ) {
            const response = (await dispatch(
              sendEsignAuthorised(referenceId)
            )) as unknown as esignApplicationResponse;
            const _application = {
              ...application,
              signDetails: response,
            } as unknown as ApplicationProps;
            setApplication(_application);
            window.open(response?.url, '_blank');
            return;
          }
        }
      }
    } catch (e) {
      if (typeof e === 'string') {
        setConsentInfo(e);
        dispatch(showError(e as string));
      }
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={authorizedSignatory} onSubmit={handleEsign} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box>
              <CardMedia
                component="img"
                src="/images/kotak-logo.png"
                alt="Kotak Logo"
                sx={{ width: '190px', m: 3, my: 1 }}
              />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 10,
                flexDirection: { xs: 'column', sm: 'row' },
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
            </Box>
            {loading && <LinearProgress />}
            {application && (
              <>
                {checkIfApplicationIsNonIndividual(application) ? (
                  <NonIndividualApplicationLayout
                    loading={loading}
                    application={application as ApplicationProps}
                  />
                ) : (
                  <ApplicationDetailsCommonLayout
                    loading={loading}
                    application={application as ApplicationProps}
                  />
                )}

                <Grid item xs={12} sx={{ mt: 4 }}>
                  <MFCheckbox
                    name={`kycDeclaration`}
                    disabled={signatoryDeclaration?.kycDeclaration as boolean}
                    label={kycDeclaration()}
                    sx={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        kycDeclaration: checked,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFCheckbox
                    name={`ppmAcknowledge`}
                    disabled={signatoryDeclaration?.ppmAcknowledge as boolean}
                    label={ppmAcknowledge(application?.plan?.tcLink)}
                    sx={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        ppmAcknowledge: checked,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFCheckbox
                    name={`kycDocumentsDeclaration`}
                    disabled={signatoryDeclaration?.kycDocumentsDeclaration as boolean}
                    label={kycDocumentsDeclaration()}
                    sx={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        kycDocumentsDeclaration: checked,
                      });
                    }}
                  />
                </Grid>

                {!isSubmitting && consentInfo && (
                  <Box sx={{ mt: 4 }}>
                    <Notes displayContent={consentInfo} />
                  </Box>
                )}
                {![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                  application?.signDetails?.status
                ) && (
                  <Box sx={{ textAlign: 'center' }}>
                    <LoadingButton
                      variant="contained"
                      loadingPosition="start"
                      type="submit"
                      sx={{
                        color: 'common.white',
                        minWidth: '200px',
                        mt: consentInfo ? 1 : 4,
                        fontWeight: 600,
                        lineHeight: 1.5,
                        px: 4,
                      }}
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        ((signatoryDeclaration?.ppmAcknowledge &&
                          signatoryDeclaration?.kycDeclaration &&
                          signatoryDeclaration?.kycDocumentsDeclaration &&
                          consentInfo) as boolean)
                      }>
                      {!(
                        signatoryDeclaration?.ppmAcknowledge &&
                        signatoryDeclaration?.kycDeclaration &&
                        signatoryDeclaration?.kycDocumentsDeclaration
                      )
                        ? 'Submit Consent'
                        : signatoryDeclaration?.ppmAcknowledge &&
                          signatoryDeclaration?.kycDeclaration &&
                          signatoryDeclaration?.kycDocumentsDeclaration &&
                          consentInfo
                        ? 'Consent Submitted'
                        : 'e-Sign'}
                    </LoadingButton>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Footer />
          <Dialog
            open={isSubmitting}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
              {'Please Wait'}
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center', px: 8 }}>
              <DialogContentText sx={{ mb: 4 }} id="alert-dialog-description">
                Don&#39;t refresh or click on back button
              </DialogContentText>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Formik>
  );
}
