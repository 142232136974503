import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { FundManager, RmsType, Trustee } from '../../redux-store/types/api-types';
import {
  AifApproverAccess,
  getAIFApproverById,
  getAmcApproverAccess,
  getFundManagersById,
  getIMAccess,
  getRMById,
  gettrusteeAccess,
  getTrustesById,
  IMAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const AifApproverFields = ({
  aifApproverDetails,
  oldData,
}: {
  aifApproverDetails: Trustee;
  oldData?: null | Trustee;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Name'}
        description={
          aifApproverDetails.userId ? aifApproverDetails.user?.firstName : aifApproverDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                aifApproverDetails.userId
                  ? aifApproverDetails.user?.firstName
                  : aifApproverDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            aifApproverDetails.userId
              ? aifApproverDetails.user?.firstName
              : aifApproverDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name',
            aifApproverDetails.userId
              ? aifApproverDetails.user?.firstName
              : aifApproverDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={
          aifApproverDetails.userId ? aifApproverDetails.user?.email : aifApproverDetails?.email
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                aifApproverDetails.userId
                  ? aifApproverDetails.user?.email
                  : aifApproverDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            aifApproverDetails.userId ? aifApproverDetails.user?.email : aifApproverDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            aifApproverDetails.userId ? aifApproverDetails.user?.email : aifApproverDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={
          aifApproverDetails.userId ? aifApproverDetails.user?.phone : aifApproverDetails?.phone
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                aifApproverDetails.userId
                  ? aifApproverDetails.user?.phone
                  : aifApproverDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            aifApproverDetails.userId ? aifApproverDetails.user?.phone : aifApproverDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            aifApproverDetails.userId ? aifApproverDetails.user?.phone : aifApproverDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pincode'}
        description={aifApproverDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, aifApproverDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, aifApproverDetails.pincode) &&
          handleRoutePopupOpen('Pincode', aifApproverDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={aifApproverDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, aifApproverDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, aifApproverDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', aifApproverDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={aifApproverDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, aifApproverDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, aifApproverDetails.streetName) &&
          handleRoutePopupOpen('Street Name', aifApproverDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={aifApproverDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, aifApproverDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, aifApproverDetails.state) &&
          handleRoutePopupOpen('State', aifApproverDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={aifApproverDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, aifApproverDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, aifApproverDetails.city) &&
          handleRoutePopupOpen('City', aifApproverDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={aifApproverDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, aifApproverDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, aifApproverDetails.country) &&
          handleRoutePopupOpen('Country', aifApproverDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          aifApproverDetails.userId
            ? aifApproverDetails.user?.companyName
            : aifApproverDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                aifApproverDetails.userId
                  ? aifApproverDetails.user?.companyName
                  : aifApproverDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            aifApproverDetails.userId
              ? aifApproverDetails.user?.companyName
              : aifApproverDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            aifApproverDetails.userId
              ? aifApproverDetails.user?.companyName
              : aifApproverDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function AIFApproverDetails({
  location,
}: {
  location: Location<{ aifApproverId: number }>;
}): JSX.Element {
  const { aifApproverId: id } = location.state || { aifApproverId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [aifApproverDetails, setAifApproverDetails] = useState<FundManager>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { amcApproverAccess } = (await dispatch(
          getAmcApproverAccess()
        )) as unknown as AifApproverAccess;
        if (id) {
          const getAifApproverDetails = (await dispatch(
            getAIFApproverById(id)
          )) as unknown as FundManager;
          setAifApproverDetails(getAifApproverDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(amcApproverAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('aif-approver')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('aif-approver')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              AIF Approver
            </Typography>
          </Box>
          {accessForEditAdd && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push('edit-aifapprover', { aifApproverId: aifApproverDetails?.id })
                }
              />
            </IconButton>
          )}
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          aifApproverDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <AifApproverFields aifApproverDetails={aifApproverDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {aifApproverDetails.history && aifApproverDetails.history.length > 0 && (
                <LogsUI data={aifApproverDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
