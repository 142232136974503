import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { RmsType } from '../../redux-store/types/api-types';
import { getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { DataTable, WebViewDataRow } from '../DataTable';
import {
  LogsUI,
  NavigationThrowPopup,
  ShowFieldUpdatedValuePopup,
  logHeaders,
} from '../UsersFundsVerification/userFundscommonComponents';
import { SubHeading } from '../investors/components';
import { applicationComparison } from '../../utils/utilityFunctions';
import { yesAndNoTrueAndFalseMasters } from '../../utils/constant';

export const RmFields = ({
  rmDetails,
  oldData,
}: {
  rmDetails: RmsType;
  oldData?: null | RmsType;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'RM Name'}
        description={rmDetails.name}
        dispalyModifiedIcon={oldData ? !applicationComparison(oldData.name, rmDetails.name) : false}
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.name, rmDetails.name) &&
          handleRoutePopupOpen('RM Name', rmDetails.name, oldData.name)
        }
      />
      <KeyValue
        title={'Email Id'}
        description={rmDetails.userId ? rmDetails.user?.email : rmDetails?.email}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                rmDetails.userId ? rmDetails.user?.email : rmDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            rmDetails.userId ? rmDetails.user?.email : rmDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            rmDetails.userId ? rmDetails.user?.email : rmDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Phone Number'}
        description={rmDetails.userId ? rmDetails.user?.phone : rmDetails?.phone}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                rmDetails.userId ? rmDetails.user?.phone : rmDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            rmDetails.userId ? rmDetails.user?.phone : rmDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Phone Number',
            rmDetails.userId ? rmDetails.user?.phone : rmDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pan'}
        description={rmDetails.panNumber}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.panNumber, rmDetails.panNumber) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, rmDetails.panNumber) &&
          handleRoutePopupOpen('Pan', rmDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={'Emp Code'}
        description={rmDetails.empCode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.empCode, rmDetails.empCode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.empCode, rmDetails.empCode) &&
          handleRoutePopupOpen('Emp Code', rmDetails.empCode, oldData.empCode)
        }
      />
      <KeyValue
        title={'Pincode'}
        description={rmDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, rmDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, rmDetails.pincode) &&
          handleRoutePopupOpen('Pincode', rmDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={rmDetails.buildingNo}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.buildingNo, rmDetails.buildingNo) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, rmDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', rmDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={rmDetails.streetName}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.streetName, rmDetails.streetName) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, rmDetails.streetName) &&
          handleRoutePopupOpen('Street Name', rmDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={rmDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, rmDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, rmDetails.state) &&
          handleRoutePopupOpen('State', rmDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={rmDetails.city}
        dispalyModifiedIcon={oldData ? !applicationComparison(oldData.city, rmDetails.city) : false}
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, rmDetails.city) &&
          handleRoutePopupOpen('City', rmDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={rmDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, rmDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, rmDetails.country) &&
          handleRoutePopupOpen('Country', rmDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={rmDetails.userId ? rmDetails.user?.companyName : rmDetails?.companyName}
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                rmDetails.userId ? rmDetails.user?.companyName : rmDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            rmDetails.userId ? rmDetails.user?.companyName : rmDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            rmDetails.userId ? rmDetails.user?.companyName : rmDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function RMDetails({
  location,
}: {
  location: Location<{ rmId: number }>;
}): JSX.Element {
  const { rmId: id } = location.state || { rmId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [rmDetails, setRMDetails] = useState<RmsType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getRmDetails = (await dispatch(getRMById(Number(id)))) as unknown as RmsType;
          setRMDetails(getRmDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('relationship-managers')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('relationship-managers')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              RM Details
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() => history.push('edit-rm-details', { rmId: rmDetails?.id })}
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          rmDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <RmFields rmDetails={rmDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {rmDetails.history && rmDetails.history.length > 0 && (
                <LogsUI data={rmDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
