import {
  Box,
  Button,
  Typography,
  Hidden,
  Grid,
  IconButton,
  Tooltip,
  Stack,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  getAuthorisedSignatoryList,
  getRequiredForEsign,
  getRequiredForEsignType,
  setRequiredForEsign,
  updateAuthorisedSignatory,
} from '../../redux-store/actions/userManagement';
import {
  AddAuthorisedSignatoryRequestBody,
  AuthorisedSignatories,
} from '../../redux-store/types/api-types';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { LoadingDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { getArrayLength } from '../../utils/utilityFunctions';
import {
  AccessComponent,
  ConfirmationDialogWithCommentsBox,
  LogsUI,
  NavigationThrowPopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import {
  AdminMakerCheckerProps,
  GetAdminMakerCheckerResponseBody,
  getHistoryForMisAdminMakerCheckerRequests,
} from '../../redux-store/actions/userFundsVerification';
import { showError } from '../../redux-store/actions/auth';

export default function AuthorisedSignatory({
  location,
}: {
  location: Location<{ distributorId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [authorisedSignatoryData, setAuthorisedSignatoryData] = useState<AuthorisedSignatories[]>(
    []
  );
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const { distributorId } = location.state || {};
  const [openDialog, setDialog] = useState<{
    authorisedSignatoryRowData: AddAuthorisedSignatoryRequestBody;
  } | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<number | null>(null);
  const [requiredEsignVal, setRequiredEsignVal] = useState<number[]>([]);
  const [RequireEsignValueRes, SetRequireEsignValueRes] = useState(0);
  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const [loadingPopup, setloadingPopup] = useState(false);
  const [dialogOpenForEsignCountSet, setDialogOpenForEsignCountSet] = useState({
    message: '',
    open: false,
  });
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyData, setHistoryData] = useState<AdminMakerCheckerProps[]>([]);

  const historyAPICall = async () => {
    const response = (await dispatch(
      getHistoryForMisAdminMakerCheckerRequests({
        endpoint: 'set-authorisedsignatorythreshold',
        payloadId: distributorId?.toString(),
      })
    )) as unknown as GetAdminMakerCheckerResponseBody;
    const { activityRequests, ...rest } = response || {};
    const activeJsonData = activityRequests.map((_data) => {
      return {
        ..._data,
        currentData: _data.currentData
          ? JSON.parse(_data.currentData as string)
          : _data.currentData,
        payload: _data.payloadId || _data.payload ? JSON.parse(_data.payload as string) : null,
      };
    });
    return { activeJsonData, ...rest };
  };

  const handleEsignCountSetPopupClose = async () => {
    setDialogOpenForEsignCountSet({ message: '', open: false });
    try {
      setHistoryLoading(true);
      const apiResponse = await historyAPICall();
      const { activeJsonData } = apiResponse || {};
      setHistoryData(activeJsonData);
      setHistoryLoading(false);
    } catch (e) {
      setHistoryLoading(false);
      console.error((e as Error).message);
    }
  };

  const handleLoadingPopupClose = () => {
    setloadingPopup(false);
  };

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  const headers = [
    {
      header: `Name`,
      renderCell: (row: AuthorisedSignatories) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{
              pathname: 'authorised-signatory-details',
              state: { authorisedSignatoryId: row.id },
            }}>
            {row.user?.firstName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'Mobile Number',
      valueGetter: (row: AuthorisedSignatories) => row.user.phone || 'N/A',
    },
    {
      header: 'Email Id',
      valueGetter: (row: AuthorisedSignatories) => row.user.email || 'N/A',
    },
    {
      header: AccessHeader,
      renderCell: (row: AddAuthorisedSignatoryRequestBody): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.userId ? row.user?.isActive : row.isActive}
                onChange={() => {
                  try {
                    if (
                      (row.userId ? row.user?.isActive : row.isActive) &&
                      Number(RequireEsignValueRes) ===
                        authorisedSignatoryData?.filter((isActiveData) =>
                          isActiveData.userId ? isActiveData.user.isActive : isActiveData.isActive
                        )?.length
                    ) {
                      throw 'Active Authorized signatories can not be less than the Total No. of required Authorised Signatories';
                    } else {
                      setDialog({ authorisedSignatoryRowData: row });
                    }
                  } catch (e) {
                    typeof e === 'string' && dispatch(showError(e));
                  }
                }}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: AuthorisedSignatories): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{
    //                 pathname: 'edit-authorised-signatory',
    //                 state: { authorisedSignatoryId: row.id },
    //               }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{
    //               pathname: 'edit-authorised-signatory',
    //               state: { authorisedSignatoryId: row.id },
    //             }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let componentIsAlive = true;
    setHistoryLoading(true);
    (async function () {
      try {
        const apiResponse = await historyAPICall();
        const { activeJsonData } = apiResponse || {};
        if (!componentIsAlive) {
          return;
        }
        setHistoryData(activeJsonData);
        setHistoryLoading(false);
      } catch (e) {
        setHistoryLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (distributorId) {
          const RequiredForEsign = (await dispatch(
            getRequiredForEsign(Number(distributorId))
          )) as unknown as getRequiredForEsignType;
          SetRequireEsignValueRes(RequiredForEsign.authorisedSignatoryThreshold);
          const res = (await dispatch(
            getAuthorisedSignatoryList({ distributorId: Number(distributorId) })
          )) as unknown as AuthorisedSignatories[];
          setRequiredEsignVal(
            getArrayLength(
              res.filter((isActiveData) =>
                isActiveData.userId ? isActiveData.user.isActive : isActiveData.isActive
              ).length
            )
          );
          setAuthorisedSignatoryData(res);
        } else {
          history.replace('/user-management');
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4, pb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
            flexDirection: { xs: 'column', sm: 'row' },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 23 }, fontWeight: 500 }}>
            List of Authorised Signatories
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              mt: { xs: 1, sm: 0 },
            }}
            alignItems="center">
            <Typography sx={{ color: 'primary.main', fontSize: '16px', ml: 3 }}>
              No. of Authorized signatories to esign:
            </Typography>
            <Box
              sx={{
                width: '100px',
                ml: { md: 1 },
                '.MuiOutlinedInput-root': {
                  marginTop: '0',
                  backgroundColor: 'rgba(97, 209, 214, 0.07)',
                  '&.MuiInputBase-root': {
                    '& .MuiSelect-select': {
                      padding: '7px 38px',
                      border: 'none',
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
                '&.Mui-focused,&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 'none',
                  },
                },
                svg: {
                  color: '#337FC9',
                },
              }}>
              <Select
                labelId="user-items"
                onChange={(e) => {
                  setConfirmationDialog(Number(e.target.value));
                }}
                value={RequireEsignValueRes}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}>
                {(requiredEsignVal.length === 1 ? [0, ...requiredEsignVal] : requiredEsignVal).map(
                  (thresholdValue, ind) => (
                    <MenuItem
                      sx={{
                        borderBottom: '1px solid #ccc',
                        textAlign: 'center',
                        '&:last-child': { border: 'none' },
                        justifyContent: 'center',
                      }}
                      value={thresholdValue}
                      key={ind}>
                      {thresholdValue}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </Grid>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: 14,
                mr: 2.5,
                py: 0.5,
                px: 3,
              }}
              onClick={() =>
                history.push('add-authorised-signatory', {
                  distributorId: distributorId.toString(),
                })
              }>
              Add Authorised Signatory
            </Button>
          </Hidden>
          <Hidden smUp={true}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 13,
                //mr: 2.5,
                py: 0.5,
                // px: 3,
                // whiteSpace: 'nowrap',
                mt: 1.2,
              }}
              onClick={() =>
                history.push('add-authorised-signatory', {
                  distributorId: distributorId.toString(),
                })
              }>
              Add Authorised Signatory
            </Button>
          </Hidden>
        </Box>
        <Grid item xs={12} sm={12} lg={12}>
          {useMemo(
            () => (
              <DataTable
                tableData={authorisedSignatoryData}
                tableHeader={headers}
                isLoading={isLoading}
                currentPage={currentPage}
                noOfPages={1}
                paginationAction={handlePageChange}
                renderAdditionalRow={true}
                tableBodyCustomStyles={{
                  '.MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                }}
                tableHeaderCustomStyles={{
                  '&:last-child th': { border: 0 },
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
                boxStyles={{ minHeight: authorisedSignatoryData.length < 4 ? 0 : '400px' }}
              />
            ),
            [headers, isLoading, authorisedSignatoryData]
          )}
        </Grid>
        {historyData && historyData.length > 0 && (
          <Grid mt={5}>
            <LogsUI
              data={historyData}
              loading={historyLoading}
              textForHeading={'Logs for Authorized signatories to esign'}
            />
          </Grid>
        )}
      </Box>

      <ConfirmationDialogWithCommentsBox
        component={
          <AccessComponent
            message={`Are you sure you want to Change No. of Authorized signatories to esign?`}
          />
        }
        open={confirmationDialog !== null}
        setOpen={() => setConfirmationDialog(null)}
        onSave={async (values: { comment: string }) => {
          setConfirmationDialog(null);
          try {
            setloadingPopup(true);
            if (confirmationDialog) {
              const resSetForEsign = await dispatch(
                setRequiredForEsign({
                  authorisedSignatoryThreshold: Number(confirmationDialog),
                  distributorId: Number(distributorId),
                  comment: values.comment,
                }) as unknown as getRequiredForEsignType
              );
              handleLoadingPopupClose();
              if (resSetForEsign.message) {
                setDialogOpenForEsignCountSet({
                  message: resSetForEsign.message,
                  open: true,
                });
              }
            }
          } catch (e) {
            handleLoadingPopupClose();
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setConfirmationDialog(null)}
      />
      <ConfirmationDialogWithCommentsBox
        component={
          <AccessComponent
            message={`Are you sure you want to ${
              (
                openDialog?.authorisedSignatoryRowData.userId
                  ? openDialog?.authorisedSignatoryRowData.user?.isActive
                  : openDialog?.authorisedSignatoryRowData.isActive
              )
                ? 'disable'
                : 'enable'
            } the access to this user${
              (
                openDialog?.authorisedSignatoryRowData.userId
                  ? openDialog?.authorisedSignatoryRowData.user?.isActive
                  : openDialog?.authorisedSignatoryRowData.isActive
              )
                ? ', It might effect any Existing POA application(s)'
                : ''
            }?`}
          />
        }
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async (values: { comment: string }) => {
          setDialog(null);
          try {
            setloadingPopup(true);
            if (openDialog) {
              const updatedResponse = (await dispatch(
                updateAuthorisedSignatory(openDialog.authorisedSignatoryRowData.id as number, {
                  ...openDialog.authorisedSignatoryRowData,
                  isActive: openDialog?.authorisedSignatoryRowData.userId
                    ? !openDialog?.authorisedSignatoryRowData.user?.isActive
                    : !openDialog?.authorisedSignatoryRowData.isActive,
                  comment: values.comment,
                })
              )) as unknown as AuthorisedSignatories;
              handleLoadingPopupClose();
              if (updatedResponse.message) {
                setRouteDialogOpen({
                  message: updatedResponse.message,
                  open: true,
                });
              }
            }
          } catch (e) {
            handleLoadingPopupClose();
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
      <NavigationThrowPopup
        handleClose={
          dialogOpenForEsignCountSet.open ? handleEsignCountSetPopupClose : handleRoutePopupClose
        }
        dialogOpen={dialogOpenForEsignCountSet.open ? dialogOpenForEsignCountSet : routeDialogOpen}
        onSave={() => {
          dialogOpenForEsignCountSet.open
            ? handleEsignCountSetPopupClose()
            : handleRoutePopupClose();
        }}
      />
      <LoadingDialog loadingPopup={loadingPopup} onLoadingPopupClose={handleLoadingPopupClose} />
    </>
  );
}
