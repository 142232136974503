import { jsx } from '@emotion/react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ApplicationProps, Declaration } from '../../redux-store/types/api-types';
import { checkIfApplicationIsNonIndividual } from '../../utils/utilityFunctions';
import { SubHeading } from '../investors/components';
import { Gridstyles } from '../ApplicationDetails';
import React from 'react';

export function kycDocumentsDeclaration(): JSX.Element {
  return (
    <Typography sx={{ textAlign: 'justify' }}>
      I/We do hereby confirm that I/We shall provide KYC documents on a periodic basis as prescribed
      by the Investment Manager/ SEBI / under Applicable Law including the Prevention of Money
      Laundering Act, 2005, and the rules notified thereunder for ongoing due diligence.
    </Typography>
  );
}
export function ppmAcknowledge(ppmLink: string): JSX.Element {
  return (
    <Typography sx={{ textAlign: 'justify' }}>
      I/We have received a copy of the{' '}
      <Button
        sx={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          color: 'inherit',
          textUnderlinePosition: 'under',
          textDecoration: 'underline',
          p: 0,
          ':hover': {
            bgcolor: '#F4FCFC',
            color: 'primary.main',
            textDecoration: 'underline',
          },
        }}
        onClick={() => window.open(ppmLink)}>
        private placement memorandum (&quot;PPM&quot;)
      </Button>{' '}
      and a copy of the contribution agreement (“Contribution Agreement”). I/We hereby further agree
      and confirm that the contents of PPM & Contribution Agreement were explained to me and have
      also read and understood the contents of the PPM and the Contribution Agreement.
    </Typography>
  );
}
export function kycDeclaration(): JSX.Element {
  return (
    <Typography sx={{ textAlign: 'justify' }}>
      I/We hereby declare that the details furnished above are true and correct to the best of
      my/our knowledge and belief. In case any of the above information is found to be false or
      untrue or misleading or misrepresenting, I/We am/are aware that I/We may be held liable for
      it. I/We hereby authorize and give the Investment Manager an explicit consent to download
      records and fetch my/our KYC details from CVL KRA / CKYCR as my/our KYC as per CVL KRA / CKYCR
      still holds goods, proofs for which were submitted earlier.
    </Typography>
  );
}
const isDeclarationPresent = (declaration: Declaration): boolean => {
  return declaration?.kycDeclaration || declaration?.kycDeclaration || declaration?.kycDeclaration
    ? true
    : false;
};

export function DeclarationWithHeading({
  heading,
  declaration,
}: {
  heading: string;
  declaration: JSX.Element;
}): JSX.Element {
  return (
    <>
      <Typography
        sx={{
          letterSpacing: 0.5 + 'px',
          padding: { xs: '10px 10px', sm: '10px 30px' },
          fontWeight: 700,
        }}>
        {heading}
      </Typography>
      <React.Fragment>
        <Box sx={{ width: '100%' }}>
          <Gridstyles>
            <Grid
              item
              container
              pb={5}
              rowSpacing={6}
              columnSpacing={13}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                <Typography>{declaration}</Typography>
              </Grid>
            </Grid>
          </Gridstyles>
        </Box>
      </React.Fragment>
    </>
  );
}

function PerPersonDeclarations({
  tcLink,
  declaration,
  heading,
}: {
  tcLink: string;
  declaration?: Declaration;
  heading: string;
}): JSX.Element {
  return (
    <>
      {declaration && isDeclarationPresent(declaration) && (
        <SubHeading
          sx={{
            letterSpacing: 0.5 + 'px',
            padding: { xs: '10px 10px', sm: '10px 30px' },
            fontWeight: 700,
          }}>
          {heading}
        </SubHeading>
      )}
      {declaration?.kycDeclaration && (
        <DeclarationWithHeading declaration={kycDeclaration()} heading={`KYC Declaration`} />
      )}
      {declaration?.ppmAcknowledge && (
        <DeclarationWithHeading
          declaration={ppmAcknowledge(tcLink)}
          heading={`PPM Acknowledgement`}
        />
      )}
      {declaration?.kycDocumentsDeclaration && (
        <DeclarationWithHeading
          declaration={kycDocumentsDeclaration()}
          heading={`KYC Document Declaration`}
        />
      )}
    </>
  );
}

export default function Declarations({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const declarationPersonType = application?.hasPOA
    ? 'POA Authorized Signatories'
    : checkIfApplicationIsNonIndividual(application)
    ? 'Group Authorized Signatories'
    : 'Investor(s)';

  return Number(
    application?.distributor?.authorisedsignatories?.filter(
      (signatory) => signatory?.declaration && isDeclarationPresent(signatory?.declaration)
    ).length
  ) > 0 ||
    Number(
      application?.applicants?.filter(
        (applicant) => applicant?.declaration && isDeclarationPresent(applicant?.declaration)
      ).length
    ) > 0 ||
    Number(
      application?.groups
        ?.map(
          (group) =>
            Number(
              group?.groupsignatories?.filter(
                (signatory) =>
                  signatory?.declaration && isDeclarationPresent(signatory?.declaration)
              ).length
            ) > 0
        )
        ?.includes(true)
    ) ? (
    <Box
      sx={{
        bgcolor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
      }}
      mt={5}>
      <Box sx={{ p: 2 }}>
        <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
            {`${declarationPersonType} Declaration(s)`}
          </Typography>
          {application?.hasPOA
            ? application?.distributor?.authorisedsignatories?.map((signatory, index) => (
                <>
                  <PerPersonDeclarations
                    heading={`Declaration For Authorized Signatory ${index + 1}`}
                    tcLink={application.plan.tcLink}
                    declaration={signatory?.declaration}
                  />
                </>
              ))
            : checkIfApplicationIsNonIndividual(application)
            ? application?.groups?.map((group) => (
                <>
                  <Box>
                    {group?.groupsignatories?.map((signatory, index) => (
                      <>
                        <PerPersonDeclarations
                          heading={`Declaration For Authorized Signatory ${index + 1}`}
                          tcLink={application.plan.tcLink}
                          declaration={signatory?.declaration}
                        />
                      </>
                    ))}
                  </Box>
                </>
              ))
            : application.applicants.map((applicant) => {
                const title = ['First/Solo', 'Second', 'Third'][
                  Number(applicant?.applicant_type) - 1
                ];
                return (
                  <>
                    <PerPersonDeclarations
                      heading={`Declaration of ${title} Applicant`}
                      tcLink={application.plan.tcLink}
                      declaration={applicant?.declaration}
                    />
                  </>
                );
              })}
        </Grid>
      </Box>
    </Box>
  ) : (
    <></>
  );
}
