import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { AmcAdmin } from '../../redux-store/types/api-types';
import {
  AmcAdminAccess,
  getAmcAdminAccess,
  getAMCAdminById,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const AmcAdminFields = ({
  amcAdminDetails,
  oldData,
}: {
  amcAdminDetails: AmcAdmin;
  oldData?: null | AmcAdmin;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Name'}
        description={
          amcAdminDetails.userId ? amcAdminDetails.user?.firstName : amcAdminDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                amcAdminDetails.userId ? amcAdminDetails.user?.firstName : amcAdminDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            amcAdminDetails.userId ? amcAdminDetails.user?.firstName : amcAdminDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name',
            amcAdminDetails.userId ? amcAdminDetails.user?.firstName : amcAdminDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={amcAdminDetails.userId ? amcAdminDetails.user?.email : amcAdminDetails?.email}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                amcAdminDetails.userId ? amcAdminDetails.user?.email : amcAdminDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            amcAdminDetails.userId ? amcAdminDetails.user?.email : amcAdminDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            amcAdminDetails.userId ? amcAdminDetails.user?.email : amcAdminDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={amcAdminDetails.userId ? amcAdminDetails.user?.phone : amcAdminDetails?.phone}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                amcAdminDetails.userId ? amcAdminDetails.user?.phone : amcAdminDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            amcAdminDetails.userId ? amcAdminDetails.user?.phone : amcAdminDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            amcAdminDetails.userId ? amcAdminDetails.user?.phone : amcAdminDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          amcAdminDetails.userId ? amcAdminDetails.user?.companyName : amcAdminDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                amcAdminDetails.userId
                  ? amcAdminDetails.user?.companyName
                  : amcAdminDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            amcAdminDetails.userId
              ? amcAdminDetails.user?.companyName
              : amcAdminDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            amcAdminDetails.userId
              ? amcAdminDetails.user?.companyName
              : amcAdminDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function AmcAdminDetails({
  location,
}: {
  location: Location<{ amcAdminId: number }>;
}): JSX.Element {
  const { amcAdminId: id } = location.state || { amcAdminId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [amcAdminDetails, setamcAdminDetails] = useState<AmcAdmin>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { amcAdminAccess } = (await dispatch(
          getAmcAdminAccess()
        )) as unknown as AmcAdminAccess;
        if (id) {
          const getAmcAdminDetails = (await dispatch(getAMCAdminById(id))) as unknown as AmcAdmin;
          setamcAdminDetails(getAmcAdminDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(amcAdminAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('amc-admin')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('amc-admin')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              AMC Admin
            </Typography>
          </Box>
          {accessForEditAdd && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() => history.push('edit-amc-admin', { amcAdminId: amcAdminDetails?.id })}
              />
            </IconButton>
          )}
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          amcAdminDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ paddingLeft: { xs: 0, sm: '10%' } }}>
                    <AmcAdminFields amcAdminDetails={amcAdminDetails} />
                  </Grid>
                </React.Fragment>
              </Grid>
              {amcAdminDetails.history && amcAdminDetails.history.length > 0 && (
                <LogsUI data={amcAdminDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
