import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { RmsType, SubDistributorListType } from '../../redux-store/types/api-types';
import { getDistributorRMbyId, getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const DistributorRmFields = ({
  distributorRmDetails,
  oldData,
}: {
  distributorRmDetails: SubDistributorListType;
  oldData?: null | SubDistributorListType;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={"Distributor's RM name"}
        description={
          distributorRmDetails.userId
            ? distributorRmDetails.user?.firstName
            : distributorRmDetails?.name
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                distributorRmDetails.userId
                  ? distributorRmDetails.user?.firstName
                  : distributorRmDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            distributorRmDetails.userId
              ? distributorRmDetails.user?.firstName
              : distributorRmDetails?.name
          ) &&
          handleRoutePopupOpen(
            "Distributor's RM name",
            distributorRmDetails.userId
              ? distributorRmDetails.user?.firstName
              : distributorRmDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Email Id'}
        description={
          distributorRmDetails.userId
            ? distributorRmDetails.user?.email
            : distributorRmDetails?.email
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                distributorRmDetails.userId
                  ? distributorRmDetails.user?.email
                  : distributorRmDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            distributorRmDetails.userId
              ? distributorRmDetails.user?.email
              : distributorRmDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            distributorRmDetails.userId
              ? distributorRmDetails.user?.email
              : distributorRmDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Contact Number'}
        description={
          distributorRmDetails.userId
            ? distributorRmDetails.user?.phone
            : distributorRmDetails?.phone
        }
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                distributorRmDetails.userId
                  ? distributorRmDetails.user?.phone
                  : distributorRmDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            distributorRmDetails.userId
              ? distributorRmDetails.user?.phone
              : distributorRmDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Contact Number',
            distributorRmDetails.userId
              ? distributorRmDetails.user?.phone
              : distributorRmDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pan'}
        description={distributorRmDetails.panNumber}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.panNumber, distributorRmDetails.panNumber)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, distributorRmDetails.panNumber) &&
          handleRoutePopupOpen('Pan', distributorRmDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={"Distributor's RM Code"}
        description={distributorRmDetails.distributors_RM_Code}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.distributors_RM_Code,
                distributorRmDetails.distributors_RM_Code
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.distributors_RM_Code,
            distributorRmDetails.distributors_RM_Code
          ) &&
          handleRoutePopupOpen(
            "Distributor's RM Code",
            distributorRmDetails.distributors_RM_Code,
            oldData.distributors_RM_Code
          )
        }
      />
      <KeyValue
        title={"AMC's RM Name"}
        description={distributorRmDetails?.rm?.name}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(Number(oldData?.rmId), Number(distributorRmDetails?.rmId))
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(Number(oldData?.rmId), Number(distributorRmDetails?.rmId)) &&
          handleRoutePopupOpen("AMC's RM Name", distributorRmDetails?.rm?.name, oldData?.rm?.name)
        }
      />
      <KeyValue
        title={'Pincode'}
        description={distributorRmDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, distributorRmDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, distributorRmDetails.pincode) &&
          handleRoutePopupOpen('Pincode', distributorRmDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={distributorRmDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, distributorRmDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, distributorRmDetails.buildingNo) &&
          handleRoutePopupOpen(
            'Building Number',
            distributorRmDetails.buildingNo,
            oldData.buildingNo
          )
        }
      />
      <KeyValue
        title={'Street Name'}
        description={distributorRmDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, distributorRmDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, distributorRmDetails.streetName) &&
          handleRoutePopupOpen('Street Name', distributorRmDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={distributorRmDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, distributorRmDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, distributorRmDetails.state) &&
          handleRoutePopupOpen('State', distributorRmDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={distributorRmDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, distributorRmDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, distributorRmDetails.city) &&
          handleRoutePopupOpen('City', distributorRmDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={distributorRmDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, distributorRmDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, distributorRmDetails.country) &&
          handleRoutePopupOpen('Country', distributorRmDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          distributorRmDetails.userId
            ? distributorRmDetails.user?.companyName
            : distributorRmDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                distributorRmDetails.userId
                  ? distributorRmDetails.user?.companyName
                  : distributorRmDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            distributorRmDetails.userId
              ? distributorRmDetails.user?.companyName
              : distributorRmDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            distributorRmDetails.userId
              ? distributorRmDetails.user?.companyName
              : distributorRmDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function DistributorRmDetails({
  location,
}: {
  location: Location<{ subDistributorId: number }>;
}): JSX.Element {
  const { subDistributorId: id } = location.state || { subDistributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorRmDetails, setDistributorRmDetails] = useState<SubDistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(id)
          )) as unknown as SubDistributorListType;
          setDistributorRmDetails(getDistributorRmDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('distributors-rm', {
                  distributorId: distributorRmDetails?.distributorId,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('distributors-rm', {
                    distributorId: distributorRmDetails?.distributorId,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              {`Distributor's RM`}
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-distributors-rm', { subDistributorId: distributorRmDetails?.id })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorRmDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <DistributorRmFields distributorRmDetails={distributorRmDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {distributorRmDetails.history && distributorRmDetails.history.length > 0 && (
                <LogsUI data={distributorRmDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
