import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { SubHeading } from '../investors/components';
import { currencyConversion } from '../../utils/utilityFunctions';
import { grossAnnualMasters, investorTypeMasters } from '../../utils/constant';

export default function ContributorDetails({
  application = {},
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  const { applicants = [], dpName = '', dpId = '', dpClientId = '' } = application;
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
            </Grid>
            {applicants.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name Of Entity'} description={applicant.name} />
                      <KeyValue
                        title={'Date of Registration/Incorporation'}
                        description={formatShortDate(applicant.dateOfBirth)}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'Place of Registration/Incorporation'}
                        description={applicant.cityOfIncorporation}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'CKYC No./CKYC Acknowledge No.'}
                        description={applicant.ckycNo}
                      />
                      {/* <KeyValue title={'KIN No'} description={applicant.kinNo} /> */}
                      {applicant.investorType && (
                        <KeyValue
                          title={'Investor Type'}
                          description={investorTypeMasters[applicant.investorType]}
                        />
                      )}
                      <KeyValue
                        title={'Corporate Identification No'}
                        description={applicant.corporateIdentificationNo}
                      />
                      <KeyValue title={'PAN'} description={applicant.panNo} />
                    </Grid>
                  </Gridstyles>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Other Details'}
                  </SubHeading>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={15}
                    pl={{ xs: 0, sm: '10%' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Gross Annual Income'}
                      description={grossAnnualMasters[applicant.grossAnnualIncome || '']}
                    />
                    <KeyValue
                      title={'Net Worth'}
                      description={applicant.netWorth}
                      amount={currencyConversion(applicant.netWorth)}
                    />
                    {/* <KeyValue
                        title={'PEP Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      /> */}
                  </Grid>
                </React.Fragment>
              );
            })}
            <SubHeading
              sx={{
                color: 'common.black',
                letterSpacing: 0.5 + 'px',
                padding: { xs: '10px 10px', sm: '10px 30px' },
              }}>
              Demat Account details
            </SubHeading>
            <Grid
              item
              container
              pb={5}
              rowSpacing={6}
              columnSpacing={15}
              pl={{ xs: 0, sm: '10%' }}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <KeyValue title={'DP Name'} description={dpName} />
              <KeyValue title={'DP Id'} description={dpId} />
              <KeyValue title={'Client Id'} description={dpClientId} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
