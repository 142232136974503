/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography, Hidden, IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, RejectApproveButtons } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  AmcAdmin,
  AuthorisedSignatories,
  DistributorListType,
  RmsType,
  SubDistributorListType,
  Trustee,
} from '../../redux-store/types/api-types';
import {
  ApproveComponent,
  ConfirmationDialogWithCommentsBox,
  RejectComponent,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import {
  AdminMakerCheckerProps,
  getAdminCheckerApprove,
  getAdminCheckerReject,
  getAdminMakerCheckerUserDetails,
} from '../../redux-store/actions/userFundsVerification';
import { filterAdminMakerCheckerUserRoles } from './filters';
import { RmFields } from '../userManagement/viewRM';
import { UserFundsMaster, yesAndNoTrueAndFalseMasters } from '../../utils/constant';
import { applicationComparison } from '../../utils/utilityFunctions';
import { DistributorFields } from '../userManagement/viewDistributor';
import { DistributorRmFields } from '../userManagement/viewDistributorRm';
import { AuthorisedSignatoryFields } from '../userManagement/viewAuthorisedSignatory';
import { PoaApproverFields } from '../userManagement/viewPoaApprover';
import { TrusteeFields } from '../userManagement/viewTrustee';
import { IMFields } from '../userManagement/viewIm';
import { AmcAdminFields } from '../userManagement/viewAmcAdmin';
import { AifApproverFields } from '../userManagement/viewAIFApprover';
import { formatShortDateAndTime } from '../../utils/date';
import { Notes } from '../investors/components';
import {
  AifApproverAccess,
  AmcAdminAccess,
  IMAccess,
  getAmcAdminAccess,
  getAmcApproverAccess,
  getIMAccess,
  gettrusteeAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';

export const consentInfo = "You didn't have the Access for Approve or Reject";

const getUserRoleForCheck = (data: AdminMakerCheckerProps) => {
  return filterAdminMakerCheckerUserRoles.find(
    (_role) => _role.key === data?.endpoint.split('-')[1]
  );
};

export default function UserDetails(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<AdminMakerCheckerProps>();
  const { id } = useParams<{ id: string }>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  const { path } = useRouteMatch();
  const [access, setAccess] = useState(false);

  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getUserDetails = (await dispatch(
            getAdminMakerCheckerUserDetails(id)
          )) as unknown as AdminMakerCheckerProps;
          const roleAccessCheck = getUserRoleForCheck(getUserDetails);
          switch (roleAccessCheck?.key) {
            case 'trustee': {
              const { trusteeAccess } = (await dispatch(
                gettrusteeAccess()
              )) as unknown as trusteeAccess;
              setAccess(trusteeAccess);
              break;
            }
            case 'fundmanager': {
              const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
              setAccess(fundManagerAccess);
              break;
            }
            case 'amcapprover': {
              const { amcApproverAccess } = (await dispatch(
                getAmcApproverAccess()
              )) as unknown as AifApproverAccess;
              setAccess(amcApproverAccess);
              break;
            }
            case 'amcadmin': {
              const { amcAdminAccess } = (await dispatch(
                getAmcAdminAccess()
              )) as unknown as AmcAdminAccess;
              setAccess(amcAdminAccess);
              break;
            }
            case 'rm':
            case 'distributor':
            case 'subdistributor':
            case 'authorisedsignatory':
            case 'poaapprover': {
              setAccess(true);
              break;
            }
          }
          setUserDetails(getUserDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handleRejectPopupOpen = () => setOpenRejectPopup(true);
  const handleApprovePopupDialogOpen = () => setOpenApprovePopup(true);

  const handleRejectPopupClose = () => {
    setOpenRejectPopup(false), setApproveLoading(false);
  };
  const handleApprovePopupClose = () => {
    setOpenApprovePopup(false), setRejectLoading(false);
  };

  const getRole = userDetails && getUserRoleForCheck(userDetails);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('/users-funds-verification')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User(s)
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('/users-funds-verification')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
                textTransform: 'capitalize',
              }}>
              {getRole?.key === 'distributor'
                ? (userDetails?.payload as any)?.type
                  ? (userDetails?.payload as any)?.type + ' ' + getRole?.value
                  : getRole?.value
                : getRole?.value}{' '}
              Details
            </Typography>
          </Box>
          <Typography
            className={UserFundsMaster[userDetails?.status || '']}
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: 'text.secondary',
              '&.Approved .MuiTypography-root': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected .MuiTypography-root': {
                color: '#BD2932',
                fontWeight: 500,
              },
            }}>
            Status:{' '}
            <Typography
              component="span"
              sx={{
                color: '#B78813',
                fontSize: '16px',
                letterSpacing: '0.4px',
                ml: 0.5,
              }}>
              {UserFundsMaster[userDetails?.status || '']}
            </Typography>
          </Typography>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          userDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    {(userDetails.makerComment || userDetails.checkerComment) && (
                      <Grid
                        item
                        sx={{
                          border: '1px solid #00D9DA',
                          p: 1.25,
                          borderRadius: 1.25,
                          my: 2,
                          mr: { lg: 20, xs: 4 },
                        }}>
                        {userDetails.makerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                              }}>
                              Maker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {userDetails.makerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box
                          display={'flex'}
                          sx={{ flexDirection: { xs: 'column', sm: 'row' }, mb: 1 }}>
                          {userDetails.makerAdminName && (
                            <KeyValue
                              title={'Requested By'}
                              description={userDetails.makerAdminName}
                            />
                          )}
                          {userDetails.createdAt && (
                            <KeyValue
                              title={'Requested At'}
                              description={formatShortDateAndTime(userDetails.createdAt)}
                            />
                          )}
                        </Box>
                        {userDetails.checkerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                                mt: 0.3,
                              }}>
                              Checker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {userDetails.checkerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box display={'flex'} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                          {userDetails.checkerAdminName && (
                            <KeyValue
                              title={
                                UserFundsMaster[userDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected By'
                                  : 'Approved By'
                              }
                              description={userDetails.checkerAdminName}
                            />
                          )}
                          {(userDetails.approvedAt || userDetails.rejectedAt) && (
                            <KeyValue
                              title={
                                UserFundsMaster[userDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected At'
                                  : 'Approved At'
                              }
                              description={formatShortDateAndTime(
                                userDetails.approvedAt || userDetails.rejectedAt
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {getRole?.key === 'rm' && (
                        <RmFields
                          rmDetails={userDetails.payload as RmsType}
                          oldData={userDetails.currentData as RmsType | null}
                        />
                      )}
                      {getRole?.key === 'distributor' && (
                        <DistributorFields
                          distributorDetails={userDetails.payload as DistributorListType}
                          oldData={userDetails.currentData as DistributorListType | null}
                        />
                      )}
                      {getRole?.key === 'subdistributor' && (
                        <DistributorRmFields
                          distributorRmDetails={userDetails.payload as SubDistributorListType}
                          oldData={userDetails.currentData as SubDistributorListType | null}
                        />
                      )}
                      {getRole?.key === 'authorisedsignatory' && (
                        <AuthorisedSignatoryFields
                          authorisedSignatoryDetails={userDetails.payload as AuthorisedSignatories}
                          oldData={userDetails.currentData as AuthorisedSignatories | null}
                        />
                      )}
                      {getRole?.key === 'poaapprover' && (
                        <PoaApproverFields
                          poaApproverDetails={userDetails.payload as AuthorisedSignatories}
                          oldData={userDetails.currentData as AuthorisedSignatories | null}
                        />
                      )}
                      {getRole?.key === 'trustee' && (
                        <TrusteeFields
                          trusteeDetails={userDetails.payload as Trustee}
                          oldData={userDetails.currentData as Trustee | null}
                        />
                      )}
                      {getRole?.key === 'fundmanager' && (
                        <IMFields
                          fundManagerDetails={userDetails.payload as Trustee}
                          oldData={userDetails.currentData as Trustee | null}
                        />
                      )}
                      {getRole?.key === 'amcapprover' && (
                        <AifApproverFields
                          aifApproverDetails={userDetails.payload as Trustee}
                          oldData={userDetails.currentData as Trustee | null}
                        />
                      )}
                      {getRole?.key === 'amcadmin' && (
                        <AmcAdminFields
                          amcAdminDetails={userDetails.payload as AmcAdmin}
                          oldData={userDetails.currentData as AmcAdmin | null}
                        />
                      )}
                      {userDetails.currentData && (
                        <KeyValue
                          title={'Access Enabled'}
                          description={
                            yesAndNoTrueAndFalseMasters[
                              (userDetails.payload as any)?.isActive.toString()
                            ]
                          }
                          dispalyModifiedIcon={
                            !applicationComparison(
                              (userDetails.currentData as any)?.userId
                                ? (userDetails.currentData as any)?.user?.isActive.toString()
                                : (userDetails.currentData as any)?.isActive.toString(),
                              (userDetails.payload as any)?.isActive.toString()
                            )
                          }
                          onInfoClick={() =>
                            !applicationComparison(
                              (userDetails.currentData as any)?.userId
                                ? (userDetails.currentData as any)?.user?.isActive.toString()
                                : (userDetails.currentData as any)?.isActive.toString(),
                              (userDetails.payload as any)?.isActive.toString()
                            ) &&
                            handleRoutePopupOpen(
                              'Access Enabled',
                              yesAndNoTrueAndFalseMasters[
                                (userDetails.payload as any)?.isActive.toString()
                              ],
                              yesAndNoTrueAndFalseMasters[
                                (userDetails.currentData as any)?.userId
                                  ? (userDetails.currentData as any)?.user?.isActive.toString()
                                  : (userDetails.currentData as any)?.isActive.toString()
                              ]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )
        )}
      </Box>
      {UserFundsMaster[userDetails?.status || ''] === UserFundsMaster.pending &&
        path === '/users-funds-verification/user-details/:id' &&
        (access ? (
          <RejectApproveButtons
            handleOpen={handleRejectPopupOpen}
            handleDialogOpen={handleApprovePopupDialogOpen}
            approve={approveLoading}
            reject={rejectLoading}
            displaySendBack={false}
          />
        ) : (
          <Box sx={{ mt: 2 }}>
            <Notes displayContent={consentInfo} />
          </Box>
        ))}
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
      <ConfirmationDialogWithCommentsBox
        component={<RejectComponent />}
        open={openRejectPopup}
        setOpen={handleRejectPopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleRejectPopupClose();
            setRejectLoading(true);
            await dispatch(getAdminCheckerReject(id, { comment: values.comment }));
            history.push('/users-funds-verification');
          } catch (e) {
            setRejectLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleRejectPopupClose}
      />
      <ConfirmationDialogWithCommentsBox
        component={<ApproveComponent />}
        open={openApprovePopup}
        setOpen={handleApprovePopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleApprovePopupClose();
            setApproveLoading(true);
            await dispatch(getAdminCheckerApprove(id, { comment: values.comment }));
            history.push('/users-funds-verification');
          } catch (e) {
            setApproveLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleApprovePopupClose}
      />
    </>
  );
}
