import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';
import LoginOtp from '../auth/loginOtp';
import LoginRequireRoute from '../../routes/LoginRequireRoute';
import AuthorizedSignatoriesInvestorApplication from './one-signatories-application';
import InvestmentDetails from '../Investments/investment-details';

const AuthorizedSignatoriesRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/fund-details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/fund-details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/otp`} component={LoginOtp} />
      <Route
        path={`${path}/:referenceId/application-details`}
        component={AuthorizedSignatoriesInvestorApplication}
      />
      <LoginRequireRoute path="*" />
    </Switch>
  );
};

export default AuthorizedSignatoriesRoutes;
