import { Button, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useField } from 'formik';
import React from 'react';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { KraDocumentType } from '../../redux-store/types/api-types';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const FileUpload = ({
  name,
  documentData,
  disabled,
}: {
  name: string;
  documentData?: any;
  disabled: boolean;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px dashed #AAB2BA',
          borderRadius: '6px',
          mt: '30px',
          minHeight: '65px',
        }}>
        {documentData && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              src={documentData?.thumbnailUrl}
              width="50"
              height="50"
              sx={{ display: 'flex', width: 'unset', mx: 1 }}
            />
          </Box>
        )}
        <Typography
          component="span"
          sx={{
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
            ml: documentData ? 0 : 2,
          }}>
          {documentData?.name || 'KRA fetch in progress'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 3 },
          }}>
          <Tooltip title={documentData ? 'Download KRA Documents' : 'KRA fetch In progress'}>
            <IconButton
              sx={{
                mr: 5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: documentData ? 'all' : 'none',
              }}
              onClick={() => {
                if (documentData) {
                  window.open(documentData?.url, '_blank');
                }
              }}>
              <CardMedia
                component="img"
                src={
                  documentData ? '/images/download-icon.png' : '/images/download-icon-disabled.png'
                }
                alt="Download"
                sx={{ width: '25px' }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

export const KraDocument = ({
  KraDocument,
  num,
}: {
  KraDocument?: KraDocumentType[];
  num?: number;
}): JSX.Element => {
  return (
    <React.Fragment>
      <>
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          {num && (
            <Typography
              component="span"
              sx={{ color: '#2057A6', fontSize: '20px', paddingRight: '15px' }}>
              {num}
            </Typography>
          )}
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              mt: 6,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            {`KRA Documents`}
          </Typography>
        </Grid>
        {KraDocument && KraDocument.length > 0 ? (
          <>
            {KraDocument?.map((data, idx) => {
              return (
                <>
                  <Grid item xs={12} sm={6} key={idx}>
                    <React.Fragment>
                      <FileUpload
                        name={`${data.name}` || 'N/a'}
                        documentData={data}
                        disabled={false}
                      />
                    </React.Fragment>
                  </Grid>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <React.Fragment>
                <FileUpload name={'KraDocument'} disabled={false} />
              </React.Fragment>
            </Grid>
          </>
        )}
      </>
    </React.Fragment>
  );
};
