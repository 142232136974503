import { Box, Button, Typography, Hidden, IconButton, Tooltip, Stack, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import {
  getFundManagersList,
  getIMAccess,
  IMAccess,
  UpdateFundManager,
} from '../../redux-store/actions/userManagement';
import { AddFundManagerRequestBody, FundManager } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog, LoadingDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import {
  AccessComponent,
  ConfirmationDialogWithCommentsBox,
  NavigationThrowPopup,
} from '../UsersFundsVerification/userFundscommonComponents';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: FundManager) => {
      return (
        <Typography
          variant="h5"
          sx={{
            color: '#61D1D6',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            ':hover': {
              color: 'primary.main',
            },
          }}
          component={RouterLink}
          to={{ pathname: 'Invest-manager-details', state: { fundManagerId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: FundManager) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: FundManager) => row.user.email || 'N/A',
  },
];

function FundManagers(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [fundManagersList, setFundManagersList] = useState<FundManager[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    fundManagerRowData: AddFundManagerRequestBody;
  } | null>(null);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);
  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const [loadingPopup, setloadingPopup] = useState(false);

  const handleLoadingPopupClose = () => {
    setloadingPopup(false);
  };

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getFundManagersList())) as unknown as FundManager[];
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        if (!isComponentAlive) return;
        setFundManagersList(res);
        setAccessForEditAdd(fundManagerAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddFundManagerRequestBody): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.userId ? row.user?.isActive : row.isActive}
                onChange={() => setDialog({ fundManagerRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',

    //   renderCell: (row: FundManager): JSX.Element => {
    //     return (
    //       <>
    //         {accessForEditAdd && row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{ pathname: 'edit-Invest-manager', state: { fundManagerId: row.id } }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-Invest-manager', state: { fundManagerId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  const viewPlansCell = [
    {
      header: 'View Plans',
      renderCell: (row: FundManager): JSX.Element => {
        return (
          <Typography
            variant="h5"
            component={RouterLink}
            to={{ pathname: 'IM-plans', state: { IMId: row.id } }}
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}>
            View
          </Typography>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>
            List of Investment Managers
          </Typography>
          {accessForEditAdd && (
            <Hidden only="xs">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  mr: 2.5,
                  py: 0.5,
                  px: 3,
                }}
                onClick={() => history.push('add-Invest-manager')}>
                Add Investment Manager
              </Button>
            </Hidden>
          )}
          {accessForEditAdd && (
            <Hidden smUp={true}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  // mr: 2.5,
                  py: 0.5,
                  //px: 3,
                  //whiteSpace: 'nowrap',
                }}
                onClick={() => history.push('add-Invest-manager')}>
                Add Investment Manager
              </Button>
            </Hidden>
          )}
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={fundManagersList}
              tableHeader={[...columns, ...AccessViewOrEditCell, ...viewPlansCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [fundManagersList, currentPage, isLoading]
        )}
      </Box>
      <ConfirmationDialogWithCommentsBox
        component={
          <AccessComponent
            message={`Are you sure you want to ${
              (
                openDialog?.fundManagerRowData.userId
                  ? openDialog?.fundManagerRowData.user?.isActive
                  : openDialog?.fundManagerRowData.isActive
              )
                ? 'disable'
                : 'enable'
            } the access to this user ?`}
          />
        }
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async (values: { comment: string }) => {
          setDialog(null);
          try {
            setloadingPopup(true);
            if (openDialog) {
              const updatedResponse = (await dispatch(
                UpdateFundManager(openDialog.fundManagerRowData.id as number, {
                  ...openDialog.fundManagerRowData,
                  isActive: openDialog?.fundManagerRowData.userId
                    ? !openDialog?.fundManagerRowData.user?.isActive
                    : !openDialog?.fundManagerRowData.isActive,
                  comment: values.comment,
                })
              )) as unknown as FundManager;
              handleLoadingPopupClose();
              if (updatedResponse.message) {
                setRouteDialogOpen({
                  message: updatedResponse.message,
                  open: true,
                });
              }
            }
          } catch (e) {
            handleLoadingPopupClose();
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
      <NavigationThrowPopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
      <LoadingDialog loadingPopup={loadingPopup} onLoadingPopupClose={handleLoadingPopupClose} />
    </>
  );
}

export default FundManagers;
