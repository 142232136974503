import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  SelectChangeEvent,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik, useFormikContext } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  AddAuthorisedSignatoryBody,
  AddAuthorisedSignatoryRequestBody,
  AuthorisedSignatories,
  Document,
  DocumentType,
  individuals_Poa_nonIndividuals_Documents,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addAuthorisedSignatory,
  updateAuthorisedSignatory,
  getAuthorisedSignatoryById,
} from '../../redux-store/actions/userManagement';
import { AuthorisedSignatoryAndPOASchema } from '../../utils/schema';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import MFSelectField from '../../lib/formik/SelectField';
import { COMPANIES_MASTER_FORMETED_OBJECT, initialComment } from '../../utils/constant';
import { NavigationThrowPopup } from '../UsersFundsVerification/userFundscommonComponents';
import { showError } from '../../redux-store/actions/auth';
import { applicationComparison, getDocNumberForMultipleDocs } from '../../utils/utilityFunctions';
import { noUpdationMsg } from './inviteRm';
import { nonDigitRegex } from '../../utils/regex';
import { SubHeading } from '../investors/components';
import { getDocuments } from '../../redux-store/actions/application';
import { FileUpload, newDocumentProps } from '../investors/documentDetails';

export const document_object = {
  documentId: '',
  isActive: true,
};

export const CommonFormForCompanyDistributors = (props: any): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="name" label="Name *" placeholder="Enter Name" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="phone"
            label="Mobile Number *"
            placeholder="Enter Mobile Number"
            // startAdornment={
            //   <CountryCodesDropDown
            //     name={`countryNameAndCode`}
            //     value={props.values.countryNameAndCode}
            //   />
            // }
            regexForFilterValue={nonDigitRegex}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="panNumber"
            label="Pan"
            placeholder="Enter Pan"
            inputProps={{ style: { textTransform: 'uppercase' } }}
            onChange={(e) => {
              setFieldValue('panNumber', e.target.value.toUpperCase());
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="distributorId"
            label="Distributor Id *"
            placeholder="Enter Distributor Id"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="amcId" label="AmcId" placeholder="Enter AmcId" />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="buildingNo"
            label="Building Number"
            placeholder="Enter Building Number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="streetName" label="Street Name" placeholder="Enter Street Name" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="state" label="State" placeholder="Enter State" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="city" label="City" placeholder="Enter City" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="country" label="Country" placeholder="Enter Country" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFSelectField
            name={`companyName`}
            label={'Company Name *'}
            items={COMPANIES_MASTER_FORMETED_OBJECT}
            applyLabelStyles={true}
          />
        </Grid>
        {props.values.checkId && (
          <Grid item xs={12}>
            <Grid item xs={12} sm={6} md={8}>
              <MFTextField
                name="comment"
                label={'Comment(s) *'}
                multiline
                rows={4}
                placeholder="Type your answer here..."
              />
            </Grid>
          </Grid>
        )}
        {props?.values?.tempSignatoryDocuments?.length ? (
          <>
            <SubHeading>Document Details</SubHeading>
            {props.values.tempSignatoryDocuments?.map(
              (document: newDocumentProps, doc_index: number) => {
                const {
                  documentName,
                  documentsList = [],
                  required,
                  multipleFiles,
                  documentType,
                } = document;
                return (
                  <React.Fragment key={doc_index}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                          mt: '59px',
                        }}>
                        {documentName} {required === 'true' ? '*' : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {documentsList.map((doc, index) => (
                        <React.Fragment key={doc.uniqueKey}>
                          <FileUpload
                            onFileChange={(value, documentId, removeFile) => {
                              const { file, ...rest } =
                                props.values.tempSignatoryDocuments[doc_index].documentsList[index];
                              setFieldValue(
                                `tempSignatoryDocuments.${doc_index}.documentsList.${index}`,
                                {
                                  ...rest,
                                  documentId,
                                  ...(!removeFile && { file }),
                                }
                              );
                            }}
                            name={`tempSignatoryDocuments.${doc_index}.documentsList.${index}.documentId`}
                            documentData={doc}
                            disabled={false}
                            onFileUpload={props.handleUpload}
                            onFileUploaded={props.handleUploaded}
                          />
                        </React.Fragment>
                      ))}
                      {multipleFiles === 'true' &&
                        documentsList.filter((item) => item.isVisible).length <
                          (documentType === 'additional' ? 4 : 2) && (
                          <Typography
                            onClick={() => {
                              const currentDocTypeListLength =
                                props.values.tempSignatoryDocuments[doc_index].documentsList.length;
                              const {
                                required = true,
                                documentType,
                                documentName,
                                documentId,
                              } = props.values.tempSignatoryDocuments[doc_index].documentsList[
                                currentDocTypeListLength - 1
                              ];
                              try {
                                documentsList
                                  .filter((item) => item.isVisible)
                                  .forEach((item, _i) => {
                                    if (!item.documentId) {
                                      throw Error(
                                        `Please upload the ${getDocNumberForMultipleDocs(
                                          _i + 1
                                        )}  document to add more documents`
                                      );
                                    }
                                  });
                                setFieldValue(`tempSignatoryDocuments.${doc_index}.documentsList`, [
                                  ...props.values.tempSignatoryDocuments[doc_index].documentsList,
                                  {
                                    ...document_object,
                                    isVisible: true,
                                    uniqueKey:
                                      (doc_index + 1).toString() +
                                      '-' +
                                      doc_index.toString() +
                                      '-' +
                                      currentDocTypeListLength.toString(),
                                    documentType,
                                    documentName,
                                    required,
                                  },
                                ]);
                              } catch (e) {
                                dispatch(showError((e as Error).message));
                              }
                            }}
                            sx={{
                              color: '#417850',
                              fontSize: '12px',
                              fontWeight: 500,
                              textDecoration: 'underline',
                              alignSelf: 'end',
                              cursor: 'pointer',
                            }}>
                            {'Add another document'}
                          </Typography>
                        )}
                    </Grid>
                  </React.Fragment>
                );
              }
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
      {/* </Grid> */}
      <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
        <MFSubmitButton label="Save" loadingEnable={props?.documentUpload} />
      </Box>
    </>
  );
};

export default function AddAuthorisedSignatory({
  location,
}: {
  location: Location<{ authorisedSignatoryId: number; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authorisedSignatoryId, distributorId: ID } = location.state || {};
  const [formikValues, setFormikValues] = useState<AddAuthorisedSignatoryRequestBody | null>();
  const [documentsData, setDocumentsData] = useState<Document>();

  const [documentUpload, setDocumentUpload] = useState(false);
  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentsData(response as Document);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const docData = 'authorised_signatory_docs';

  const getAsDoc1 = (document: DocumentType[]) => {
    return typeof documentsData !== 'undefined'
      ? ((documentsData as Document)[docData] || []).map(
          (doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
            const { documentType, documentName, multipleFiles, required, options } = doc;
            const docsOfCurrentDocType = document
              ?.filter((doc) => doc.documentType === documentType)
              .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
            return {
              documentType: documentType,
              documentName: documentName,
              documentsList: docsOfCurrentDocType?.length
                ? docsOfCurrentDocType?.map((doc, ind) => {
                    const {
                      id,
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                      authorisedsignatoryId,
                    } = doc;
                    return {
                      id,
                      documentType,
                      documentName,
                      documentId,
                      isActive,
                      file,
                      options,
                      required,
                      uniqueKey: (index.toString() + '-' + ind.toString()) as string,
                      isVisible: true,
                      authorisedsignatoryId,
                    };
                  })
                : [
                    {
                      documentType,
                      documentName,
                      required,
                      options,
                      uniqueKey: (index.toString() + '-' + '0') as string,
                      isVisible: true,
                      authorisedsignatoryId: null,
                      ...document_object,
                    },
                  ],
              required,
              multipleFiles,
              options,
            };
          }
        )
      : [];
  };

  const initialValues: AddAuthorisedSignatoryBody = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    distributorId: ID,
    amcId: '',
    isActive: true,
    companyName: '',
    comment: '',
    signatoryDocuments: [],
    tempSignatoryDocuments: [],
  };

  const [authorisedSignatoryDetails, setAuthorisedSignatoryDetails] = useState({
    ...initialValues,
    checkId: authorisedSignatoryId,
  });

  const [loading, setLoading] = useState(false);
  const [authorisedSignatoryDetailsForComparision, setAuthorisedSignatoryDetailsForComparision] =
    useState({
      ...initialValues,
      checkId: authorisedSignatoryId,
    });

  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (authorisedSignatoryId) {
          const getAuthorisedSignatoryDetails = (await dispatch(
            getAuthorisedSignatoryById(authorisedSignatoryId)
          )) as unknown as AuthorisedSignatories;
          const {
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            isActive,
            comment,
            signatoryDocuments,
          } = getAuthorisedSignatoryDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
            companyName,
          } = getAuthorisedSignatoryDetails.user;
          const authorisedSignatoryObj = {
            ...authorisedSignatoryDetails,
            name: name || authorisedSignatoryDetails.name,
            phone: phone || authorisedSignatoryDetails.phone,
            email: email || authorisedSignatoryDetails.email,
            panNumber: panNumber || authorisedSignatoryDetails.panNumber,
            pincode: pincode || authorisedSignatoryDetails.pincode,
            buildingNo: buildingNo || authorisedSignatoryDetails.buildingNo,
            streetName: streetName || authorisedSignatoryDetails.streetName,
            city: city || authorisedSignatoryDetails.city,
            state: state || authorisedSignatoryDetails.state,
            country: country || authorisedSignatoryDetails.country,
            distributorId,
            amcId,
            id,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            companyName: companyName || authorisedSignatoryDetails.companyName,
            tempSignatoryDocuments: getAsDoc1(signatoryDocuments || []),
          };
          setAuthorisedSignatoryDetails({
            ...authorisedSignatoryObj,
            comment: comment || '',
          });
          setAuthorisedSignatoryDetailsForComparision(authorisedSignatoryObj);
        } else {
          setAuthorisedSignatoryDetails((prev) => ({
            ...prev,
            tempSignatoryDocuments: getAsDoc1(authorisedSignatoryDetails?.signatoryDocuments || []),
          }));
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, [documentsData]);

  const updateSignedDocs = (signedDocs: newDocumentProps[]): DocumentType[] => {
    const finalDocs = signedDocs.map((doc) => {
      return doc.documentsList.filter((d) => d.documentId);
    });
    return finalDocs
      .flat()
      .map((doc) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { uniqueKey, required, ...rest } = doc;
        return rest;
      })
      .filter((doc) => doc.documentType !== 'digitally_signed_document') as DocumentType[];
  };

  const onSubmit = async (values: AddAuthorisedSignatoryBody) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment, tempSignatoryDocuments, ...rest } = values;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment: comparedComment, ...restComparedData } =
        authorisedSignatoryDetailsForComparision;
      const updatedSignedDocs = updateSignedDocs(values.tempSignatoryDocuments || []);
      const checkAuthorisedSignatoryData = applicationComparison(restComparedData, {
        ...rest,
        countryCode: values.countryNameAndCode?.split(':')[1].trim(),
        signatoryDocuments: updatedSignedDocs,
      });
      if (checkAuthorisedSignatoryData) {
        throw noUpdationMsg;
      }
      const { tempSignatoryDocuments: existingtempDoc, ...restValues } = values;
      if (authorisedSignatoryId) {
        const result = (await dispatch(
          updateAuthorisedSignatory(authorisedSignatoryId, {
            ...restValues,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            signatoryDocuments: updatedSignedDocs,
          })
        )) as any;
        if (result.message) {
          setRouteDialogOpen({
            message: result.message,
            open: true,
          });
        }
      } else {
        const res = (await dispatch(
          addAuthorisedSignatory({
            ...restValues,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            signatoryDocuments: updatedSignedDocs,
            distributorId: ID,
            comment: initialComment,
          })
        )) as any;
        if (res.message) {
          setRouteDialogOpen({
            message: res.message,
            open: true,
          });
        }
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={authorisedSignatoryDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={AuthorisedSignatoryAndPOASchema}>
        {({ handleSubmit, values, errors }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    authorisedSignatoryId
                      ? history.push('authorised-signatory-details', {
                          authorisedSignatoryId: values.id,
                          distributorId: values.distributorId,
                        })
                      : history.push('authorised-signatory', {
                          distributorId: values.distributorId,
                        })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => {
                    authorisedSignatoryId
                      ? history.push('authorised-signatory-details', {
                          authorisedSignatoryId: values.id,
                          distributorId: values.distributorId,
                        })
                      : history.push('authorised-signatory', {
                          distributorId: values.distributorId,
                        });
                  }}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {authorisedSignatoryId
                  ? 'Authorised Signatories'
                  : 'Onboarding Authorised Signatories'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <CommonFormForCompanyDistributors
                values={values}
                handleUpload={handleUpload}
                handleUploaded={handleUploaded}
                documentUpload={documentUpload}
              />
            )}
            <NavigationThrowPopup
              handleClose={handleRoutePopupClose}
              dialogOpen={routeDialogOpen}
              onSave={() => {
                // handleErrorPopupClose();
                authorisedSignatoryId
                  ? history.push('authorised-signatory-details', {
                      authorisedSignatoryId,
                      distributorId: values.distributorId,
                    })
                  : history.push('authorised-signatory', { distributorId: values.distributorId });
              }}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
