import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { RmsType, Trustee } from '../../redux-store/types/api-types';
import {
  getRMById,
  gettrusteeAccess,
  getTrustesById,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const TrusteeFields = ({
  trusteeDetails,
  oldData,
}: {
  trusteeDetails: Trustee;
  oldData?: null | Trustee;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Name (Individual)'}
        description={trusteeDetails.userId ? trusteeDetails.user?.firstName : trusteeDetails?.name}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.firstName : oldData?.name,
                trusteeDetails.userId ? trusteeDetails.user?.firstName : trusteeDetails?.name
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.firstName : oldData?.name,
            trusteeDetails.userId ? trusteeDetails.user?.firstName : trusteeDetails?.name
          ) &&
          handleRoutePopupOpen(
            'Name (Individual)',
            trusteeDetails.userId ? trusteeDetails.user?.firstName : trusteeDetails?.name,
            oldData.userId ? oldData.user?.firstName : oldData?.name
          )
        }
      />
      <KeyValue
        title={'Leegality Name'}
        description={trusteeDetails.leegalityName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.leegalityName, trusteeDetails.leegalityName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.leegalityName, trusteeDetails.leegalityName) &&
          handleRoutePopupOpen(
            'Leegality Name',
            trusteeDetails.leegalityName,
            oldData.leegalityName
          )
        }
      />
      <KeyValue
        title={'Pan'}
        description={trusteeDetails.panNumber}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.panNumber, trusteeDetails.panNumber) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, trusteeDetails.panNumber) &&
          handleRoutePopupOpen('Pan', trusteeDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={'Email Id'}
        description={trusteeDetails.userId ? trusteeDetails.user?.email : trusteeDetails?.email}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                trusteeDetails.userId ? trusteeDetails.user?.email : trusteeDetails?.email
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.email : oldData?.email,
            trusteeDetails.userId ? trusteeDetails.user?.email : trusteeDetails?.email
          ) &&
          handleRoutePopupOpen(
            'Email Id',
            trusteeDetails.userId ? trusteeDetails.user?.email : trusteeDetails?.email,
            oldData.userId ? oldData.user?.email : oldData?.email
          )
        }
      />
      <KeyValue
        title={'Mobile Number'}
        description={trusteeDetails.userId ? trusteeDetails.user?.phone : trusteeDetails?.phone}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                trusteeDetails.userId ? trusteeDetails.user?.phone : trusteeDetails?.phone
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.phone : oldData?.phone,
            trusteeDetails.userId ? trusteeDetails.user?.phone : trusteeDetails?.phone
          ) &&
          handleRoutePopupOpen(
            'Mobile Number',
            trusteeDetails.userId ? trusteeDetails.user?.phone : trusteeDetails?.phone,
            oldData.userId ? oldData.user?.phone : oldData?.phone
          )
        }
      />
      <KeyValue
        title={'Pincode'}
        description={trusteeDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, trusteeDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, trusteeDetails.pincode) &&
          handleRoutePopupOpen('Pincode', trusteeDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={trusteeDetails.buildingNo}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.buildingNo, trusteeDetails.buildingNo) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, trusteeDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', trusteeDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={trusteeDetails.streetName}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.streetName, trusteeDetails.streetName) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, trusteeDetails.streetName) &&
          handleRoutePopupOpen('Street Name', trusteeDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={trusteeDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, trusteeDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, trusteeDetails.state) &&
          handleRoutePopupOpen('State', trusteeDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={trusteeDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, trusteeDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, trusteeDetails.city) &&
          handleRoutePopupOpen('City', trusteeDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={trusteeDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, trusteeDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, trusteeDetails.country) &&
          handleRoutePopupOpen('Country', trusteeDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Company Name'}
        description={
          trusteeDetails.userId ? trusteeDetails.user?.companyName : trusteeDetails?.companyName
        }
        sx={{
          textTransform: 'capitalize',
        }}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                trusteeDetails.userId
                  ? trusteeDetails.user?.companyName
                  : trusteeDetails?.companyName
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            oldData.userId ? oldData.user?.companyName : oldData?.companyName,
            trusteeDetails.userId ? trusteeDetails.user?.companyName : trusteeDetails?.companyName
          ) &&
          handleRoutePopupOpen(
            'Company Name',
            trusteeDetails.userId ? trusteeDetails.user?.companyName : trusteeDetails?.companyName,
            oldData.userId ? oldData.user?.companyName : oldData?.companyName
          )
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function TrusteeDetails({
  location,
}: {
  location: Location<{ trusteeId: number }>;
}): JSX.Element {
  const { trusteeId: id } = location.state || { trusteeId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [trusteeDetails, setTrusteeDetails] = useState<Trustee>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        if (id) {
          const getTrusteeDetails = (await dispatch(getTrustesById(id))) as unknown as Trustee;
          setTrusteeDetails(getTrusteeDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(trusteeAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('trustee')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('trustee')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Trustee
            </Typography>
          </Box>
          {accessForEditAdd && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() => history.push('edit-trustee', { trusteeId: trusteeDetails?.id })}
              />
            </IconButton>
          )}
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          trusteeDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <TrusteeFields trusteeDetails={trusteeDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {trusteeDetails.history && trusteeDetails.history.length > 0 && (
                <LogsUI data={trusteeDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
