/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography, Hidden, IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, RejectApproveButtons } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  ApproveComponent,
  ConfirmationDialogWithCommentsBox,
  RejectComponent,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import {
  AdminMakerCheckerProps,
  getAdminCheckerApprove,
  getAdminCheckerReject,
  getAdminMakerCheckerUserDetails,
} from '../../redux-store/actions/userFundsVerification';
import { UserFundsMaster, yesAndNoTrueAndFalseMasters } from '../../utils/constant';
import { applicationComparison } from '../../utils/utilityFunctions';
import { ClassPlanProps, accessType } from '../../redux-store/types/funds';
import { PlanFields } from '../FundsSetup/planDetail';
import { formatShortDateAndTime } from '../../utils/date';
import { fundAccess } from '../../redux-store/actions/funds';
import { Notes } from '../investors/components';
import { consentInfo } from './userDetails';

export default function PlanDetails(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState<AdminMakerCheckerProps>();
  const { id } = useParams<{ id: string }>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  const { path } = useRouteMatch();
  const [access, setAccess] = useState(false);

  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (id) {
          const getSchemeDetails = (await dispatch(
            getAdminMakerCheckerUserDetails(id)
          )) as unknown as AdminMakerCheckerProps;
          setPlanDetails(getSchemeDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handleRejectPopupOpen = () => setOpenRejectPopup(true);
  const handleApprovePopupDialogOpen = () => setOpenApprovePopup(true);

  const handleRejectPopupClose = () => {
    setOpenRejectPopup(false), setApproveLoading(false);
  };
  const handleApprovePopupClose = () => {
    setOpenApprovePopup(false), setRejectLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('/users-funds-verification/plansList')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              Plan(s)
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('/users-funds-verification/plansList')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Plan Details
            </Typography>
          </Box>
          <Typography
            className={UserFundsMaster[planDetails?.status || '']}
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: 'text.secondary',
              '&.Approved .MuiTypography-root': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected .MuiTypography-root': {
                color: '#BD2932',
                fontWeight: 500,
              },
            }}>
            Status:{' '}
            <Typography
              component="span"
              sx={{
                color: '#B78813',
                fontSize: '16px',
                letterSpacing: '0.4px',
                ml: 0.5,
              }}>
              {UserFundsMaster[planDetails?.status || '']}
            </Typography>
          </Typography>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          planDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    {(planDetails.makerComment || planDetails.checkerComment) && (
                      <Grid
                        item
                        sx={{
                          border: '1px solid #00D9DA',
                          p: 1.25,
                          borderRadius: 1.25,
                          my: 2,
                          mr: { lg: 20, xs: 4 },
                        }}>
                        {planDetails.makerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                              }}>
                              Maker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {planDetails.makerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box
                          display={'flex'}
                          sx={{ flexDirection: { xs: 'column', sm: 'row' }, mb: 1 }}>
                          {planDetails.makerAdminName && (
                            <KeyValue
                              title={'Requested By'}
                              description={planDetails.makerAdminName}
                            />
                          )}
                          {planDetails.createdAt && (
                            <KeyValue
                              title={'Requested At'}
                              description={formatShortDateAndTime(planDetails.createdAt)}
                            />
                          )}
                        </Box>
                        {planDetails.checkerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                                mt: 0.3,
                              }}>
                              Checker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {planDetails.checkerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box display={'flex'} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                          {planDetails.checkerAdminName && (
                            <KeyValue
                              title={
                                UserFundsMaster[planDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected By'
                                  : 'Approved By'
                              }
                              description={planDetails.checkerAdminName}
                            />
                          )}
                          {(planDetails.approvedAt || planDetails.rejectedAt) && (
                            <KeyValue
                              title={
                                UserFundsMaster[planDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected At'
                                  : 'Approved At'
                              }
                              description={formatShortDateAndTime(
                                planDetails.approvedAt || planDetails.rejectedAt
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <PlanFields
                        planDetails={planDetails.payload as ClassPlanProps}
                        oldData={planDetails.currentData as ClassPlanProps | null}
                      />
                      {planDetails.currentData && (
                        <KeyValue
                          title={'Access Enabled'}
                          description={
                            yesAndNoTrueAndFalseMasters[
                              (planDetails.payload as any)?.isActive.toString()
                            ]
                          }
                          dispalyModifiedIcon={
                            !applicationComparison(
                              (planDetails.currentData as any)?.isActive.toString(),
                              (planDetails.payload as any)?.isActive.toString()
                            )
                          }
                          onInfoClick={() =>
                            !applicationComparison(
                              (planDetails.currentData as any)?.isActive.toString(),
                              (planDetails.payload as any)?.isActive.toString()
                            ) &&
                            handleRoutePopupOpen(
                              'Access Enabled',
                              yesAndNoTrueAndFalseMasters[
                                (planDetails.payload as any)?.isActive.toString()
                              ],
                              yesAndNoTrueAndFalseMasters[
                                (planDetails.currentData as any)?.isActive.toString()
                              ]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )
        )}
      </Box>
      {UserFundsMaster[planDetails?.status || ''] === UserFundsMaster.pending &&
        path === '/users-funds-verification/plan-details/:id' &&
        (access ? (
          <RejectApproveButtons
            handleOpen={handleRejectPopupOpen}
            handleDialogOpen={handleApprovePopupDialogOpen}
            approve={approveLoading}
            reject={rejectLoading}
            displaySendBack={false}
          />
        ) : (
          <Box sx={{ mt: 2 }}>
            <Notes displayContent={consentInfo} />
          </Box>
        ))}
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
      <ConfirmationDialogWithCommentsBox
        component={<RejectComponent />}
        open={openRejectPopup}
        setOpen={handleRejectPopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleRejectPopupClose();
            setRejectLoading(true);
            await dispatch(getAdminCheckerReject(id, { comment: values.comment }));
            history.push('/users-funds-verification/plansList');
          } catch (e) {
            setRejectLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleRejectPopupClose}
      />
      <ConfirmationDialogWithCommentsBox
        component={<ApproveComponent />}
        open={openApprovePopup}
        setOpen={handleApprovePopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleApprovePopupClose();
            setApproveLoading(true);
            await dispatch(getAdminCheckerApprove(id, { comment: values.comment }));
            history.push('/users-funds-verification/plansList');
          } catch (e) {
            setApproveLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleApprovePopupClose}
      />
    </>
  );
}
