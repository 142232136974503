import { CALL_API } from '../middleware';

const GET_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS = 'GET_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS';
const GET_MDMS_REQUIRED_DOCUMENTS_MASTER = 'GET_MDMS_REQUIRED_DOCUMENTS_MASTER';

const UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS =
  'UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS';
const UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER = 'UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER';

export const mdmsRequiredDocumentsMasterDownload =
  () =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        //when ever changing file name from required_document_master.json to any other name in mdms then update the same here
        url: `/onboarding/applications/mdms/required_document_master.json`,
        method: 'GET',
        types: [GET_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS, GET_MDMS_REQUIRED_DOCUMENTS_MASTER],
        contentType: 'application/pdf',
      },
    });
  };

export const mdmsRequiredDocumentsMasterUpdate =
  (fileName: string, body: { documentId: string | number; comment: string }) =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        //when ever changing file name from required_document_master.json to any other name in mdms then update the same here
        url: `/onboarding/applications/mdms/${fileName}`,
        method: 'POST',
        types: [
          UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER_SUCCESS,
          UPDATE_MDMS_REQUIRED_DOCUMENTS_MASTER,
        ],
        body,
      },
    });
  };
