/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  AmcAdmin,
  AuthorisedSignatories,
  DistributorListType,
  FundManager,
  RmsType,
  SubDistributorListType,
  Trustee,
  poaApprover,
} from '../types/api-types';
import { ClassPlanProps, FundProps, addFundType } from '../types/funds';
import { getRequiredForEsignType } from './userManagement';

export type AdminMakerCheckerProps = {
  id: string;
  makerAdminId: string;
  makerAdminName: string;
  makerAdminPhone: string;
  makerAdminEmail: string;
  makerComment: string | null;
  checkerAdminId: string | null;
  checkerAdminName: string | null;
  checkerAdminPhone: string | null;
  checkerAdminEmail: string | null;
  checkerComment: string | null;
  currentData:
    | RmsType
    | DistributorListType
    | Trustee
    | FundManager
    | AmcAdmin
    | SubDistributorListType
    | AuthorisedSignatories
    | poaApprover
    | FundProps
    | ClassPlanProps
    | Partial<addFundType>
    | getRequiredForEsignType
    | string
    | null;
  payload:
    | RmsType
    | DistributorListType
    | Trustee
    | FundManager
    | AmcAdmin
    | SubDistributorListType
    | AuthorisedSignatories
    | poaApprover
    | FundProps
    | ClassPlanProps
    | Partial<addFundType>
    | getRequiredForEsignType
    | string
    | null;
  payloadId: string | null;
  endpoint: string;
  approvedAt: string | null;
  rejectedAt: string | null;
  status: string;
  isActive: boolean;
  createdBy: string;
  createdAt: string | null;
};

type GetAdminMAkerCheckerRequestBody = {
  limit: number;
  page: number;
  sort: string;
  order: string;
  search: string;
  status: string;
  endpoint: string;
  createdBy: string;
  documentType?: string;
  payloadId?: string;
};

export type GetAdminMakerCheckerResponseBody = {
  activityRequests: AdminMakerCheckerProps[];
  limit: number;
  page: number;
  pages: number;
  total: number;
  status: string;
  endpoint: string;
  createdBy: string;
};

const GET_LIST_FOR_ADMIN_MAKER_CHECKER_SUCCESS = 'GET_LIST_FOR_ADMIN_MAKER_CHECKER_SUCCESS';
const GET_LIST_FOR_ADMIN_MAKER_CHECKER = 'GET_LIST_FOR_ADMIN_MAKER_CHECKER';
const GET_USER_DETAILS_AT_MAKER_CHECKER_SUCCESS = 'GET_USER_DETAILS_AT_MAKER_CHECKER_SUCCESS';
const GET_USER_DETAILS_AT_MAKER_CHECKER = 'GET_USER_DETAILS_AT_MAKER_CHECKER';
const ADMIN_CHECKER_APPROVED_SUCCESS = 'ADMIN_CHECKER_APPROVED_SUCCESS';
const ADMIN_CHECKER_APPROVED = 'ADMIN_CHECKER_APPROVED';
const ADMIN_CHECKER_REJECTED_SUCCESS = 'ADMIN_CHECKER_REJECTED_SUCCESS';
const ADMIN_CHECKER_REJECTED = 'ADMIN_CHECKER_REJECTED';
const GET_NEW_OR_OLD_DOCUMENTS_SUCCESS = 'GET_NEW_OR_OLD_DOCUMENTS_SUCCESS';
const GET_NEW_OR_OLD_DOCUMENTS = 'GET_NEW_OR_OLD_DOCUMENTS';

export const getAdminMakerCheckerParams = (
  paramsObjForAdminMakerChecker: Partial<GetAdminMAkerCheckerRequestBody>
) => ({
  type: 'GET_PARAMS_FOR_ADMIN_MAKER_CHECKER_DATA',
  paramsObjForAdminMakerChecker,
});

export const getAdminMakerCheckerRequests =
  (params: Partial<GetAdminMAkerCheckerRequestBody>) =>
  async (dispatch: any): Promise<GetAdminMakerCheckerResponseBody> => {
    await dispatch(getAdminMakerCheckerParams(params));
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests`,
        method: 'GET',
        types: [GET_LIST_FOR_ADMIN_MAKER_CHECKER_SUCCESS, GET_LIST_FOR_ADMIN_MAKER_CHECKER],
        params: {
          limit: 10,
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const getAdminMakerCheckerUserDetails =
  (userReferenceId: string) =>
  async (dispatch: any): Promise<AdminMakerCheckerProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests/${userReferenceId}`,
        method: 'GET',
        types: [GET_USER_DETAILS_AT_MAKER_CHECKER_SUCCESS, GET_USER_DETAILS_AT_MAKER_CHECKER],
      },
    });
  };

export const getAdminCheckerApprove =
  (userReferenceId: string, body: { comment: string }) =>
  async (dispatch: any): Promise<AdminMakerCheckerProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests/approve/${userReferenceId}`,
        method: 'Post',
        types: [ADMIN_CHECKER_APPROVED_SUCCESS, ADMIN_CHECKER_APPROVED],
        body,
      },
    });
  };

export const getAdminCheckerReject =
  (userReferenceId: string, body: { comment: string }) =>
  async (dispatch: any): Promise<AdminMakerCheckerProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests/reject/${userReferenceId}`,
        method: 'Post',
        types: [ADMIN_CHECKER_REJECTED_SUCCESS, ADMIN_CHECKER_REJECTED],
        body,
      },
    });
  };

export const getHistoryForMisAdminMakerCheckerRequests =
  (params: Partial<GetAdminMAkerCheckerRequestBody>) =>
  async (dispatch: any): Promise<GetAdminMakerCheckerResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests`,
        method: 'GET',
        types: [GET_LIST_FOR_ADMIN_MAKER_CHECKER_SUCCESS, GET_LIST_FOR_ADMIN_MAKER_CHECKER],
        params: {
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const downloadOldNewDocumentsInMiscellaneous =
  (requestId: string, params: Partial<GetAdminMAkerCheckerRequestBody>) =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/activity-requests/mdms/${requestId}`,
        method: 'GET',
        types: [GET_NEW_OR_OLD_DOCUMENTS_SUCCESS, GET_NEW_OR_OLD_DOCUMENTS],
        contentType: 'application/pdf',
        params,
      },
    });
  };
