import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { DistributorListType, RmsType } from '../../redux-store/types/api-types';
import { getDistributorById, getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { applicationComparison } from '../../utils/utilityFunctions';

export const DistributorFields = ({
  distributorDetails,
  oldData,
}: {
  distributorDetails: DistributorListType;
  oldData?: null | DistributorListType;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Distributor/POA Name'}
        description={distributorDetails.name}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.name, distributorDetails.name) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.name, distributorDetails.name) &&
          handleRoutePopupOpen('Distributor Name', distributorDetails.name, oldData.name)
        }
      />
      {distributorDetails.type === 'individual' && (
        <>
          <KeyValue
            title={'Email Id'}
            description={
              distributorDetails.userId ? distributorDetails.user?.email : distributorDetails?.email
            }
            dispalyModifiedIcon={
              oldData
                ? !applicationComparison(
                    oldData.userId ? oldData.user?.email : oldData?.email,
                    distributorDetails.userId
                      ? distributorDetails.user?.email
                      : distributorDetails?.email
                  )
                : false
            }
            onInfoClick={() =>
              oldData &&
              !applicationComparison(
                oldData.userId ? oldData.user?.email : oldData?.email,
                distributorDetails.userId
                  ? distributorDetails.user?.email
                  : distributorDetails?.email
              ) &&
              handleRoutePopupOpen(
                'Email Id',
                distributorDetails.userId
                  ? distributorDetails.user?.email
                  : distributorDetails?.email,
                oldData.userId ? oldData.user?.email : oldData?.email
              )
            }
          />
          <KeyValue
            title={'Contact Number'}
            description={
              distributorDetails.userId ? distributorDetails.user?.phone : distributorDetails?.phone
            }
            dispalyModifiedIcon={
              oldData
                ? !applicationComparison(
                    oldData.userId ? oldData.user?.phone : oldData?.phone,
                    distributorDetails.userId
                      ? distributorDetails.user?.phone
                      : distributorDetails?.phone
                  )
                : false
            }
            onInfoClick={() =>
              oldData &&
              !applicationComparison(
                oldData.userId ? oldData.user?.phone : oldData?.phone,
                distributorDetails.userId
                  ? distributorDetails.user?.phone
                  : distributorDetails?.phone
              ) &&
              handleRoutePopupOpen(
                'Contact Number',
                distributorDetails.userId
                  ? distributorDetails.user?.phone
                  : distributorDetails?.phone,
                oldData.userId ? oldData.user?.phone : oldData?.phone
              )
            }
          />
        </>
      )}
      <KeyValue
        title={'Distributor/POA PAN'}
        description={distributorDetails.panNumber}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.panNumber, distributorDetails.panNumber) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.panNumber, distributorDetails.panNumber) &&
          handleRoutePopupOpen('Pan', distributorDetails.panNumber, oldData.panNumber)
        }
      />
      <KeyValue
        title={'ARN Code'}
        description={distributorDetails.arnCode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.arnCode, distributorDetails.arnCode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.arnCode, distributorDetails.arnCode) &&
          handleRoutePopupOpen('ARN Code', distributorDetails.arnCode, oldData.arnCode)
        }
      />
      {distributorDetails.type === 'individual' && (
        <KeyValue
          title={'RM Name'}
          description={distributorDetails?.rm?.name}
          dispalyModifiedIcon={
            oldData
              ? !applicationComparison(Number(oldData?.rmId), Number(distributorDetails?.rmId))
              : false
          }
          onInfoClick={() =>
            oldData &&
            !applicationComparison(Number(oldData?.rmId), Number(distributorDetails?.rmId)) &&
            handleRoutePopupOpen('RM Name', distributorDetails?.rm?.name, oldData?.rm?.name)
          }
        />
      )}
      <KeyValue
        title={'Pincode'}
        description={distributorDetails.pincode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.pincode, distributorDetails.pincode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.pincode, distributorDetails.pincode) &&
          handleRoutePopupOpen('Pincode', distributorDetails.pincode, oldData.pincode)
        }
      />
      <KeyValue
        title={'Building Number'}
        description={distributorDetails.buildingNo}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.buildingNo, distributorDetails.buildingNo)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.buildingNo, distributorDetails.buildingNo) &&
          handleRoutePopupOpen('Building Number', distributorDetails.buildingNo, oldData.buildingNo)
        }
      />
      <KeyValue
        title={'Street Name'}
        description={distributorDetails.streetName}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData.streetName, distributorDetails.streetName)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.streetName, distributorDetails.streetName) &&
          handleRoutePopupOpen('Street Name', distributorDetails.streetName, oldData.streetName)
        }
      />
      <KeyValue
        title={'State'}
        description={distributorDetails.state}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.state, distributorDetails.state) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.state, distributorDetails.state) &&
          handleRoutePopupOpen('State', distributorDetails.state, oldData.state)
        }
      />
      <KeyValue
        title={'City'}
        description={distributorDetails.city}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.city, distributorDetails.city) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.city, distributorDetails.city) &&
          handleRoutePopupOpen('City', distributorDetails.city, oldData.city)
        }
      />
      <KeyValue
        title={'Country'}
        description={distributorDetails.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.country, distributorDetails.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.country, distributorDetails.country) &&
          handleRoutePopupOpen('Country', distributorDetails.country, oldData.country)
        }
      />
      <KeyValue
        title={'Distributor/POA KIN'}
        description={distributorDetails.poaKinNum}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData.poaKinNum, distributorDetails.poaKinNum) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData.poaKinNum, distributorDetails.poaKinNum) &&
          handleRoutePopupOpen(
            'Distributor/POA KIN',
            distributorDetails.poaKinNum,
            oldData.poaKinNum
          )
        }
      />
      {distributorDetails.type === 'individual' && (
        <KeyValue
          title={'Company Name'}
          description={
            distributorDetails.userId
              ? distributorDetails.user?.companyName
              : distributorDetails?.companyName
          }
          sx={{
            textTransform: 'capitalize',
          }}
          dispalyModifiedIcon={
            oldData
              ? !applicationComparison(
                  oldData.userId ? oldData.user?.companyName : oldData?.companyName,
                  distributorDetails.userId
                    ? distributorDetails.user?.companyName
                    : distributorDetails?.companyName
                )
              : false
          }
          onInfoClick={() =>
            oldData &&
            !applicationComparison(
              oldData.userId ? oldData.user?.companyName : oldData?.companyName,
              distributorDetails.userId
                ? distributorDetails.user?.companyName
                : distributorDetails?.companyName
            ) &&
            handleRoutePopupOpen(
              'Company Name',
              distributorDetails.userId
                ? distributorDetails.user?.companyName
                : distributorDetails?.companyName,
              oldData.userId ? oldData.user?.companyName : oldData?.companyName
            )
          }
        />
      )}
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function UserManagementDistributorDetails({
  location,
}: {
  location: Location<{ distributorId: number }>;
}): JSX.Element {
  const { distributorId: id } = location.state || { distributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState<DistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(id)
          )) as unknown as DistributorListType;
          setDistributorDetails(getDistributorDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('distributors', {
                  distributorType: distributorDetails?.type,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('distributors', {
                    distributorType: distributorDetails?.type,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
                textTransform: 'capitalize',
              }}>
              {distributorDetails?.type
                ? distributorDetails?.type + ' Distributor Detail'
                : 'Distributor Detail'}
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-distributor', { distributorId: distributorDetails?.id })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <DistributorFields distributorDetails={distributorDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {distributorDetails.history && distributorDetails.history.length > 0 && (
                <LogsUI data={distributorDetails.history} />
              )}
            </>
          )
        )}
      </Box>
    </>
  );
}
