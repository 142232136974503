/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CardMedia, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { NomineeType } from '../../redux-store/types/api-types';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  getNomineeRelation,
  isMinor,
  isNumberNull,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { FetchData, updateApplication } from '../../redux-store/actions/application';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { addNomineeDetailsSchema, nomineeDetailsSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  NomineeRelations,
  USER_ROLES,
} from '../../utils/constant';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { showError } from '../../redux-store/actions/auth';
import { individualPanRegex } from '../../utils/regex';

export type updateNominee = NomineeType & {
  Relationship: string | null;
  sno?: number;
  edited: boolean;
};

type Values = {
  doNotWishToNominate: boolean;
  nominees: updateNominee[];
  saveType: string;
};
type AddNewNominee = {
  nominees: updateNominee[];
  nomineePan: string;
  dateOfBirth: string | null;
  sno: number;
};

const addNewNominee: AddNewNominee = {
  nominees: [],
  nomineePan: '',
  dateOfBirth: null,
  sno: 0,
};

const newNominee: updateNominee = {
  nomineeName: '',
  nomineeRelationship: '',
  dateOfBirth: null,
  nomineePercentage: 0,
  guardianName: '',
  nomineePan: '',
  nomineeGuardianPan: '',
  isActive: true,
  Relationship: '',
  cancelledAt: null,
  favourOf: '',
  sno: 0,
  nominationDocumentCheck: false,
  edited: true,
};

const initialValues: Values = {
  doNotWishToNominate: true,
  nominees: [newNominee],
  saveType: 'save and proceed',
};

const Nominee = ({
  nomineeName,
  index,
  values,
  // setAddNewNomineeData,
  hasPOA,
  kraFetchData,
  setKraFetchData,
}: {
  nomineeName: number;
  index: number;
  values: Values;
  // setAddNewNomineeData: any;
  hasPOA: boolean;
  kraFetchData: boolean;
  setKraFetchData: any;
}): JSX.Element => {
  const { dateOfBirth = '' } = values.nominees[index];
  const { setFieldValue, setValues } = useFormikContext();
  const [openClander, setOpenClander] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [fetchData, setFetchData] = useState<number | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabledForPOALogin = [USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      {hasPOA && (
        <Grid item xs={12}>
          <MFCheckbox
            name={`nominees.${index}.nominationDocumentCheck`}
            label={'Wish to upload Nomination Form'}
            sx={{ my: 2 }}
            onChange={({ target: { checked } }) => {
              setValues({
                ...values,
                nominees: values.nominees.map((_nominee) => {
                  return {
                    ..._nominee,
                    ...newNominee,
                    isActive: _nominee.isActive,
                    sno: _nominee.sno,
                    nominationDocumentCheck: checked,
                    edited: !checked,
                  };
                }),
              });
            }}
            disabled={nomineeName !== 1 || isFieldDisabledForPOALogin}
          />
        </Grid>
      )}
      {!values.nominees[index].nominationDocumentCheck && (
        <>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={'Date of birth *'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'Enter Date of birth'}
              onClick={() => {
                setFieldValue(`nominees.${index}.edited`, true);
                // setAddNewNomineeData({
                //   sno: index,
                //   dateOfBirth: values.nominees[index].dateOfBirth,
                //   nomineePan: values.nominees[index].nomineePan,
                //   nominees: values.nominees[index],
                // });
              }}
              // open={openClander}
              // onOpen={() => {
              //   setAddNewNomineeData({
              //     sno: index,
              //     dateOfBirth: values.nominees[index].dateOfBirth,
              //     nomineePan: values.nominees[index].nomineePan,
              //     nominees: values.nominees[index],
              //   });
              //   setOpenClander(false);
              // }}
              name={`nominees.${index}.dateOfBirth`}
              disabled={isFieldDisabledForPOALogin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineePan`}
              label={`Nominee PAN ${isMinor(dateOfBirth || '') ? `` : `*`}`}
              placeholder="Enter Nominee PAN"
              inputProps={{ style: { textTransform: 'uppercase' } }}
              disabled={isFieldDisabledForPOALogin}
              onChange={(e) => {
                setValues({
                  ...values,
                  nominees: values.nominees.map((nominee) => {
                    const isEdited = nominee.sno === index;
                    return isEdited
                      ? {
                          ...nominee,
                          edited: true,
                          nomineePan: e.target.value.toUpperCase(),
                        }
                      : nominee;
                  }),
                });
                // setFieldValue(`nominees.${index}.nomineePan`, e.target.value.toUpperCase());
                // setAddNewNomineeData({
                //   sno: index,
                //   dateOfBirth: values.nominees[index].dateOfBirth,
                //   nomineePan: values.nominees[index].nomineePan,
                //   nominees: values.nominees[index],
                // });
              }}
            />
          </Grid>
          {values.nominees[index].edited && (
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              display="flex"
              sx={{
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
              }}>
              <LoadingButton
                loadingPosition="start"
                // type="submit"
                loading={kraFetchData && index === fetchData}
                disabled={kraFetchData}
                variant="contained"
                sx={{
                  fontSize: 15,
                  minWidth: 180,
                  letterSpacing: 2,
                  fontWeight: 500,
                  alignSelf: 'center',
                  my: 1,
                  mx: { xs: 0, sm: 0 },
                }}
                onClick={async () => {
                  try {
                    setFetchData(index);
                    setKraFetchData(true);
                    if (
                      !values.nominees[index].dateOfBirth ||
                      (!isMinor(values.nominees[index].dateOfBirth || '') &&
                        !values.nominees[index].nomineePan)
                    ) {
                      throw 'Please fill all the required(*) fields';
                    }
                    if (
                      values.nominees[index].nomineePan &&
                      !values.nominees[index].nomineePan?.match(individualPanRegex)
                    ) {
                      throw 'Only individual PANs are allowed';
                    }
                    const applicantsPan =
                      application?.applicants?.map((applicant) => applicant.panNo) || [];
                    if (
                      values.nominees[index].nomineePan &&
                      applicantsPan.length &&
                      applicantsPan?.includes(values.nominees[index].nomineePan)
                    ) {
                      throw 'Applicant PAN not allowed to enter as nominee PAN';
                    }
                    if (
                      values.nominees[index].nomineePan &&
                      values.nominees.filter(
                        (nominee: NomineeType, _index: number) =>
                          nominee.nomineePan === values.nominees[index].nomineePan &&
                          nominee.isActive &&
                          _index !== values.nominees[index].sno
                      ).length
                    ) {
                      throw 'There is already same pan for a nominee associated with this application';
                    }
                    if (
                      isMinor(values.nominees[index].dateOfBirth || '') &&
                      !values.nominees[index].nomineePan
                    ) {
                      setFieldValue(`nominees.${index}`, {
                        ...values.nominees.find(
                          (nominee) => nominee.sno === values.nominees[index].sno
                        ),
                        ...newNominee,
                        dateOfBirth: values.nominees[index].dateOfBirth,
                        sno: values.nominees[index].sno,
                        edited: false,
                      });
                    } else {
                      const { nomineePan, dateOfBirth, sno } = values.nominees[index];
                      if (nomineePan && dateOfBirth) {
                        const response = (await dispatch(
                          FetchData({
                            panNumber: nomineePan as string,
                            dob: dateOfBirth as string,
                          })
                        )) as any;
                        if (Object.keys(response.data).length === 0) {
                          enqueueSnackbar('Data Not Found', {
                            variant: 'error',
                            autoHideDuration: 3000,
                          });
                          setFieldValue(`nominees.${index}`, {
                            ...values.nominees.find((nominee) => nominee.sno === sno),
                            ...newNominee,
                            nomineePan,
                            dateOfBirth,
                            sno,
                            edited: false,
                          });
                        }
                        if (Object.keys(response.data).length) {
                          enqueueSnackbar('Data Fetched Successfully', {
                            variant: 'success',
                            autoHideDuration: 3000,
                          });
                          const findNominee =
                            values.nominees.find((nominee) => nominee.sno === sno) || newNominee;
                          setFieldValue(`nominees.${index}`, {
                            ...values.nominees.find((nominee) => nominee.sno === sno),
                            ...newNominee,
                            nomineePan: response.data.panNumber || nomineePan,
                            dateOfBirth: response.data.dob || dateOfBirth,
                            nomineeName: response.data.name || '',
                            sno: sno,
                            edited: false,
                          });
                        }
                      }
                    }
                  } catch (e) {
                    typeof e === 'string' && dispatch(showError(e));
                    console.error((e as Error).message);
                  } finally {
                    setKraFetchData(false);
                    setFetchData(null);
                  }
                }}>
                {isMinor(values.nominees[index].dateOfBirth || '') &&
                !values.nominees[index].nomineePan
                  ? `Proceed`
                  : `Fetch Data`}
              </LoadingButton>
            </Grid>
          )}
          {!values.nominees[index].edited && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`nominees.${index}.nomineeName`}
                  label={`Nominee Name ${nomineeName} *`}
                  placeholder={`Enter Nominee Name ${nomineeName}`}
                  disabled={isFieldDisabledForPOALogin}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFSelectField
                  name={`nominees.${index}.Relationship`}
                  label="Nominee Relationship *"
                  items={NomineeRelations.filter((relation) => {
                    const { nominees } = values;
                    const selectedRelations = nominees
                      .filter(
                        (nominee, ind) =>
                          ind !== index &&
                          nominee.Relationship &&
                          nominee.isActive &&
                          ['MOTHER', 'FATHER', 'SPOUSE'].includes(nominee.Relationship)
                      )
                      .map((nominee) => nominee.Relationship);
                    return !selectedRelations.includes(relation);
                  }).map((relation) => ({
                    key: relation,
                    value: relation,
                  }))}
                  disabled={isFieldDisabledForPOALogin}
                />
                {values.nominees.map((nominee, idx: number) => {
                  if (nominee.Relationship === 'OTHERS' && idx === index) {
                    return (
                      <MFTextField
                        name={`nominees.${index}.nomineeRelationship`}
                        label="Please Specify Relationship *"
                        placeholder={`Enter Nominee Relationship`}
                        disabled={isFieldDisabledForPOALogin}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`nominees.${index}.nomineePercentage`}
                  label="Nominee % *"
                  placeholder="Enter Nominee %"
                  disabled={isFieldDisabledForPOALogin}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Cancellation Date'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 125))}
          placeholder={'Enter Cancellation Date '}
          name={`nominees.${index}.cancelledAt`}
        />
      </Grid> */}
              {isMinor(dateOfBirth ? dateOfBirth : '') && (
                <>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`nominees.${index}.guardianName`}
                      label="Guardian Name *"
                      placeholder="Enter Guardian Name"
                      disabled={isFieldDisabledForPOALogin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`nominees.${index}.nomineeGuardianPan`}
                      label="Guardian PAN *"
                      placeholder="Enter Guardian Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue(
                          `nominees.${index}.nomineeGuardianPan`,
                          e.target.value.toUpperCase()
                        );
                      }}
                      disabled={isFieldDisabledForPOALogin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`nominees.${index}.favourOf`}
                      label="Guardian Ckyc No."
                      placeholder="Enter Guardian Ckyc No."
                      disabled={isFieldDisabledForPOALogin}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default function NomineeDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [nomineesData, setNomineesData] = useState(initialValues);
  const [addNewNomineeData, setAddNewNomineeData] = useState(addNewNominee);
  const [loading, setLoading] = useState(false);
  const [nomineeToDelete, setNomineeToDelete] = useState<{
    nominee: Partial<NomineeType>;
    index: number;
  } | null>(null);
  // const [nomineesToDelete, setNomineesToDelete] = useState<
  //   | {
  //       nominee: Partial<NomineeType>;
  //       // index: number;
  //     }[]
  //   | null
  // >(null);
  const [bulkNomineeDelete, setBulkNomineeDelete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [kraFetchData, setKraFetchData] = useState<boolean>(false);
  const [open, setOpen] = useState<{
    index: number;
  } | null>(null);

  const onSubmit = async (values: Values) => {
    try {
      const {
        nominees: existingNominees = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { nominees, doNotWishToNominate, saveType } = values;
      if (
        nominees.filter((nominee) => nominee.isActive).filter((nominee) => nominee.edited).length
      ) {
        throw 'Please Provide Nominee Details';
      }
      const updatedPayload = {
        ...application,
        doNotWishToNominate,
        nominees: nominees
          .filter((nominee) => nominee?.id || nominee.isActive)
          .map((nominee, index) => {
            const { id = null } = existingNominees[index] || {};
            if (!nominee.nominationDocumentCheck) {
              if (nominee.id === id) {
                const { Relationship, sno, edited, ...rest } = nominee;
                return {
                  ...(existingNominees[index] || {}),
                  ...rest,
                  nomineeRelationship: getNomineeRelation(Relationship)
                    ? Relationship
                    : removeSingleQuote(nominee.nomineeRelationship),
                  dateOfBirth: !nominee.dateOfBirth
                    ? null
                    : new Date(nominee.dateOfBirth).toISOString(),
                  isActive: nominee.isActive && !doNotWishToNominate,
                  nomineeName: removeSingleQuote(rest.nomineeName),
                  guardianName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                    ? removeSingleQuote(rest.guardianName)
                    : null,
                  nomineeGuardianPan: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                    ? removeSingleQuote(rest.nomineeGuardianPan)
                    : null,
                  cancelledAt: nominee?.cancelledAt ? nominee?.cancelledAt : null,
                  favourOf: nominee?.favourOf ? nominee?.favourOf : '',
                  nomineePercentage: !isNumberNull(nominee.nomineePercentage)
                    ? Number(nominee.nomineePercentage)
                    : null,
                };
              }
              const { Relationship, sno, edited, ...rest1 } = nominees[nominee.sno as number];
              return {
                ...rest1,
                nomineeRelationship: removeSingleQuote(rest1.nomineeRelationship),
                nomineeName: removeSingleQuote(rest1.nomineeName),
                guardianName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                  ? removeSingleQuote(rest1.guardianName)
                  : null,
                nomineeGuardianPan: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                  ? removeSingleQuote(rest1.nomineeGuardianPan)
                  : null,
                nomineePercentage: !isNumberNull(rest1.nomineePercentage)
                  ? Number(rest1.nomineePercentage)
                  : null,
              };
            } else {
              const { Relationship, sno, edited, ...rest1 } = newNominee;
              const { Relationship: Relationship2, sno: sno2, edited: edited1, ...rest2 } = nominee;
              return {
                ...rest2,
                ...rest1,
                isActive: nominee.isActive,
                nomineePercentage: null,
                nominationDocumentCheck: true,
              };
            }
          }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          nominees: application?.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        },
        {
          ...updatedPayload,
          nominees: updatedPayload.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
          currentStep: !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 6,
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const setData = (nomineevalues: any) => {
    setNomineesData({
      ...nomineesData,
      doNotWishToNominate: false,
      nominees:
        open && nomineesData.nominees[nomineevalues.sno]
          ? nomineesData.nominees.map((nominee: updateNominee, index: number) => {
              if (open && nomineevalues.sno === nominee.sno)
                return {
                  ...nominee,
                  ...nomineevalues,
                };
              return nominee;
            })
          : [
              ...nomineesData.nominees,
              {
                ...nomineevalues,
                nomineePercentage: nomineesData.nominees?.filter((item) => item.isActive).length
                  ? 0
                  : 100,
              },
            ],
    });
  };

  const onClose = () => {
    !kraFetchData && setOpen(null);
    !kraFetchData && setAddNewNomineeData(addNewNominee);
  };

  const FetchKRA = async (values: AddNewNominee) => {
    try {
      setKraFetchData(true);
      const { nomineePan, dateOfBirth, nominees, sno } = values;
      if (nomineePan) {
        const response = (await dispatch(
          FetchData({
            panNumber: nomineePan as string,
            dob: dateOfBirth as string,
          })
        )) as any;
        if (Object.keys(response.data).length === 0) {
          enqueueSnackbar('Data Not Found', {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setData({ ...newNominee, nomineePan, dateOfBirth, sno });
        }
        if (Object.keys(response.data).length) {
          enqueueSnackbar('Data Fetched Successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
          const findNominee =
            nomineesData.nominees.find((nominee) => nominee.sno === sno) || newNominee;
          setData({
            ...findNominee,
            nomineePan: response.data.panNumber || nomineePan,
            dateOfBirth: response.data.dob || dateOfBirth,
            nomineeName: response.data.name || '',
            sno: sno,
          });
        }
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setOpen(null);
      setAddNewNomineeData(addNewNominee);
      setKraFetchData(false);
    }
  };

  useEffect(() => {
    const { nominees = [], doNotWishToNominate = true } = application || {};
    setNomineesData({
      ...nomineesData,
      doNotWishToNominate: nominees.length
        ? false
        : doNotWishToNominate === null
        ? true
        : doNotWishToNominate,
      nominees: nominees.length
        ? nominees.map((nominee, index) => ({
            ...nominee,
            Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? nominee.nomineeRelationship
                ? nominee.nomineeRelationship.toUpperCase()
                : nominee.nomineeRelationship
              : 'OTHERS',
            nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? ''
              : nominee.nomineeRelationship,
            sno: index,
            nomineePercentage: nominee.nomineePercentage
              ? nominee.nomineePercentage
              : nominees.length === 1
              ? 100
              : 0,
            edited: false,
            nominationDocumentCheck:
              nominee.nominationDocumentCheck === null && application?.hasPOA
                ? true
                : nominee.nominationDocumentCheck,
          }))
        : [],
    });
  }, [application]);

  const isFieldDisabledForPOALogin = [USER_ROLES.POAAPPROVER].includes(role);
  // const isEdited = (values: Values) => {
  //   try {
  //     if (
  //       values.nominees.filter((nominee) => nominee.isActive).filter((nominee) => nominee.edited)
  //         .length
  //     ) {
  //       throw 'Please Provide Nominee Details';
  //     }
  //   } catch (e) {
  //     typeof e === 'string' && dispatch(showError(e));
  //     console.error((e as Error).message);
  //   }
  // };
  return (
    <Formik
      initialValues={nomineesData}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nomineeDetailsSchema(application?.applicants), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, setFieldValue, errors }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {/* {useMemo(() => setNomineesData(values), [values])} */}
          <Notes displayContent={'Investor(s) have the option to choose upto three nominees'} />
          <Grid item xs={12}>
            <MFCheckbox
              disabled={kraFetchData || isFieldDisabledForPOALogin}
              // disabled={!!values.nominees.length}
              name="doNotWishToNominate"
              // label="I do not wish to nominate."
              label="I/ We hereby confirm that I/ We do not wish to appoint any nominee(s) for my/ our Investment units held in my/ our folio and understand the issues involved in non-appointment of nominee(s) and further are aware that in case of death of all the account holder(s), my/ our legal heirs would need to submit all the requisite documents issued by Court or other such competent authority, based on the value of assets held in the said folio."
              sx={{ letterSpacing: '1px', alignItems: 'flex-start', textAlign: 'justify' }}
              onChange={({ target: { checked } }) => {
                const updatedNominees = !checked
                  ? [
                      ...values.nominees,
                      { ...newNominee, nomineePercentage: 100, sno: values.nominees.length },
                    ]
                  : values.nominees;
                setValues({
                  ...nomineesData,
                  doNotWishToNominate:
                    values.nominees?.filter((item) => item.isActive).length === 0
                      ? checked
                      : !checked,
                  nominees: updatedNominees.map((nominee) => ({
                    ...nominee,
                    nominationDocumentCheck: application?.hasPOA
                      ? values.nominees.filter((item) => item.isActive).length === 0
                        ? true
                        : values.nominees.filter((item) => item.isActive)[0].nominationDocumentCheck
                      : newNominee.nominationDocumentCheck,
                    edited:
                      application?.hasPOA &&
                      values.nominees.filter((item) => item.isActive).length === 0
                        ? false
                        : nominee.edited,
                  })),
                });
                checked && setBulkNomineeDelete(true);
                // !checked &&
                //   setOpen({
                //     index: values.nominees.length,
                //   });
                // !checked &&
                //   setAddNewNomineeData({
                //     ...addNewNomineeData,
                //     nominees: values.nominees,
                //     sno: values.nominees.length,
                //   });
              }}
              checked={values.doNotWishToNominate}
            />
          </Grid>
          {/* <Dialog
            onClose={() => onClose()}
            open={open ? true : false}
            sx={{
              '.MuiPaper-root ': {
                px: 1,
                maxWidth: 900,
              },
            }}>
            <Formik
              initialValues={addNewNomineeData}
              onSubmit={FetchKRA}
              enableReinitialize={true}
              validate={(newValues: AddNewNominee) => {
                try {
                  if (open) {
                    validateYupSchema(
                      newValues,
                      addNomineeDetailsSchema(open?.index, application?.applicants),
                      true,
                      newValues
                    );
                  }
                } catch (e) {
                  return yupToFormErrors(e);
                }
              }}>
              {({ handleSubmit, setFieldValue, values }) => (
                <Grid
                  container
                  rowSpacing={1}
                  // columnSpacing={5}
                  sx={{
                    width: '100%',
                    ml: 0,
                    '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                  }}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                      position: 'sticky',
                      top: 0,
                      boxShadow: '0 2px #ccc 0.6',
                      zIndex: 10,
                      bgcolor: 'white',
                      py: 3,
                    }}>
                    <Typography
                      sx={{
                        color: '#0D2762',
                        fontSize: '20px',
                        fontWeight: 500,
                        ml: { xs: 2, sm: 5 },
                      }}>
                      Nominee
                    </Typography>
                    <IconButton onClick={() => onClose()}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: '100%', sm: '90%' },
                      mx: 'auto',
                      px: { xs: 3, sm: 0 },
                      pb: 4,
                    }}>
                    <Grid
                      container
                      rowSpacing={1}
                      sx={{
                        width: '100%',
                        ml: 0,
                        '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                        mt: 1,
                      }}>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label={'Date of Birth*'}
                          inputLabelStyles={{
                            transform: 'unset',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)',
                          }}
                          placeholder={'DD/MM/YYYY'}
                          name={`dateOfBirth`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MFTextField
                          name={`nomineePan`}
                          label={`PAN Number ${isMinor(values?.dateOfBirth || '') ? `` : `*`}`}
                          placeholder="Enter PAN Number"
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                          onChange={(e) => {
                            setFieldValue(
                              `nomineePan`,
                              removeSingleQuote(e.target.value.toUpperCase())
                            );
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        display="flex"
                        sx={{
                          width: '100%',
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: 'center',
                        }}>
                        <LoadingButton
                          loadingPosition="start"
                          type="submit"
                          loading={kraFetchData}
                          variant="contained"
                          sx={{
                            fontSize: 15,
                            minWidth: 180,
                            letterSpacing: 2,
                            fontWeight: 500,
                            alignSelf: 'center',
                            my: 1,
                            mx: { xs: 0, sm: 0 },
                          }}
                          onClick={() => {
                            if (isMinor(values?.dateOfBirth || '') && !values.nomineePan) {
                              setData({
                                ...newNominee,
                                dateOfBirth: values?.dateOfBirth,
                                sno: values?.sno || nomineesData.nominees?.length,
                              });
                            } else handleSubmit();
                          }}>
                          {isMinor(values?.dateOfBirth || '') ? `Proceed` : `Fetch Data`}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Formik>
          </Dialog> */}
          {values.nominees
            .filter((_nominee) => _nominee.isActive)
            .map((nominee, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}>
                  {getApplicantName(index + 1, true)} Nominee Details
                  {!isFieldDisabledForPOALogin && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={() =>
                        !loading &&
                        !kraFetchData &&
                        setNomineeToDelete({ nominee, index: nominee.sno as number })
                      }>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          cursor: 'pointer',
                        }}>
                        Remove Nominee{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <Nominee
                  nomineeName={index + 1}
                  index={nominee.sno as number}
                  key={nominee.sno as number}
                  values={values}
                  // setAddNewNomineeData={(load: AddNewNominee) => {
                  //   setOpen({ index: load.sno });
                  //   setAddNewNomineeData(load);
                  // }}
                  setKraFetchData={(load: boolean) => {
                    setKraFetchData(load);
                  }}
                  kraFetchData={kraFetchData || isFieldDisabledForPOALogin}
                  hasPOA={application?.hasPOA || false}
                />
              </React.Fragment>
            ))}
          {values.nominees.filter((item) => item.isActive).length < 3 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                disabled={loading || kraFetchData || isFieldDisabledForPOALogin}
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                onClick={() => {
                  setValues({
                    ...values,
                    nominees: [
                      ...values.nominees,
                      {
                        ...newNominee,
                        nomineePercentage: values.nominees.filter((item) => item.isActive).length
                          ? 0
                          : 100,
                        sno: values.nominees.length,
                        nominationDocumentCheck: application?.hasPOA
                          ? values.nominees.filter((item) => item.isActive).length === 0
                            ? true
                            : values.nominees.filter((item) => item.isActive)[0]
                                .nominationDocumentCheck
                          : newNominee.nominationDocumentCheck,
                        edited: application?.hasPOA
                          ? values.nominees.filter((item) => item.isActive).length === 0
                            ? false
                            : !values.nominees.filter((item) => item.isActive)[0]
                                .nominationDocumentCheck
                          : newNominee.edited,
                      },
                    ],
                    doNotWishToNominate: false,
                  });
                  // setOpen({
                  //   index: values.nominees.length,
                  // });
                  // setAddNewNomineeData({
                  //   ...addNewNomineeData,
                  //   sno: values.nominees.length,
                  //   nominees: values.nominees,
                  // });
                }}>
                Add Nominee
              </Button>
            </Grid>
          )}
          <ProceedSaveLater
            disabled={kraFetchData}
            saveLater={() => {
              // isEdited(values);
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee.Relationship,
                })),
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              // isEdited(values);
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee?.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee?.Relationship,
                })),
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete nominees ?'}
            open={nomineeToDelete !== null || bulkNomineeDelete !== false}
            setOpen={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedApplicants = values.nominees.map((nominee, i) => {
                  if (nominee.sno === nomineeToDelete?.index || bulkNomineeDelete) {
                    return {
                      ...nominee,
                      isActive: false,
                      edited: true,
                    };
                  }
                  return nominee;
                });
                setValues({
                  ...values,
                  nominees: remainedApplicants,
                  doNotWishToNominate:
                    remainedApplicants.filter((item) => item.isActive).length === 0
                      ? true
                      : values.doNotWishToNominate,
                });
                setBulkNomineeDelete(false);
                setNomineeToDelete(null);
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
