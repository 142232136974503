export const riskProfileDeclaration =
  'I here by declare that the information provided by me in this form is true, correct and complete to the best of my knowledge and I undertake to inform you of any changes there in, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it.';
export const riskProfileDonotWish =
  'I do not wish to undergo the RISK Profiling and want to use the platform of products and services as execution only platform, whereby all investment decisions would be my sole discretion and decision';

export const investorProfileDeclaration =
  'I confirm that all the answers and information given in the questionnaire is true and complete.';

export const investorVerificationDeclaration = `I hereby authorize Kotak Investment Advisors Limited ("KIAL") to verify any information mentioned above and undertake to immediately
  inform KIAL of any changes therein. In case any of the above answer is found to be false, untrue, or misrepresenting, I am are aware that I may
  be held liable for it, and hereby agree to indemnify Kotak Investment Advisors Limited from any liability arising therefrom.`;

export const investorProfileDeclarationNonIndividual =
  'We confirm that all the answers and information given in the questionnaire is true and complete.';

export const investorVerificationDeclarationNonIndividual = `We hereby authorize Kotak Investment Advisors Limited ("KIAL") to verify any information mentioned above and undertake to immediately
inform KIAL of any changes therein. In case any of the above answer is found to be false, untrue, or misrepresenting, we are aware that we may
be held liable for it, and hereby agree to indemnify Kotak Investment Advisors Limited from any liability arising therefrom.`;
