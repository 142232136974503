import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ApplicationProps, ubo, uboTypes } from '../../redux-store/types/api-types';
import { Item, ItemDetails } from '../ApplicationDetails';
import { SubHeading } from '../investors/components';
import { MobileViewDataRow } from '../DataTable';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { getUboTypes } from '../../redux-store/actions/application';
import { useDispatch } from 'react-redux';
import { AmlDetailsFields } from '../commonComponents';
import { occupationDetailsMasters } from '../../utils/constant';

const headers = [
  {
    header: 'Name of UBO',
    label: 'name',
    valueGetter: (row: ubo) => row.name || 'N/A',
  },
  {
    header: 'Country Of Tax Residency',
    valueGetter: (row: ubo) => row.countryOfTaxResidency || 'N/A',
  },
  {
    header: 'Taxpayer ID Number/PAN/Equivalent ID Number',
    valueGetter: (row: ubo) => row.panNumber.toUpperCase() || 'N/A',
  },
  {
    header: 'Identification Type',
    valueGetter: (row: ubo) => row.identificationType || 'N/A',
  },
  {
    header: 'Percentage Of Beneficial Interest',
    valueGetter: (row: ubo) => row.percentageOfBeneficialInterest || 'N/A',
  },
  {
    header: 'CP/UBO Code',
    valueGetter: (row: ubo) => row.cpUboCode || 'N/A',
  },
  {
    header: 'Place & Country Of Birth',
    valueGetter: (row: ubo) => row.placeAndCountryOfBirth || 'N/A',
  },
  {
    header: 'Date of Birth/Incorporation',
    valueGetter: (row: ubo) => (row.dob ? formatShortDate(new Date(row.dob)) : 'N/A'),
  },
  {
    header: 'Pincode',
    valueGetter: (row: ubo) => row.pincode || 'N/A',
  },
  {
    header: 'Address Line 1',
    valueGetter: (row: ubo) => row.address2 || 'N/A',
  },
  {
    header: 'Address Line 2',
    valueGetter: (row: ubo) => row.address3 || 'N/A',
  },
  {
    header: 'State',
    valueGetter: (row: ubo) => row.state || 'N/A',
  },
  {
    header: 'City',
    valueGetter: (row: ubo) => row.city || 'N/A',
  },
  {
    header: 'Country',
    valueGetter: (row: ubo) => row.country || 'N/A',
  },
  {
    header: 'Gender',
    valueGetter: (row: ubo) => row.gender || 'N/A',
  },
  {
    header: 'Nationality',
    valueGetter: (row: ubo) => row.nationality || 'N/A',
  },
  {
    header: 'Father’s Name',
    valueGetter: (row: ubo) => row.fatherName || 'N/A',
  },
  {
    header: 'CKYC Number',
    valueGetter: (row: ubo) => row.ckycNumber || 'N/A',
  },
  {
    header: 'Occupation',
    valueGetter: (row: ubo) =>
      row.otherOccupation ? row.otherOccupation : occupationDetailsMasters[row.occupation] || 'N/A',
  },
];

const getUboDeclarationType = (uboTypesMdms: uboTypes, ubo_declaration_type: string) => {
  return uboTypesMdms.uboType
    .map((type) => {
      if (type.key === ubo_declaration_type) {
        return type.displayText;
      }
    })
    .filter((ele) => ele)
    .toString();
};

const getUboDeclarationValue = (
  uboTypesMdms: uboTypes,
  ubo_declaration_type: string,
  ubo_declaration_value: string
) => {
  const declarationValue = uboTypesMdms.uboType
    .map((type) => {
      if (type.key === ubo_declaration_type && type.displayOthers) {
        return type.values
          .map((val) => {
            if (val.key === ubo_declaration_value) {
              return val.label;
            }
          })
          .filter((ele) => ele)
          .toString();
      }
    })
    .filter((ele) => ele)
    .toString();
  return declarationValue ? declarationValue : ubo_declaration_value;
};

export default function Ubo({ application }: { application: ApplicationProps }): JSX.Element {
  const {
    ubos,
    applicants = [],
    ubo_declaration_type = '',
    ubo_declaration_value = '',
  } = application || {};
  const [uboTypesMdms, setUboTypesMdms] = useState<uboTypes>();
  const dispatch = useDispatch();
  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getUboTypes())) as unknown as uboTypes;
        setUboTypesMdms(response);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Declaration of UBO
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{
                marginTop: '40px',
              }}>
              <KeyValue title={'Entity Name'} description={applicants[0].name} />
              <KeyValue title={'PAN'} description={applicants[0].panNo} />
            </Grid>
            <Item
              sx={{
                marginTop: '40px',
                marginBottom: '5px',
              }}>
              {'We hereby declare that:'}
            </Item>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'baseline',
                flexDirection: { xs: 'column', sm: 'row' },
              }}>
              {uboTypesMdms && (
                <>
                  <Item sx={{ pr: 1 }}>
                    {getUboDeclarationType(uboTypesMdms, ubo_declaration_type) ===
                    'None of the above'
                      ? 'Our company is a '
                      : getUboDeclarationType(uboTypesMdms, ubo_declaration_type)}
                  </Item>
                  <ItemDetails>
                    {getUboDeclarationValue(
                      uboTypesMdms,
                      ubo_declaration_type,
                      ubo_declaration_value
                    )}
                  </ItemDetails>
                </>
                // <KeyValue
                //   title={getUboDeclarationType(uboTypesMdms, ubo_declaration_type)}
                //   description={ubo_declaration_value}
                // />
              )}
            </Box>
            <SubHeading
              sx={{
                color: 'common.black',
                letterSpacing: 0.5 + 'px',
                padding: { xs: '10px 10px', sm: '10px 30px' },
              }}>
              {'List of ultimate benificiary owner'}
            </SubHeading>
            {ubos.length === 0 && (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No List of ultimate benificiary owner
              </Grid>
            )}
            {ubos.map((ubo, index) => (
              <React.Fragment key={ubo.id}>
                <Item
                  sx={{
                    marginBottom: '20px',
                    paddingLeft: { xs: '10px', sm: '30px' },
                  }}>{`Ultimate Beneficiary Owner ${index + 1}`}</Item>
                {!ubo.amlCheck && (
                  <Grid container sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'primary.main',
                        paddingLeft: { xs: '10px', sm: '30px' },
                      }}>
                      Aml Check Details
                    </Typography>
                    <AmlDetailsFields
                      amlCheck={ubo.amlCheck}
                      amlCommentedRole={ubo.amlCommentedRole}
                      amlComments={ubo.amlComments}
                    />
                  </Grid>
                )}
                <MobileViewDataRow index={index} row={ubo} key={ubo.id} tableHeader={headers} />
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
