import {
  Box,
  Button,
  CardMedia,
  Grid,
  Badge,
  IconButton,
  Pagination,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Menu,
} from '@mui/material';
import { CommonLayout } from '../commonComponents';
import { SubHeading } from './components';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import { ChangeEvent, useEffect, useState } from 'react';
import { getAllApplications, getStampPaperCount } from '../../redux-store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import { FilterDropdown, initialFilters } from './filters';
import { Distributor, getDistributorsListForFilters } from '../../redux-store/actions';
import { ApplicationFilterType } from '../../redux-store/types/api-types';
import {
  ApplicationProps,
  StampPapersType,
  stampPaperProp,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import React from 'react';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { USER_ROLES } from '../../utils/constant';
import { useDebounce } from '../../utils/useDebounce';
import DashboardAndApplicationsList from '../dashboard/applicationslist';
import { Formik } from 'formik';
import MFCheckbox from '../../lib/formik/Checkbox';

interface Values {
  pan: string;
}
const FilterMenu = (): JSX.Element => {
  const initialValues1: Values = { pan: '' };
  const handleSubmit = (values: Values) => {
    console.log(values);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Formik initialValues={initialValues1} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <IconButton
            sx={{ p: 0 }}
            id="filter-button"
            aria-controls="filter-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <CardMedia component="img" src="/images/filter-icon.svg" alt="Filter" />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'filter-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '.MuiPaper-root': { p: 2, pt: 1 },
              '.MuiTypography-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                '&.MuiTypography-body2': { color: 'primary.main', mb: 1 },
              },
              '.MuiBox-root:not(:last-of-type)': {
                position: 'relative',
                mr: 5,
                '::after': {
                  content: '""',
                  position: 'absolute',
                  top: 10,
                  right: -15,
                  width: '2px',
                  height: '100%',
                  background:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.2),rgba(255, 255, 255, 0) )',
                },
              },
            }}>
            <Stack direction="row">
              <Box>
                <Typography variant="body2">Mode of holding</Typography>
                <MFCheckbox name="single" label="Single" />
                <MFCheckbox label="joint" name="Joint" />
              </Box>
              <Box>
                <Typography variant="body2">Status</Typography>
                <MFCheckbox name="Draft" label="Draft" />
                <MFCheckbox name="in-process" label="In Process" />
                <MFCheckbox name="completed" label="Completed" />
              </Box>
              <Box>
                <Typography variant="body2">Distributor Name</Typography>
                <MFCheckbox name="distributor-name1" label="Distributor Name 1" />
                <MFCheckbox name="distributor-name2" label="Distributor Name 2" />
              </Box>
            </Stack>
          </Menu>
        </Box>
      )}
    </Formik>
  );
};

export default function Investors(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [applicationsList, setApplicationsList] = useState<ApplicationProps[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const [stampCount, setStampCount] = useState<stampPaperProp[]>([]);
  const {
    page,
    search: storeSearch,
    status: storeStatus,
    applicationType: storeApplicationType,
    modeOfHolding: storeModeOfHolding,
    distributorId: storeDistributorId,
    applicationSentBack,
    schemeId: storeSchemeId,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [currentPage, setPage] = useState(page);
  const [open, setOpen] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const [filters, setFilters] = useState<ApplicationFilterType>(
    initialFilters(
      role,
      applicationSentBack ? storeStatus.replace('draft', 'draft_send_back') : storeStatus,
      storeApplicationType,
      storeModeOfHolding,
      storeDistributorId,
      storeSchemeId
    )
  );
  const { applicationType, distributorId, status, modeOfHolding, schemeId } = filters;
  const [search, setSearch] = useState(storeSearch);
  const debounceSearchString = useDebounce(search, 500);
  const [distributorLoading, setDistributorLoading] = useState(false);

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllApplications({
            page: currentPage,
            search,
            applicationType: applicationType.toString(),
            distributorId: distributorId.toString(),
            status: status
              .toString()
              .replace('draft_send_back', 'draft')
              .replace('draft,draft', 'draft'),
            modeOfHolding: modeOfHolding.toString(),
            applicationSentBack:
              status.includes('draft_send_back') && !status.includes('draft') ? true : null,
            schemeId: schemeId.toString(),
          })
        )) as unknown as GetAllApplicantionsResponseBody;
        const { applications, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setApplicationsList(applications);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
        setLoading(false);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        const stamPaper = (await dispatch(getStampPaperCount())) as unknown as StampPapersType;
        const { series } = stamPaper || {};
        if (!componentIsAlive) {
          return;
        }
        setStampCount(series);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFiltersAnchorEl = async () => {
    try {
      setFiltersAnchorEl(true);
      setDistributorLoading(true);
      const distributorList = (await dispatch(
        getDistributorsListForFilters()
      )) as unknown as Distributor[];
      setDistributors(distributorList);
      setDistributorLoading(false);
    } catch (e) {
      console.error((e as Error).message);
      setDistributorLoading(false);
    }
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['applicationType', 'modeOfHolding', 'status', 'distributorId'].filter(
      (filterKey) => (filters[filterKey] as string[]).length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            distributors={distributors}
            loader={distributorLoading}
          />
        )}
      </>
    );
  };

  return (
    <CommonLayout>
      <>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 18,
              fontWeight: 500,
              mb: { xs: 2, md: 4 },
              p: { xs: '5px 15px', md: '' },
              bgcolor: '#e5f1f8',
              borderRadius: '10px',
            }}>
            All Applications
          </Typography>
          {[USER_ROLES.AMC_ADMIN, USER_ROLES.RM, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Box
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                borderRadius: '10px',
                width: 'unset',
              }}
              mb={2}>
              <Box pb={3}>
                <Grid container sx={{ pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                  <SubHeading
                    sx={{
                      display: 'flex',
                      mb: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    Stamp Papers Information
                  </SubHeading>
                  {stampCount.map((action, index) => (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      justifyContent="space-between"
                      sx={{ pl: { xs: 0, sm: 10 }, pr: { xs: 0, sm: 10 } }}
                      alignItems="center"
                      pt={3}>
                      {/* <Typography
                        key={index}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'text.secondary',
                        }}>
                        Serial Number: {action.seriesNumber}
                      </Typography> */}
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'text.secondary',
                        }}>
                        Denomination: {action.denomination}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'text.secondary',
                          '&.Draft .MuiTypography-root': {
                            color: 'rgba(51, 127, 201, 0.7)',
                          },
                          '&.Completed .MuiTypography-root': {
                            fontWeight: 600,
                            color: 'rgba(35, 133, 63, 0.7)',
                          },
                          '&.Rejected .MuiTypography-root': {
                            color: '#BD2932',
                            fontWeight: 500,
                          },
                        }}>
                        Unused Stamp Count: {action.unused}
                        <Typography
                          component="span"
                          sx={{
                            color: '#B78813',
                            fontSize: '16px',
                            letterSpacing: '0.4px',
                            ml: 0.5,
                          }}></Typography>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
          <Grid
            container
            sx={{
              p: '20px 30px',
              pb: 0,
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              bgcolor: 'common.white',
              mb: 1.5,
            }}>
            <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
              <Grid item>
                <FormControl variant="standard" sx={{ display: 'block' }}>
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                    Search Application
                  </InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    value={search}
                    onChange={({ target: { value } }) => {
                      setPage(1);
                      setSearch(value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchSharpIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              {/* <Grid item sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.5 }}> */}
              {/* <Typography sx={{ fontSize: 14, fontWeight: 600, color: 'primary.main', pr: 1 }}>
                Filters :
              </Typography> 
               <Stack direction="row" gap={1} flexWrap="wrap">
                <Chip
                  label="Single"
                  size="small"
                  onDelete={() => console.log('chip deleted')}
                  sx={{
                    bgcolor: 'rgba(41, 49, 57, 0.05)',
                    //  '& .MuiChip-label': { lineHeight: 1 }
                  }}
                />
              </Stack> */}
              {/* </Grid> */}
            </Grid>
            <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    mr: 2.5,
                    p: 1,
                  }}
                  onClick={() => history.push('/onboarding')}>
                  Add Applicant
                </Button>
                {filterOptions()}
              </Grid>
              <Grid item>{/* <FilterMenu /> */}</Grid>
            </Grid>
          </Grid>
          <Box>
            <DashboardAndApplicationsList
              isLoading={isLoading}
              applicationsList={applicationsList}
              boxStyles={{ minWidth: 650 }}
            />
          </Box>

          {!!applicationsList.length && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
              <Pagination
                count={noOfPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </Box>
      </>
    </CommonLayout>
  );
}
