import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Hidden, Grid, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik } from 'formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { AddFundManagerRequestBody, FundManager } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addFundManager,
  getFundManagersById,
  getIMAccess,
  IMAccess,
  UpdateFundManager,
} from '../../redux-store/actions/userManagement';
import { addFundManagerSchema } from '../../utils/schema';
import { Location } from 'history';
import { CountryCodesDropDown } from '../commonComponents';
import MFSelectField from '../../lib/formik/SelectField';
import { COMPANIES_MASTER_FORMETED_OBJECT, initialComment } from '../../utils/constant';
import { NavigationThrowPopup } from '../UsersFundsVerification/userFundscommonComponents';
import { showError } from '../../redux-store/actions/auth';
import { applicationComparison } from '../../utils/utilityFunctions';
import { noUpdationMsg } from './inviteRm';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: AddFundManagerRequestBody = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  leegalityName: '',
  isActive: true,
  companyName: '',
  comment: '',
};

export default function AddFundManager({
  location,
}: {
  location: Location<{ fundManagerId: number }>;
}): JSX.Element {
  const { fundManagerId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [formikValues, setFormikValues] = useState<AddFundManagerRequestBody | null>();

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (!fundManager) {
  //         await dispatch(addFundManager(formikValues));
  //       } else {
  //         await dispatch(UpdateFundManager(id, formikValues));
  //       }

  //       if (!isComponentActive) return;
  //       history.push('invest-managers');
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const [fundManagerDetails, setFundManagerDetails] = useState({
    ...initialValues,
    checkId: fundManagerId,
  });
  const [loading, setLoading] = useState(false);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);
  const [imDetailsForComparision, setImDetailsForComparision] = useState({
    ...initialValues,
    checkId: fundManagerId,
  });

  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        if (fundManagerId) {
          const getFundManagerDetails = (await dispatch(
            getFundManagersById(fundManagerId)
          )) as unknown as FundManager;
          const {
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            isActive,
            comment,
          } = getFundManagerDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
            companyName,
          } = getFundManagerDetails.user;
          const imObj = {
            ...fundManagerDetails,
            name: name || fundManagerDetails.name,
            pincode: pincode || fundManagerDetails.pincode,
            buildingNo: buildingNo || fundManagerDetails.buildingNo,
            streetName: streetName || fundManagerDetails.streetName,
            city: city || fundManagerDetails.city,
            state: state || fundManagerDetails.city,
            country: country || fundManagerDetails.country,
            panNumber: panNumber || fundManagerDetails.panNumber,
            id,
            phone: phone || fundManagerDetails.phone,
            email: email || fundManagerDetails.email,
            leegalityName: leegalityName || fundManagerDetails.leegalityName,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            companyName: companyName || fundManagerDetails.companyName,
          };
          setFundManagerDetails({ ...imObj, comment: comment || '' });
          setImDetailsForComparision(imObj);
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(fundManagerAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddFundManagerRequestBody) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment, ...rest } = values;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment: comparedComment, ...restComparedData } = imDetailsForComparision;
      const checkImData = applicationComparison(restComparedData, {
        ...rest,
        countryCode: values.countryNameAndCode?.split(':')[1].trim(),
      });
      if (checkImData) {
        throw noUpdationMsg;
      }
      if (!fundManagerId) {
        const res = (await dispatch(
          addFundManager({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            comment: initialComment,
          })
        )) as any;
        if (res.message) {
          setRouteDialogOpen({
            message: res.message,
            open: true,
          });
        }
      } else {
        const result = (await dispatch(
          UpdateFundManager(fundManagerId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        )) as any;
        if (result.message) {
          setRouteDialogOpen({
            message: result.message,
            open: true,
          });
        }
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={fundManagerDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addFundManagerSchema}>
        {({ handleSubmit, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    fundManagerId
                      ? history.push('Invest-manager-details', { fundManagerId: values.id })
                      : history.push('invest-managers')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    fundManagerId
                      ? history.push('Invest-manager-details', { fundManagerId: values.id })
                      : history.push('invest-managers')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {fundManagerId ? 'Investment Managers' : 'Onboarding Investment Managers'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name (Individual) *"
                      placeholder="Enter Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="leegalityName"
                      label="Leegality Name *"
                      placeholder="Enter Leegality Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      disabled={!accessForEditAdd}
                      regexForFilterValue={nonDigitRegex}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="pincode"
                      label="Pincode"
                      placeholder="Enter Pincode"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="state"
                      label="State"
                      placeholder="Enter State"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="city"
                      label="City"
                      placeholder="Enter City"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name={`companyName`}
                      label={'Company Name *'}
                      items={COMPANIES_MASTER_FORMETED_OBJECT}
                      applyLabelStyles={true}
                    />
                  </Grid>
                  {values.checkId && (
                    <Grid item xs={12}>
                      <Grid item xs={12} sm={6} md={8}>
                        <MFTextField
                          name="comment"
                          label={'Comment(s) *'}
                          multiline
                          rows={4}
                          placeholder="Type your answer here..."
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
            <NavigationThrowPopup
              handleClose={handleRoutePopupClose}
              dialogOpen={routeDialogOpen}
              onSave={() => {
                // handleErrorPopupClose();
                history.push(!fundManagerId ? `invest-managers` : 'Invest-manager-details', {
                  fundManagerId,
                });
              }}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
