import { Box, Typography, IconButton, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { poaApprover, AddPoaApproverRequestBody } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { CountryCodesDropDown } from '../commonComponents';
import {
  addPOAAppprover,
  updatePOAApprover,
  getPoaApproverById,
} from '../../redux-store/actions/userManagement';
import { AuthorisedSignatoryAndPOASchema } from '../../utils/schema';
import { Location } from 'history';
import { CommonFormForCompanyDistributors } from './addAuthorisedSignatory';
import { useEffect, useState } from 'react';
import { NavigationThrowPopup } from '../UsersFundsVerification/userFundscommonComponents';
import { initialComment } from '../../utils/constant';
import { showError } from '../../redux-store/actions/auth';
import { applicationComparison } from '../../utils/utilityFunctions';
import { noUpdationMsg } from './inviteRm';

export default function AddPOAApprover({
  location,
}: {
  location: Location<{ poaApproverId: number; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { poaApproverId, distributorId: ID } = location.state || {};
  const [formikValues, setFormikValues] = useState<AddPoaApproverRequestBody | null>();

  const initialValues: AddPoaApproverRequestBody = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    distributorId: ID,
    amcId: '',
    isActive: true,
    companyName: '',
    comment: '',
  };
  const [poaApproverDetails, setPoaApproverDetailsDetails] = useState({
    ...initialValues,
    checkId: poaApproverId,
  });
  const [loading, setLoading] = useState(false);
  const [poaApproverDetailsForComparision, setPoaApproverDetailsForComparision] = useState({
    ...initialValues,
    checkId: poaApproverId,
  });

  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (poaApproverId) {
          const getPoaApproverDetails = (await dispatch(
            getPoaApproverById(poaApproverId)
          )) as unknown as poaApprover;
          const {
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            isActive,
            comment,
          } = getPoaApproverDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
            companyName,
          } = getPoaApproverDetails.user;
          const poaApproverObj = {
            ...poaApproverDetails,
            name: name || poaApproverDetails.name,
            panNumber: panNumber || poaApproverDetails.panNumber,
            pincode: pincode || poaApproverDetails.pincode,
            buildingNo: buildingNo || poaApproverDetails.buildingNo,
            streetName: streetName || poaApproverDetails.streetName,
            city: city || poaApproverDetails.city,
            state: state || poaApproverDetails.state,
            country: country || poaApproverDetails.country,
            distributorId,
            amcId,
            id,
            phone: phone || poaApproverDetails.phone,
            email: email || poaApproverDetails.email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            companyName: companyName || poaApproverDetails.companyName,
          };
          setPoaApproverDetailsDetails({
            ...poaApproverObj,
            comment: comment || '',
          });
          setPoaApproverDetailsForComparision(poaApproverObj);
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (poaApprover) {
  //         await dispatch(updatePOAApprover(id, formikValues));
  //       } else {
  //         await dispatch(addPOAAppprover({ ...formikValues, distributorId: ID }));
  //       }
  //       if (!isComponentActive) return;
  //       history.push('poa-approver', { distributorId: ID });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const onSubmit = async (values: AddPoaApproverRequestBody) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment, ...rest } = values;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { comment: comparedComment, ...restComparedData } = poaApproverDetailsForComparision;
      const checkPoaApproverData = applicationComparison(restComparedData, {
        ...rest,
        countryCode: values.countryNameAndCode?.split(':')[1].trim(),
      });
      if (checkPoaApproverData) {
        throw noUpdationMsg;
      }
      if (poaApproverId) {
        const result = (await dispatch(
          updatePOAApprover(poaApproverId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        )) as any;
        if (result.message) {
          setRouteDialogOpen({
            message: result.message,
            open: true,
          });
        }
      } else {
        const res = (await dispatch(
          addPOAAppprover({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            distributorId: ID,
            comment: initialComment,
          })
        )) as any;
        if (res.message) {
          setRouteDialogOpen({
            message: res.message,
            open: true,
          });
        }
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={poaApproverDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={AuthorisedSignatoryAndPOASchema}>
        {({ handleSubmit, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    poaApproverId
                      ? history.push('poa-approver-details', {
                          poaApproverId: values.id,
                          distributorId: values.distributorId,
                        })
                      : history.push('poa-approver', { distributorId: values.distributorId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    poaApproverId
                      ? history.push('poa-approver-details', {
                          poaApproverId: values.id,
                          distributorId: values.distributorId,
                        })
                      : history.push('poa-approver', { distributorId: values.distributorId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {poaApproverId ? 'POA Approver' : 'Onboarding POA Approver'}
              </Typography>
            </Box>
            {loading ? <LinearProgress /> : <CommonFormForCompanyDistributors values={values} />}
            <NavigationThrowPopup
              handleClose={handleRoutePopupClose}
              dialogOpen={routeDialogOpen}
              onSave={() => {
                // handleErrorPopupClose();
                poaApproverId
                  ? history.push('poa-approver-details', {
                      poaApproverId,
                      distributorId: values.distributorId.toString(),
                    })
                  : history.push('poa-approver', {
                      distributorId: values.distributorId.toString(),
                    });
              }}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
