import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Applicant,
  ApplicationProps,
  profileType,
  RiskProfile,
  RiskProfileMaster,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import { changeGoalVal, currencyConversion, getApplicantName } from '../../utils/utilityFunctions';

import { riskProfileMasterData } from '../../redux-store/actions';
import { riskProfileDeclaration, riskProfileDonotWish } from '../../utils/declaration';
import { PieChart } from 'react-minimal-pie-chart';
import { DataTable } from '../DataTable';
import { SubHeading } from '../investors/components';
import { KeyValue } from './contributor';
import { formatShortDate } from '../../utils/date';
import { Gridstyles, Item, ItemDetails } from '.';
import {
  handleIntialSelect,
  profiletext,
  riskQuetionnaireObject,
} from '../investors/riskProfileDetails';

export type updatedRiskProfile = RiskProfile &
  RiskProfileMasterType & {
    otherValue?: string;
    InvestmentGoal?: string[];
    scoreCal?: number;
  };
export type updatedSectionType = updatedRiskProfile & {
  objective?: string;
  investmentAmount?: string;
};
export type updateApplicant = {
  riskprofiles: updatedRiskProfile[];
  riskQuetionnaire: updatedSectionType[];
} & Partial<Applicant>;

export type Values = {
  applicants: updateApplicant[];
  saveType: string;
  doNotWishToFillRiskProfile: boolean;
};
export type InvestmentHorizonAndGoalType = {
  investmentHorizon?: string;
  objective?: string;
  investmentAmount?: string;
};

const newInvestmentHorizonAndGoal: InvestmentHorizonAndGoalType = {
  investmentHorizon: '',
  objective: '',
  investmentAmount: '',
};
export const RiskProfileObj: updatedRiskProfile = {
  question: '',
  answer: '',
  scoreText: '',
  otherValue: '',
  key: '',
  displayText: '',
  InvestmentGoal: [],
  scoreCal: 0,
};

const initialValues: Values = {
  applicants: [
    {
      riskDocumentCheck: false,
      wishToAddRiskProfile: false,
      riskProfileDeclaration: false,
      totalRiskProfileScore: 0,
      riskprofiles: [],
      investmentHorizonAndGoal: [newInvestmentHorizonAndGoal],
      riskQuetionnaire: [riskQuetionnaireObject],
    },
  ],
  saveType: 'save and proceed',
  doNotWishToFillRiskProfile: false,
};

function RiskQuetionnaires({
  data,
  applicant,
  index,
}: {
  data: updatedRiskProfile[];
  applicant: updateApplicant;
  index: number;
}): JSX.Element {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography>Section 1: Demographics</Typography>
      <Gridstyles>
        <Grid
          item
          container
          pb={5}
          rowSpacing={6}
          columnSpacing={13}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <KeyValue
            title={'Name'}
            description={(applicant.namePrefix ? applicant.namePrefix + ' ' : '') + applicant.name}
          />
          <KeyValue title={'Date of birth'} description={formatShortDate(applicant.dateOfBirth)} />
        </Grid>
      </Gridstyles>
      {data.map((section, ind) => (
        <>
          <>
            <Typography>
              Section {ind + 2}:{` ${section.displayText}`}
            </Typography>
            <Gridstyles>
              {section?.questionType === 'radio' && (
                <Grid
                  item
                  container
                  pb={5}
                  rowSpacing={6}
                  columnSpacing={13}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <KeyValue
                    title={''}
                    description={
                      section.otherValue ||
                      section.values?.find((value) => value.key === section.answer)?.label
                    }
                  />
                </Grid>
              )}
              {section?.questionType === 'radioTable' && (
                <InvestmentHorizonAndGoalTable values={applicant} ind={index} />
              )}
            </Gridstyles>
          </>
        </>
      ))}
    </Box>
  );
}

export function InvestmentHorizonAndGoalTable({
  values,
  ind,
}: {
  values: updateApplicant;
  ind: number;
}): JSX.Element {
  const headers = [
    {
      header: 'Investment Horizon And Goal',
      label: 'investmentHorizonAndGoal',
      valueGetter: (row: any) => row.label || '-',
    },
    {
      header: 'Objective',
      label: 'objective',
      valueGetter: (row: any) => row.objective || '-',
    },
    {
      header: 'Amount',
      label: 'investmentAmount',
      renderCell: (row: any) =>
        row.investmentAmount ? (
          <>
            <Item>{row.investmentAmount}</Item>
            <Item>{currencyConversion(row.investmentAmount)}</Item>
          </>
        ) : (
          <>-</>
        ),
    },
  ];

  return (
    <DataTable
      tableData={
        values?.riskQuetionnaire.find((risk) => risk.key === 'investmentHorizonAndGoalArray')
          ?.values
      }
      tableHeader={headers}
      renderAdditionalRow={false}
      tableHeaderCustomStyles={{
        '.MuiTableCell-head': {
          fontFamily: 'Poppins, sans-serif',
          fontSize: 14,
          fontWeight: 500,
          color: 'primary.main',
          textAlign: 'left',
          // pl: 2,
          '&:nth-of-type(1)': {
            //   pl: 6,
          },
        },
      }}
      rowCustomStyles={{
        '.MuiTableCell-root': {
          // py: 0,
          overflowWrap: 'anywhere',
          textAlign: 'left',
          verticalAlign: 'top',
        },
      }}
      boxStyles={{ minHeight: '0px' }}
    />
  );
}

export default function RiskProfiles({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const [riskProfileData, setRiskProfileData] = useState<Values>(initialValues);

  //   const { application } = useSelector((store: RootStateType) => store.application);

  const [riskProfileDataMaster, setRiskProfileDataMaster] = useState<RiskProfileMasterType[]>([]);
  const [profileData, setProfileData] = useState<profileType[]>([]);
  const [riskQuetionnaire, setRiskQuetionnaire] = useState<RiskProfileMasterType[]>([
    riskQuetionnaireObject,
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    const {
      applicants: exisitingApplicants = [],
      hasPOA = false,
      doNotWishToFillRiskProfile,
    } = application || {};
    (async function () {
      try {
        const response = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
        setRiskProfileDataMaster(response.riskProfile);
        setProfileData(response.profile);
        setRiskQuetionnaire(response.section);
        const applicants = exisitingApplicants.map((applicant) => {
          const {
            riskDocumentCheck,
            namePrefix,
            name,
            dateOfBirth,
            riskProfileDeclaration,
            wishToAddRiskProfile,
            totalRiskProfileScore = 0,
            investmentHorizon,
            objective,
            investmentAmount,
            investmentObjective = '',
          } = applicant;
          const defaultPayload = {
            riskDocumentCheck,
            namePrefix,
            name,
            dateOfBirth,
            riskProfileDeclaration,
            wishToAddRiskProfile,
            riskprofiles: response.riskProfile
              ?.map((mdmsProfile) => {
                const existingRiskProfiles = applicant?.riskprofiles?.filter(
                  (risk_profiles: any) => risk_profiles.question === mdmsProfile.key
                );
                const newrisk = existingRiskProfiles?.length
                  ? existingRiskProfiles?.map((existingProfiles: any) => {
                      if (
                        existingProfiles.question === mdmsProfile.key &&
                        mdmsProfile.isMultiselect === 'true'
                      ) {
                        const getInvestment = handleIntialSelect(
                          applicant?.riskprofiles as RiskProfile[],
                          mdmsProfile.key
                        );
                        const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                        let getOtherValue = '';
                        const updateOptions = getInvestment.map((ans) => {
                          if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                            getOtherValue = ans.split('_')[0];
                            return `others_${ans.split('_')[1]}`;
                          }
                          return ans;
                        });
                        return {
                          ...existingProfiles,
                          ...mdmsProfile,
                          values: mdmsProfile.values?.map((value) => {
                            const scoreUpdate = updateOptions
                              .find((investment) => investment?.split('_')[0] === value.key)
                              ?.split('_')[1];

                            return {
                              ...value,
                              score: scoreUpdate ? Number(scoreUpdate) : value.score,
                            };
                          }),
                          InvestmentGoal: updateOptions || [],
                          scoreCal: Number(existingProfiles.scoreText) || 0,
                          otherValue: getOtherValue,
                          answer: getOtherValue
                            ? updateOptions?.toString()?.replace(/,/g, '*')
                            : existingProfiles.answer,
                        };
                      } else {
                        const checkAnswer =
                          existingProfiles.question === mdmsProfile.key &&
                          mdmsProfile.isMultiselect === 'false' &&
                          mdmsProfile.values
                            ?.map((value) => value.key)
                            .includes(existingProfiles.answer);

                        return {
                          ...existingProfiles,
                          ...mdmsProfile,
                          values: mdmsProfile.values?.map((value) => {
                            if (existingProfiles.answer === value.key) {
                              return {
                                ...value,
                                score: Number(existingProfiles.scoreText),
                              };
                            }
                            return value;
                          }),
                          otherValue: checkAnswer ? '' : existingProfiles.answer,
                          answer: checkAnswer ? existingProfiles.answer : 'others',
                        };
                      }
                    })
                  : [
                      {
                        ...RiskProfileObj,
                        question: mdmsProfile.key,
                        answer: '',
                        scoreText: '',
                        ...mdmsProfile,
                      },
                    ];
                return newrisk.filter((risk) => risk.answer);
              })
              .flat(),
            totalRiskProfileScore,
            riskQuetionnaire: response.section.map((sec) => {
              const setAnswer = sec.values
                ?.map((value) => value.key)
                .includes(investmentObjective || '');
              return {
                ...riskQuetionnaireObject,
                ...sec,
                answer:
                  (sec.key === 'investmentObjective'
                    ? setAnswer
                      ? investmentObjective
                      : investmentObjective
                      ? 'others'
                      : ''
                    : sec.key === 'investmentHorizonAndGoalArray'
                    ? investmentHorizon
                    : '') || '',
                otherValue:
                  sec.key === 'investmentObjective' ? (setAnswer ? '' : investmentObjective) : '',
                values:
                  sec.key === 'investmentHorizonAndGoalArray'
                    ? sec?.values?.map((value) => {
                        const check = value.key === investmentHorizon;
                        return {
                          ...value,
                          isChecked: check,
                          objective:
                            check && sec.key === 'investmentHorizonAndGoalArray' ? objective : '',
                          investmentAmount:
                            check && sec.key === 'investmentHorizonAndGoalArray'
                              ? investmentAmount
                              : '',
                        };
                      })
                    : sec?.values,
              };
            }),
            investmentHorizonAndGoal: newInvestmentHorizonAndGoal,
          };
          return defaultPayload;
        });
        setRiskProfileData({
          ...riskProfileData,
          applicants: applicants,
          doNotWishToFillRiskProfile: doNotWishToFillRiskProfile,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);
  const manageTotal = (values: updateApplicant) => {
    const total = values.riskprofiles
      .map((risk) => {
        return Number(risk?.scoreText);
      })
      .reduce(function (acc, val) {
        return acc + val;
      }, 0);

    return total;
  };
  return (
    <>
      {!(
        application.doNotWishToFillRiskProfile ||
        riskProfileData.applicants.every((applicant) => applicant.riskDocumentCheck)
      ) ? (
        <Box
          sx={{
            bgcolor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
          }}
          mt={5}>
          <Box sx={{ p: 2 }}>
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Risk Profile
              </Typography>
              <React.Fragment>
                <Box sx={{ width: '100%' }}>
                  {riskProfileData.applicants.map((applicant, index) => {
                    return (
                      <Grid key={index}>
                        {/* {application?.hasPOA && index === 0 && applicant.riskDocumentCheck && (
                          <Gridstyles>
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                  mt: 2,
                                }}>
                                Wish to upload Risk Form
                              </Typography>
                            </Grid>
                          </Gridstyles>
                        )} */}
                        {!applicant.riskDocumentCheck && (
                          <>
                            <SubHeading
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              Risk Questionnaire -{getApplicantName(index + 1, true)} Holder
                            </SubHeading>

                            <RiskQuetionnaires
                              data={applicant.riskQuetionnaire}
                              applicant={applicant}
                              index={index}
                            />
                            <SubHeading
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              Risk Profile of {getApplicantName(index + 1, true)} Applicant
                            </SubHeading>
                            <Gridstyles>
                              <Grid
                                item
                                container
                                pb={5}
                                rowSpacing={6}
                                columnSpacing={13}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                                {applicant.riskprofiles.length ? (
                                  <>
                                    {applicant.riskprofiles?.map((profileData) => {
                                      const profileAns = changeGoalVal(
                                        profileData?.answer,
                                        applicant.riskprofiles,
                                        profileData?.question
                                      );
                                      return (
                                        <React.Fragment key={profileData?.id}>
                                          {profileData?.isMultiselect === 'false' ? (
                                            <KeyValue
                                              title={profileData.displayText}
                                              description={
                                                profileData.values?.find(
                                                  (value) => value.key === profileData.answer
                                                )?.label || profileData.answer
                                              }
                                            />
                                          ) : (
                                            <Grid item xs={12} sm={6}>
                                              <Item>{profileData?.displayText}</Item>
                                              {profileData?.key ===
                                              'realizingMarketBasedInvestments' ? (
                                                profileAns && profileAns.length > 0 ? (
                                                  profileAns?.map((ans, ind) => (
                                                    <Box
                                                      key={ind}
                                                      sx={{
                                                        display: 'flex',
                                                        alignItems: 'baseline',
                                                      }}>
                                                      <Typography
                                                        sx={{
                                                          fontSize: '30px',
                                                          fontWeight: 900,
                                                          pr: 1,
                                                        }}>
                                                        .
                                                      </Typography>
                                                      <ItemDetails> {ans}</ItemDetails>
                                                    </Box>
                                                  ))
                                                ) : (
                                                  <ItemDetails>-</ItemDetails>
                                                )
                                              ) : (
                                                <ItemDetails>
                                                  {' '}
                                                  {profileAns?.toString() || '-'}
                                                </ItemDetails>
                                              )}
                                            </Grid>
                                          )}
                                        </React.Fragment>
                                      );
                                    })}

                                    {applicant.totalRiskProfileScore ? (
                                      <>
                                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                                          <Typography
                                            sx={{
                                              color: 'black',
                                              fontWeight: 550,
                                              fontSize: 14,
                                            }}>{`Your total Score is ${manageTotal(
                                            applicant
                                          )}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                                          <Typography>
                                            {profiletext(
                                              Number(manageTotal(applicant)),
                                              profileData
                                            )}
                                          </Typography>
                                        </Grid>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {applicant?.riskProfileDeclaration ? (
                                      <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                                        <Typography
                                          sx={{
                                            fontSize: 15,
                                            fontWeight: 700,
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            letterSpacing: '0.5px',
                                          }}>
                                          {riskProfileDeclaration}
                                        </Typography>
                                      </Grid>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : applicant.wishToAddRiskProfile ? (
                                  <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                                    <Typography
                                      sx={{
                                        fontSize: 15,
                                        fontWeight: 700,
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        letterSpacing: '0.5px',
                                      }}>
                                      {riskProfileDonotWish}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    sx={{
                                      fontSize: 18,
                                      fontWeight: 600,
                                      color: 'common.black',
                                      textAlign: 'center',
                                      my: 4,
                                    }}>
                                    No Risk Profile
                                  </Grid>
                                )}
                              </Grid>
                            </Gridstyles>
                          </>
                        )}
                      </Grid>
                    );
                  })}
                </Box>
              </React.Fragment>
            </Grid>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
