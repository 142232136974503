/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography, Hidden, IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, RejectApproveButtons } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  ApproveComponent,
  ConfirmationDialogWithCommentsBox,
  RejectComponent,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';
import { Notes, SubHeading } from '../investors/components';
import {
  AdminMakerCheckerProps,
  getAdminCheckerApprove,
  getAdminCheckerReject,
  getAdminMakerCheckerUserDetails,
} from '../../redux-store/actions/userFundsVerification';
import { UserFundsMaster, yesAndNoTrueAndFalseMasters } from '../../utils/constant';
import { applicationComparison } from '../../utils/utilityFunctions';
import { FundFields } from '../FundsSetup/fundDetail';
import { ClassPlanProps, FundProps, accessType, addFundType } from '../../redux-store/types/funds';
import { PlanFields } from '../FundsSetup/planDetail';
import { formatShortDateAndTime } from '../../utils/date';
import { fundAccess } from '../../redux-store/actions/funds';
import { consentInfo } from './userDetails';

export default function SchemeDetails(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState<AdminMakerCheckerProps>();
  const { id } = useParams<{ id: string }>();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  const { path } = useRouteMatch();
  const [access, setAccess] = useState(false);

  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (id) {
          const getSchemeDetails = (await dispatch(
            getAdminMakerCheckerUserDetails(id)
          )) as unknown as AdminMakerCheckerProps;
          setSchemeDetails(getSchemeDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handleRejectPopupOpen = () => setOpenRejectPopup(true);
  const handleApprovePopupDialogOpen = () => setOpenApprovePopup(true);

  const handleRejectPopupClose = () => {
    setOpenRejectPopup(false), setApproveLoading(false);
  };
  const handleApprovePopupClose = () => {
    setOpenApprovePopup(false), setRejectLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('/users-funds-verification/schemesList')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              Scheme(s)
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('/users-funds-verification/schemesList')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Scheme Details
            </Typography>
          </Box>
          <Typography
            className={UserFundsMaster[schemeDetails?.status || '']}
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: 'text.secondary',
              '&.Approved .MuiTypography-root': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected .MuiTypography-root': {
                color: '#BD2932',
                fontWeight: 500,
              },
            }}>
            Status:{' '}
            <Typography
              component="span"
              sx={{
                color: '#B78813',
                fontSize: '16px',
                letterSpacing: '0.4px',
                ml: 0.5,
              }}>
              {UserFundsMaster[schemeDetails?.status || '']}
            </Typography>
          </Typography>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          schemeDetails && (
            <>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    {(schemeDetails.makerComment || schemeDetails.checkerComment) && (
                      <Grid
                        sx={{
                          border: '1px solid #00D9DA',
                          p: 1.25,
                          borderRadius: 1.25,
                          my: 2,
                          mr: { lg: 20, xs: 4 },
                        }}>
                        {schemeDetails.makerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                              }}>
                              Maker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {schemeDetails.makerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box
                          display={'flex'}
                          sx={{ flexDirection: { xs: 'column', sm: 'row' }, mb: 1 }}>
                          {schemeDetails.makerAdminName && (
                            <KeyValue
                              title={'Requested By'}
                              description={schemeDetails.makerAdminName}
                            />
                          )}
                          {schemeDetails.createdAt && (
                            <KeyValue
                              title={'Requested At'}
                              description={formatShortDateAndTime(schemeDetails.createdAt)}
                            />
                          )}
                        </Box>
                        {schemeDetails.checkerComment && (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: 17,
                                fontWeight: 500,
                                color: 'text.secondary',
                                mt: 0.3,
                              }}>
                              Checker Comments:{' '}
                              <Typography
                                component="span"
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: '16px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 700,
                                  ml: { sm: 0.5 },
                                }}>
                                {schemeDetails.checkerComment}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        <Box display={'flex'} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                          {schemeDetails.checkerAdminName && (
                            <KeyValue
                              title={
                                UserFundsMaster[schemeDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected By'
                                  : 'Approved By'
                              }
                              description={schemeDetails.checkerAdminName}
                            />
                          )}
                          {(schemeDetails.approvedAt || schemeDetails.rejectedAt) && (
                            <KeyValue
                              title={
                                UserFundsMaster[schemeDetails.status || ''] ===
                                UserFundsMaster.rejected
                                  ? 'Rejected At'
                                  : 'Approved At'
                              }
                              description={formatShortDateAndTime(
                                schemeDetails.approvedAt || schemeDetails.rejectedAt
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {(schemeDetails?.payload as Partial<addFundType>)?.plan && (
                        <Box sx={{ mr: { lg: 20, xs: 4 }, width: '100%', mt: 3 }}>
                          <SubHeading
                            sx={{
                              color: 'common.blue',
                              letterSpacing: 0.5 + 'px',
                              padding: { xs: '10px 10px', sm: '10px 30px' },
                              ml: { lg: 8, xs: 2 },
                            }}>
                            {'Scheme Details'}
                          </SubHeading>
                        </Box>
                      )}
                      <FundFields
                        fund={schemeDetails.payload as FundProps}
                        oldData={schemeDetails.currentData as FundProps | null}
                      />
                      {(schemeDetails?.payload as Partial<addFundType>)?.plan && (
                        <>
                          <Box sx={{ mr: { lg: 20, xs: 4 }, width: '100%', mt: 2 }}>
                            <SubHeading
                              sx={{
                                color: 'common.blue',
                                letterSpacing: 0.5 + 'px',
                                padding: { xs: '10px 10px', sm: '10px 30px' },
                                ml: { lg: 8, xs: 2 },
                              }}>
                              {'Plan Details'}
                            </SubHeading>
                          </Box>
                          <PlanFields
                            planDetails={
                              (schemeDetails?.payload as Partial<addFundType>)
                                ?.plan as Partial<ClassPlanProps>
                            }
                            oldData={schemeDetails.currentData as Partial<ClassPlanProps> | null}
                          />
                        </>
                      )}
                      {schemeDetails.currentData && (
                        <KeyValue
                          title={'Access Enabled'}
                          description={
                            yesAndNoTrueAndFalseMasters[
                              (schemeDetails.payload as any)?.isActive?.toString()
                            ]
                          }
                          dispalyModifiedIcon={
                            !applicationComparison(
                              (schemeDetails.currentData as any)?.isActive.toString(),
                              (schemeDetails.payload as any)?.isActive?.toString()
                            )
                          }
                          onInfoClick={() =>
                            !applicationComparison(
                              (schemeDetails.currentData as any)?.isActive.toString(),
                              (schemeDetails.payload as any)?.isActive?.toString()
                            ) &&
                            handleRoutePopupOpen(
                              'Access Enabled',
                              yesAndNoTrueAndFalseMasters[
                                (schemeDetails.payload as any)?.isActive?.toString()
                              ],
                              yesAndNoTrueAndFalseMasters[
                                (schemeDetails.currentData as any)?.isActive.toString()
                              ]
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )
        )}
      </Box>
      {UserFundsMaster[schemeDetails?.status || ''] === UserFundsMaster.pending &&
        path === '/users-funds-verification/schemes-details/:id' &&
        (access ? (
          <RejectApproveButtons
            handleOpen={handleRejectPopupOpen}
            handleDialogOpen={handleApprovePopupDialogOpen}
            approve={approveLoading}
            reject={rejectLoading}
            displaySendBack={false}
          />
        ) : (
          <Box sx={{ mt: 2 }}>
            <Notes displayContent={consentInfo} />
          </Box>
        ))}
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
      <ConfirmationDialogWithCommentsBox
        component={<RejectComponent />}
        open={openRejectPopup}
        setOpen={handleRejectPopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleRejectPopupClose();
            setRejectLoading(true);
            await dispatch(getAdminCheckerReject(id, { comment: values.comment }));
            history.push('/users-funds-verification/schemesList');
          } catch (e) {
            setRejectLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleRejectPopupClose}
      />
      <ConfirmationDialogWithCommentsBox
        component={<ApproveComponent />}
        open={openApprovePopup}
        setOpen={handleApprovePopupClose}
        onSave={async (values: { comment: string }) => {
          try {
            handleApprovePopupClose();
            setApproveLoading(true);
            await dispatch(getAdminCheckerApprove(id, { comment: values.comment }));
            history.push('/users-funds-verification/schemesList');
          } catch (e) {
            setApproveLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={handleApprovePopupClose}
      />
    </>
  );
}
