import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  // dematFieldValidation,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  USER_ROLES,
  yesAndNoMasters,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkValidationBasedOnDate,
  getApplicantName,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { DatePicker } from '../../lib/formik/DatePicker';
import { UseRadioGroup } from '../../lib/formik/Radio';
import { useFeatureToggles } from '../../utils/toggler';

type Values = {
  applicants: Partial<Applicant>[];
  dpName: string;
  dpId: string;
  dpClientId: string;
  saveType: string;
  createdAt: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      otherOccupation: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
      kinNo: '',
      isAccreditedInvestor: '',
      issuingAuthority: '',
      certificateNo: '',
      accreditedInvestorFrom: null,
      accreditedInvestorTo: null,
    },
  ],
  dpName: '',
  dpId: '',
  dpClientId: '',
  saveType: 'save and proceed',
  createdAt: '',
};

const KYCDetails = ({
  applicant,
  index,
  occupationDetails,
  values,
}: {
  applicant: string;
  index: number;
  occupationDetails: string;
  values: Values;
}): JSX.Element => {
  const { setValues, setFieldValue } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationDetails`}
          label="Occupation Details *"
          items={Object.keys(occupationDetailsMasters).map((occupation) => ({
            key: occupationDetailsMasters[occupation],
            value: occupation,
          }))}
          onChange={(e) => {
            setValues({
              ...values,
              applicants: values.applicants.map((_applicant, _index) => {
                if (_index === index) {
                  return {
                    ..._applicant,
                    occupationDetails: e.target.value,
                    otherOccupation: '',
                  };
                }
                return _applicant;
              }) as Partial<Applicant>[],
            });
          }}
          disabled={isFieldDisabled}
        />
      </Grid>
      {occupationDetailsMasters[occupationDetails] === occupationDetailsMasters.others && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.otherOccupation`}
            label="Other Occupation *"
            placeholder={`Enter Occupation`}
            disabled={isFieldDisabled}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income"
          items={Object.keys(grossAnnualMasters).map((grossIncome) => ({
            key: grossAnnualMasters[grossIncome],
            value: grossIncome,
          }))}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number *"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={isFieldDisabled}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.kinNo`}
          label="KIN Number"
          placeholder={'Enter KIN number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={isFieldDisabled}
        />
      </Grid> */}
      <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
        <UseRadioGroup
          name={`applicants.${index}.isAccreditedInvestor`}
          formLabel="Are you an Accredited Investor *"
          items={Object.keys(yesAndNoMasters).map((item) => ({
            label: yesAndNoMasters[item],
            value: item,
          }))}
          disabled={isFieldDisabled}
          onChange={({ target: { value } }) => {
            const check = yesAndNoMasters[value] === yesAndNoMasters.yes;
            setFieldValue(`applicants.${index}`, {
              ...values.applicants[index],
              isAccreditedInvestor: value,
              issuingAuthority: check ? values.applicants[index].issuingAuthority : '',
              certificateNo: check ? values.applicants[index].certificateNo : '',
              accreditedInvestorFrom: check
                ? values.applicants[index].accreditedInvestorFrom
                : null,
              accreditedInvestorTo: check ? values.applicants[index].accreditedInvestorTo : null,
            });
          }}
        />
      </Grid>
      {yesAndNoMasters[values.applicants[index].isAccreditedInvestor || ''] ===
        yesAndNoMasters.yes && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.issuingAuthority`}
              label="Issuing Authority *"
              placeholder={'Enter Issuing Authority'}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.certificateNo`}
              label="Certificate No *"
              placeholder={'Enter Certificate No'}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={`Accredited Investor From *`}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'DD/MM/YYYY'}
              maxDate={new Date(8640000000000000)}
              name={`applicants.${index}.accreditedInvestorFrom`}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={`Accredited Investor to *`}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'DD/MM/YYYY'}
              name={`applicants.${index}.accreditedInvestorTo`}
              maxDate={new Date(8640000000000000)}
              minDate={new Date(values.applicants[index]?.accreditedInvestorFrom || '')}
              disabled={isFieldDisabled}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export const DematDetails = ({
  values,
  disabled,
}: {
  values: Values;
  disabled?: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { dateFeatureToggles } = useSelector((store: RootStateType) => store.toggler);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <SubHeading>Demat Account details</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`dpName`}
          label={`DP Name ${
            checkValidationBasedOnDate(
              values.createdAt || '',
              dateFeatureToggles?.dematFieldValidation
            )
              ? ' *'
              : ''
          }`}
          placeholder="Enter DP Name"
          disabled={disabled || isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`dpId`}
          label={`DP Id${
            checkValidationBasedOnDate(
              values.createdAt || '',
              dateFeatureToggles?.dematFieldValidation
            )
              ? ' *'
              : ''
          }`}
          placeholder={`Enter DP Id`}
          disabled={disabled || isFieldDisabled}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`dpClientId`}
          label={`Client Id${
            checkValidationBasedOnDate(
              values.createdAt || '',
              dateFeatureToggles?.dematFieldValidation
            )
              ? ' *'
              : ''
          }`}
          placeholder={`Enter Client Id`}
          disabled={disabled || isFieldDisabled}
        />
      </Grid>
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const dateFeatureToggles = useFeatureToggles();

  useEffect(() => {
    const {
      applicants: exisitingApplicants = [],
      dpName = '',
      dpId = '',
      dpClientId = '',
      createdAt = '',
    } = application || {};
    setkycDetails({
      ...kycDetails,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        otherOccupation: applicant.otherOccupation || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        ckycNo: applicant.ckycNo || '',
        kinNo: applicant.kinNo || '',
        isAccreditedInvestor: applicant.isAccreditedInvestor || '',
        issuingAuthority: applicant.issuingAuthority || '',
        certificateNo: applicant.certificateNo || '',
        accreditedInvestorFrom: applicant.accreditedInvestorFrom || new Date().toISOString(),
        accreditedInvestorTo:
          applicant.accreditedInvestorTo ||
          new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
      })),
      dpName,
      dpId,
      dpClientId,
      createdAt,
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType, dpId, dpName, dpClientId } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
        ...applicant,
        ...applicants[index],
        accreditedInvestorFrom:
          yesAndNoMasters[applicants[index]?.isAccreditedInvestor || ''] === yesAndNoMasters.yes
            ? applicants[index].accreditedInvestorFrom
            : null,
        accreditedInvestorTo:
          yesAndNoMasters[applicants[index]?.isAccreditedInvestor || ''] === yesAndNoMasters.yes
            ? applicants[index].accreditedInvestorTo
            : null,
      }));
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        dpId,
        dpName,
        dpClientId,
        currentStep: !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              dpId,
              dpName,
              dpClientId,
              currentStep: 4,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            KYCDetailsSchema(dateFeatureToggles?.dematFieldValidation),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_applicant, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              key={index}
              index={index}
              occupationDetails={_applicant.occupationDetails as string}
              values={values}
            />
          ))}
          <DematDetails values={values} />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
